import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { Box, Typography } from '@mui/material';

import Chip from '~/components/Chip';
import Link from '~/components/Link';
import Card, { CardMedia } from '.';

const BlogCard = ({
  children,
  href,
  imgSrc,
  imgAlt,
  imgHeight,
  author,
  date,
  header,
  tags,
  LinkProps,
  sx,
  ...overrides
}) => (
  <Card
    variant="outlined"
    {...(imgSrc &&
      imgAlt && {
        media: (
          <CardMedia
            component="img"
            alt={imgAlt}
            height={imgHeight}
            image={imgSrc}
          />
        )
      })}
    sx={{
      position: 'relative',
      ...sx
    }}
    {...overrides}
  >
    {author || date ? (
      <Typography
        component="div"
        variant="subtitle2"
        color="primary"
        sx={{ mb: 1 }}
      >
        {author}
        {date && (
          <Box
            component="span"
            sx={
              author && {
                '&::before': {
                  content: '"\\2022"',
                  mx: 1
                }
              }
            }
          >
            {date}
          </Box>
        )}
      </Typography>
    ) : null}
    {header ? (
      <Link
        href={href}
        variant="h6"
        underline="none"
        sx={{
          display: 'block',
          color: 'text.primary',
          mb: 1,
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%'
          },
          ...propOr({}, 'sx', LinkProps)
        }}
        {...LinkProps}
      >
        {header}
      </Link>
    ) : null}
    {children}
    {tags ? (
      <Box sx={{ pt: 2 }}>
        {tags.map(({ label, color, onClick, href: tagHref }) => {
          const chip = (
            <Chip
              key={label}
              label={label}
              onClick={onClick}
              href={tagHref}
              sx={{
                ...(color && { color: color[500], backgroundColor: color[50] }),
                ...((onClick || tagHref) && {
                  position: 'relative',
                  cursor: 'pointer'
                })
              }}
            />
          );

          return tagHref ? (
            <Link
              href={tagHref}
              sx={{
                display: 'inline-block',
                '+ .MuiLink-root': {
                  ml: 1
                }
              }}
            >
              {chip}
            </Link>
          ) : (
            chip
          );
        })}
      </Box>
    ) : null}
  </Card>
);

BlogCard.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  imgHeight: PropTypes.number,
  author: PropTypes.string,
  date: PropTypes.node,
  header: PropTypes.node,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      color: PropTypes.shape(),
      onClick: PropTypes.func,
      href: PropTypes.string
    })
  ),
  LinkProps: PropTypes.shape(),
  sx: PropTypes.shape()
};

BlogCard.defaultProps = {
  href: null,
  imgSrc: null,
  imgAlt: null,
  imgHeight: 140,
  author: null,
  date: null,
  tags: null,
  LinkProps: {}
};

export default BlogCard;

import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';

const Checkbox = ({
  label,
  disabled,
  indeterminate,
  small,
  sx,
  ...overrides
}) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <MuiCheckbox
          size={small ? 'small' : 'medium'}
          sx={{
            ...sx
          }}
          disabled={disabled}
          indeterminate={indeterminate}
          {...overrides}
        />
      }
    />
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  small: PropTypes.bool,
  sx: PropTypes.object
};

Checkbox.defaultProps = {
  disabled: false,
  indeterminate: false
};

export default Checkbox;

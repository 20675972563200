import { Box, CircularProgress, Container } from '@mui/material';
import * as React from 'react';
import AdminThumbnailsEditDialog from './AdminThumbnailsEditDialog';
import AdminThumbnailsTable from './AdminThumbnailsTable';
import { useGhsAdminDashboards } from './useGhsAdminDashboards';

/**
 * Component for displaying the form to manage Sisense dashboards metadata (thumbnails, descriptions)
 *
 * @returns {Element} GhsAdminDashboards component
 * @class
 */
function GhsAdminDashboards() {
  const { dashboards, isDashboardsLoading, isEditDialogOpened, openEditDialog, closeEditDialog, dashboardToEdit, handleSave, isUpdateMetadataLoading } = useGhsAdminDashboards();

  if (isDashboardsLoading) {
    return (
      <Box sx={{ display: 'flex' }} justifyContent="center" data-testid="admin-thumbnails-tab-loading">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container data-testid="admin-thumbnails-tab">
      <AdminThumbnailsEditDialog open={isEditDialogOpened} handleClose={closeEditDialog} dashboard={dashboardToEdit} handleSave={handleSave} isUpdateMetadataLoading={isUpdateMetadataLoading} />
      <AdminThumbnailsTable dashboards={dashboards} handleEditDialogOpen={openEditDialog} handleEditDialogClose={closeEditDialog} />
    </Container>
  );
}

export default GhsAdminDashboards;

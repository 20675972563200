import { attach, createEvent, createStore, sample } from 'effector';
import { persist } from 'effector-storage/local';
import { persist as persistQuery } from 'effector-storage/query';
import { spread } from 'patronum';
import { $$confirmationDialog } from '../../../components/ConfirmationDialog/model';
import { $$infoDialog } from '../../../components/InfoDialog/model';
import { $$snackbar } from '../../../components/SnackbarRoot/model';
import { $$personaService } from '../../../services/PersonaService/model';
import { $$reseller } from '../../../services/ResellerService/model';
import { $$user, PERMISSIONS } from '../../../services/UserService/UserService';
import { saveArrayAsCsvFx, treeShakeObject } from '../../../util/JsonUtils';
import createDataGridModel from '../../../util/createDataGridModel';
import { createCustomersUserCreateSuccessDialogArg, createCustomersUserDeleteConfirmationDialogArg, createLobsUserCreateSuccessDialogArg, createLobsUserDeleteConfirmationDialogArg } from './utils';

const $isResellerUser = sample({
  source: $$personaService.$activePersona,
  fn: persona => !persona?.customerId
});

const $selectedCustomerId = createStore(/** @type {Reseller.Customer['id'] | null} */ (null));

const $selectedFilterCustomerId = createStore(/** @type {Reseller.Customer['id'] | null} */ (null));

const $selectedLobId = createStore(/** @type {Reseller.LineOfBusiness['id'] | null} */ (null));

const $selectedFilterLobId = createStore(/** @type {Reseller.LineOfBusiness['id'] | null} */ (null));

const $customerUserFilter = createStore(/** @type {string | null} */ (null));

const $lobUserFilter = createStore(/** @type {string | null} */ (null));

const $customersUserDeleteConfirmationSkipped = createStore(/** @type {boolean} */ (false));

const $lobsUserCreateSuccessSkipped = createStore(/** @type {boolean} */ (false));

const $customersUserCreateSuccessSkipped = createStore(/** @type {boolean} */ (false));

const $lobsUserDeleteConfirmationSkipped = createStore(/** @type {boolean} */ (false));

/** @type {import('effector').EventCallable<boolean>} */
const pageMounted = createEvent();

/** @type {import('effector').EventCallable<Reseller.Customer['id'] | null>} */
const selectCustomerId = createEvent();

/** @type {import('effector').EventCallable<Reseller.Customer['id'] | null>} */
const filterCustomerId = createEvent();

/** @type {import('effector').EventCallable<Reseller.LineOfBusiness['id'] | null>} */
const selectLobId = createEvent();

/** @type {import('effector').EventCallable<Reseller.LineOfBusiness['id'] | null>} */
const filterLobId = createEvent();

/** @type {import('effector').EventCallable<string>} */
const customerUserFiltered = createEvent();

/** @type {import('effector').EventCallable<string>} */
const lobUserFiltered = createEvent();

/** @type {import('effector').EventCallable<Reseller.User['id']>} */
const customersUserDeleteClicked = createEvent();

/** @type {import('effector').EventCallable<Reseller.LineOfBusiness['id']>} */
const lobsUserDeleteClicked = createEvent();

/** @type {import('effector').EventCallable<boolean>} */
const customersUserDeleteConfirmationSkipChecked = createEvent();

/** @type {import('effector').EventCallable<boolean>} */
const lobsUserDeleteConfirmationSkipChecked = createEvent();

/** @type {import('effector').EventCallable<Reseller.User['id']>} */
const customersUserDeleteConfirmed = createEvent();

/** @type {import('effector').EventCallable<Reseller.LineOfBusiness['id']>} */
const lobsUserDeleteConfirmed = createEvent();

/** @type {import('effector').EventCallable<boolean>} */
const customersUserCreateSuccessSkipChecked = createEvent();

/** @type {import('effector').EventCallable<boolean>} */
const lobsUserCreateSuccessSkipChecked = createEvent();

// Wrap the effects to be able to handle the done event in each page separately and avoid overlaps
const getLineOfBusinessFx = attach({ effect: $$reseller.getLineOfBusinessFx });

// Effect to get lobs users provided the lob id as an argument from the store at the moment of calling this effect
/** @type {import('effector').Effect<Partial<{ size: number; page: number; sort: string }>, PaginatedResponse<Reseller.User>>} */
const getSelectCustomerUsersFx = attach({
  source: {
    customerId: $selectedCustomerId,
    search: $customerUserFilter
  },
  mapParams: (rest, { customerId, search }) => treeShakeObject({ customerId, ...rest, search }),
  effect: $$reseller.getCustomersUsersFx
});

// Effect to get lobs list, customerId is taken from the store at the moment of calling this effect
/** @type {import('effector').Effect<Partial<{ search: string; startDate: string; endDate: string }>, Reseller.LineOfBusiness[]>} */
const getSelectedCustomerLobsFx = attach({
  source: $selectedCustomerId,
  mapParams: (rest, customerId) => ({ customerIds: [Number(customerId)], ...rest }),
  effect: getLineOfBusinessFx
});

// Effect to get lobs users provided the lob id as an argument from the store at the moment of calling this effect
/** @type {import('effector').Effect<Partial<{ size: number; page: number; sort: string }>, PaginatedResponse<Reseller.User>>} */
const getSelectedLobUsersFx = attach({
  source: {
    lineOfBusinessId: $selectedLobId,
    search: $lobUserFilter
  },
  mapParams: (rest, { lineOfBusinessId, search }) => treeShakeObject({ lineOfBusinessId: Number(lineOfBusinessId), ...rest, search }),
  effect: $$reseller.getLobUsersFx
});

// Model for client-side data grid for customers table
const $$customers = createDataGridModel({
  type: 'client',
  effect: $$reseller.getCustomersFx
});

// Model for server-side data grid for customers users table
const $$customersUsers = createDataGridModel({
  type: 'server',
  effect: getSelectCustomerUsersFx,
  refetch: [
    $$reseller.addCustomersUserFx.done,
    $$reseller.editCustomersUserFx.done,
    $$reseller.deleteCustomersUserFx.done,
    $customerUserFilter.updates,
    // When the user clicks on the X of the Customers dropdown, customerId is set to null. This condition is to prevent a call to the API when no customer is selected
    $selectedCustomerId.updates.filter({ fn: value => value !== null })
  ]
});

// Model for client-side data grid for lobs table
const $$lobs = createDataGridModel({
  type: 'client',
  effect: getSelectedCustomerLobsFx,
  refetch: [
    $$reseller.addLobUserFx.done,
    $$reseller.editLobUserFx.done,
    $$reseller.deleteLobUserFx.done,
    // When the user clicks on the X of the Customers dropdown, customerId is set to null. This condition is to prevent a call to the API when no customer is selected
    $selectedCustomerId.updates.filter({ fn: value => value !== null })
  ]
});

// Model for server-side data grid for lobs users table
const $$lobsUsers = createDataGridModel({
  type: 'server',
  effect: getSelectedLobUsersFx,
  refetch: [
    $$reseller.addLobUserFx.done,
    $$reseller.editLobUserFx.done,
    $$reseller.deleteLobUserFx.done,
    $lobUserFilter.updates.filter({ fn: value => value !== null }),
    // When the user clicks on the X of the LOBs dropdown, lobId is set to null. This condition is to prevent a call to the API when no LOB is selected
    $selectedLobId.updates.filter({ fn: value => value !== null })
  ]
});

// Effect to download all reseller users
const downloadResellerUsersFx = attach({
  effect: $$reseller.getResellerUsersFx
});

// Effect to download all customers users, customerId, size and page are taken from the stores at the moment of calling this effect
const downloadCustomersUsersFx = attach({
  source: {
    customerId: $selectedCustomerId,
    search: $customerUserFilter,
    size: $$customersUsers.$pagination.map(pagination => pagination?.totalElements || 0),
    page: $selectedCustomerId.map(() => 0)
  },
  effect: $$reseller.getCustomersUsersFx
});

// Effect to download all lobs users, lobId, size and page are taken from the stores at the moment of calling this effect
const downloadLobsUsersFx = attach({
  source: {
    lineOfBusinessId: $selectedLobId.map(Number),
    search: $lobUserFilter,
    size: $$lobsUsers.$pagination.map(pagination => pagination?.totalElements || 0),
    page: $selectedLobId.map(() => 0)
  },
  effect: $$reseller.getLobUsersFx
});

// Persist selected customer id and lob id in query parameter
persistQuery({ store: $selectedCustomerId, key: 'selected-customer-id' });

// Persist selected lob id in local storage
persist({ store: $customersUserDeleteConfirmationSkipped, key: 'gh:admin-users:skip-customer-user-delete-confirm' });

persist({ store: $lobsUserDeleteConfirmationSkipped, key: 'gh:admin-users:skip-lobs-user-delete-confirm' });

persist({ store: $customersUserCreateSuccessSkipped, key: 'gh:admin-users:skip-customer-user-create-success' });

persist({ store: $lobsUserCreateSuccessSkipped, key: 'gh:admin-users:skip-lobs-user-create-success' });

// When selectCustomerId is triggered (row double click), pass it to $selectedCustomerId
sample({
  clock: selectCustomerId,
  target: $selectedCustomerId
});

// When a customer is selected in the dropdown, both the selected filter customer and the selected customer are updated.
// We need to keep both customer ids in different stores because if we use the same store for both then when a customer
// is selected in the table, the table is automatically filtered and only the selected customer is kept
sample({
  clock: filterCustomerId,
  target: [$selectedFilterCustomerId, $selectedCustomerId]
});

// Update the selection model in the DataGrid so that the row is highlighted
sample({
  clock: filterCustomerId,
  fn: customerId => customerId ?? [],
  target: $$customers.selectionModelChanged
});

// When selectLobId is triggered (row double click), pass it to $selectedLobId
sample({
  clock: selectLobId,
  target: $selectedLobId
});

sample({
  clock: filterLobId,
  target: [$selectedFilterLobId, $selectedLobId]
});

// Update the selection model in the DataGrid so that the row is highlighted
sample({
  clock: filterLobId,
  fn: lobId => lobId ?? [],
  target: $$lobs.selectionModelChanged
});

// When skip checkbox for customers user delete confirmation is checked, pass the value from event to the store
sample({
  clock: customersUserDeleteConfirmationSkipChecked,
  target: $customersUserDeleteConfirmationSkipped
});

// When skip checkbox for lobs user delete confirmation is checked, pass the value from event to the store
sample({
  clock: lobsUserDeleteConfirmationSkipChecked,
  target: $lobsUserDeleteConfirmationSkipped
});

// When skip checkbox for customers user create success is checked, pass the value from event to the store
sample({
  clock: customersUserCreateSuccessSkipChecked,
  target: $customersUserCreateSuccessSkipped
});

// When skip checkbox for lobs user create success is checked, pass the value from event to the store
sample({
  clock: lobsUserCreateSuccessSkipChecked,
  target: $lobsUserCreateSuccessSkipped
});

// When page is unmounted, reset $selectedCustomerId and $selectedLobId stores
sample({
  clock: pageMounted.filter({ fn: mounted => !mounted }),
  target: [$selectedCustomerId.reinit, $selectedLobId.reinit, $customerUserFilter.reinit, $lobUserFilter.reinit]
});

// When delete icon is clicked (customer's users table), open delete confirmation dialog, pass userId, and events to skip confirmation and confirm delete
sample({
  // @ts-ignore
  source: $customersUserDeleteConfirmationSkipped,
  clock: customersUserDeleteClicked,
  fn: (skipped, userId) =>
    skipped
      ? { proceed: userId }
      : { open: createCustomersUserDeleteConfirmationDialogArg({ userId, deleteConfirmationSkipChecked: customersUserDeleteConfirmationSkipChecked, deleteConfirmed: customersUserDeleteConfirmed }) },
  target: spread({
    open: $$confirmationDialog.open,
    proceed: customersUserDeleteConfirmed
  })
});

// When delete icon is clicked (lobs users table), open delete confirmation dialog, pass userId, and events to skip confirmation and confirm delete
sample({
  // @ts-ignore
  source: $lobsUserDeleteConfirmationSkipped,
  clock: lobsUserDeleteClicked,
  fn: (skipped, lobId) =>
    skipped
      ? { proceed: lobId }
      : { open: createLobsUserDeleteConfirmationDialogArg({ lobId, deleteConfirmationSkipChecked: lobsUserDeleteConfirmationSkipChecked, deleteConfirmed: lobsUserDeleteConfirmed }) },
  target: spread({
    open: $$confirmationDialog.open,
    proceed: lobsUserDeleteConfirmed
  })
});

// When skip delete operation is confirmed from the dialog, take customerId from store, combine them into an object and pass to deleteCustomersUserFx
sample({
  source: $selectedCustomerId,
  clock: customersUserDeleteConfirmed,
  fn: (customerId, userId) => ({ customerId, data: { userId } }),
  target: $$reseller.deleteCustomersUserFx
});

// When skip delete operation is confirmed from the dialog, take lobId from store, combine them into an object and pass to deleteLobUserFx
sample({
  source: $selectedLobId,
  clock: lobsUserDeleteConfirmed,
  fn: (lineOfBusinessId, userId) => ({ lineOfBusinessId, data: { userId } }),
  target: $$reseller.deleteLobUserFx
});

// When user is added successfully to customers, open the dialog with success message
sample({
  // @ts-ignore
  clock: $$reseller.addCustomersUserFx.done,
  filter: $customersUserCreateSuccessSkipped.map(skipChecked => !skipChecked),
  fn: () => createCustomersUserCreateSuccessDialogArg({ deleteConfirmationSkipChecked: customersUserCreateSuccessSkipChecked }),
  target: $$confirmationDialog.open
});

// When user is added successfully to line of business, open the dialog with success message
sample({
  // @ts-ignore
  clock: $$reseller.addLobUserFx.done,
  filter: $lobsUserCreateSuccessSkipped.map(skipChecked => !skipChecked),
  fn: () => createLobsUserCreateSuccessDialogArg({ deleteConfirmationSkipChecked: lobsUserCreateSuccessSkipChecked }),
  target: $$confirmationDialog.open
});

// $selectedCustomer is a derived store that contains the customer object that matches the $selectedCustomerId value
/** @type {import('effector').Store<Reseller.Customer | null>} */
const $selectedCustomer = sample({
  source: {
    selectedCustomerId: $selectedCustomerId,
    customers: $$customers.$data
  },
  fn: ({ selectedCustomerId, customers }) => customers.find(customer => customer.id === selectedCustomerId) || null
});

// $selectedLob is a derived store that contains the lob object that matches the $selectedLobId value
/** @type {import('effector').Store<Reseller.LineOfBusiness | null>} */
const $selectedLob = sample({
  source: {
    selectedLobId: $selectedLobId,
    lobs: $$lobs.$data
  },
  fn: ({ selectedLobId, lobs }) => lobs.find(lob => lob.id === selectedLobId) || null
});

// When all users for the customer are downloaded, trigger saveArrayAsCsvFx with the data
sample({
  clock: downloadResellerUsersFx.doneData,
  fn: response => ({ filename: `Users`, data: response, columns: { customerName: 'Customer', lineOfBusinessName: 'Line Of Business', email: 'Email' } }),
  target: saveArrayAsCsvFx
});

// When all users for the customer are downloaded, trigger saveArrayAsCsvFx with the data
sample({
  source: $selectedCustomer.map(customer => customer?.name || ''),
  clock: downloadCustomersUsersFx.doneData,
  fn: (customerId, response) => ({ filename: `${customerId} - Users`, data: response.content, columns: { email: 'Email', firstName: 'First Name', lastName: 'Last Name' } }),
  target: saveArrayAsCsvFx
});

// When all users for the lob are downloaded, trigger saveArrayAsCsvFx with the data
sample({
  source: $selectedLob.map(lob => lob?.name || ''),
  clock: downloadLobsUsersFx.doneData,
  fn: (lobName, response) => ({ filename: `${lobName} - Users`, data: response.content, columns: { email: 'Email', firstName: 'First Name', lastName: 'Last Name' } }),
  target: saveArrayAsCsvFx
});

// When download is started (users for customers or lobs), open info dialog with the message
sample({
  clock: [
    sample({ source: createStore('users'), clock: downloadResellerUsersFx }),
    sample({ source: $selectedCustomer.map(lob => lob?.name || ''), clock: downloadCustomersUsersFx }),
    sample({ source: $selectedLob.map(lob => lob?.name || ''), clock: downloadLobsUsersFx })
  ],
  fn: name => ({ title: `Your ${name} list is downloading`, content: `Please wait while we prepare your users list for download.` }),
  target: $$infoDialog.open
});

// When download is finished (users for customers or lobs, successfully or not), close the info dialog
sample({
  clock: [downloadResellerUsersFx.finally, downloadCustomersUsersFx.finally, downloadLobsUsersFx.finally],
  target: $$infoDialog.close
});

sample({
  source: {
    selectedCustomerId: $selectedCustomerId,
    personasCutomerId: $$personaService.$activePersona.map(persona => persona?.customerId || null),
    hasPermission: $$user.$permissions.map(permissions => permissions.includes(PERMISSIONS.RESELLER_ADMIN.MANAGE_USERS_DATA_ACCESS_LOB))
  },
  filter: ({ selectedCustomerId, personasCutomerId, hasPermission }) => hasPermission && selectedCustomerId === null && personasCutomerId !== null,
  fn: ({ personasCutomerId }) => personasCutomerId,
  target: $selectedCustomerId
});

sample({
  clock: customerUserFiltered,
  target: $customerUserFilter
});

sample({
  clock: lobUserFiltered,
  target: $lobUserFilter
});

// When the selected customer changes, reset the user filters in both customer and LOB list
sample({
  clock: $selectedCustomerId.updates,
  target: [$customerUserFilter.reinit, $lobUserFilter.reinit]
});

// When the selected LOB changes, reset the user filter in the LOB list
sample({
  clock: $selectedLobId.updates,
  target: [$lobUserFilter.reinit]
});

// When a user is deleted, reset the user filter in case it was the deleted user the one that was selected in the dropdown
sample({
  clock: $$reseller.deleteCustomersUserFx.done,
  target: $customerUserFilter.reinit
});

sample({
  clock: $$reseller.deleteLobUserFx.done,
  target: $lobUserFilter.reinit
});

sample({
  clock: [
    $$reseller.addCustomersUserFx.fail.map(response => ({ response, action: 'adding the user' })),
    $$reseller.editCustomersUserFx.fail.map(response => ({ response, action: 'editing the user' })),
    $$reseller.deleteCustomersUserFx.fail.map(response => ({ response, action: 'deleting the user' })),
    $$reseller.addLobUserFx.fail.map(response => ({ response, action: 'adding the user' })),
    $$reseller.editLobUserFx.fail.map(response => ({ response, action: 'editing the user' })),
    $$reseller.deleteLobUserFx.fail.map(response => ({ response, action: 'deleting the user' })),
    downloadResellerUsersFx.fail.map(response => ({ response, action: 'exporting the users' })),
    downloadCustomersUsersFx.fail.map(response => ({ response, action: 'exporting the users' })),
    downloadLobsUsersFx.fail.map(response => ({ response, action: 'exporting the users' }))
  ],
  fn: ({ response, action }) => {
    const { error } = response;
    let message = `An error occurred ${action}.`;
    if (error.response?.data?.message) {
      message = error.response.data.message;
    }
    return { message: message, severity: 'error' };
  },
  target: $$snackbar.open
});

export const $$adminDataAccessPage = {
  $$customers,
  $$customersUsers,
  $$lobs,
  $$lobsUsers,

  $isResellerUser,
  $selectedCustomerId,
  $selectedFilterCustomerId,
  $selectedCustomer,
  $selectedLobId,
  $selectedFilterLobId,
  $selectedLob,
  $customerUserFilter,
  $lobUserFilter,

  pageMounted,
  selectCustomerId,
  filterCustomerId,
  selectLobId,
  filterLobId,
  customerUserFiltered,
  lobUserFiltered,
  customersUserDeleteClicked,
  lobsUserDeleteClicked,
  downloadResellerUsersFx,
  downloadCustomersUsersFx,
  downloadLobsUsersFx
};

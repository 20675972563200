import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from 'react';

const StartLogin = () => {
  const { error, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const getUsers = async() => {
      console.log("Start Login");

      console.log(window.location.href);

      let myURL = new URL(window.location.href);
      let conn = myURL.searchParams.get("connection");
      const options = {
        authorizationParams: {
          redirect_uri: window.location.origin + "/callback",
          connection: conn
        }
      };
      await loginWithRedirect(options);
    };

    getUsers();
  }, [loginWithRedirect]);

  if (error) {
    return (
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Error
          </h1>
          <div className="content__body">
            <p id="page-description">
              <span>{error.message}</span>
            </p>
          </div>
        </div>
    );
  }

  return (
    <div className="page-layout">
      <div className="page-layout__content" />
    </div>
  );
};

export default StartLogin;
import { useUnit } from 'effector-react';
import { $$auth } from '../../services/AuthService/model';
import MyRolesTable from './MyRolesTable';
import PersonalHeader from './PersonalHeader';

/**
 * MyRolesTable component
 *
 * @type {React.FC<{}>}
 * @returns {React.ReactNode} MyRolesTable component
 */
export default function MyRolesTab() {
  const user = useUnit($$auth.$user);

  if (!user) {
    return null;
  }

  return (
    <>
      <PersonalHeader name={user.name || ''} firstName={user.given_name || ''} lastName={user.family_name || ''} avatarUrl={user.picture} />
      <MyRolesTable />
    </>
  );
}

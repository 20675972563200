import '../field-description-table.css'

const ParsedAddressFieldDescriptions = (<>
    <table className={"table"}>
        <thead>
        <tr>
            <th className={"field-description"}>Field</th>
            <th className={"field-description"}>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td className={"field-name"}>House #</td>
            <td className={"field-description"}>The house number for the output street address. For example, if the output street address is &ldquo;123 Main St,&rdquo; the house number is &ldquo;123.&rdquo;</td>
        </tr>
        <tr>
            <td className={"field-name"}>Pre Dir</td>
            <td className={"field-description"}>The pre-directional for the output street address. For example, if the output street address is &quot;123 E Main St,&quot; the PreDirectional designator is &quot;E.&quot;</td>
        </tr>
        <tr>
            <td className={"field-name"}>Street Name</td>
            <td className={"field-description"}>The street name for the output street address. For example, if the output street address is &quot;123 Main St,&quot; the street name is &quot;Main.&quot;</td>
        </tr>
        <tr>
            <td className={"field-name"}>Suffix</td>
            <td className={"field-description"}>The street suffix for the output street address. For example, if the output street address is &quot;123 Main St,&quot; the street suffix is &quot;St.&quot;</td>
        </tr>
        <tr>
            <td className={"field-name"}>Post Dir</td>
            <td className={"field-description"}>The post-directional for the output street address. For example, if the output street address is &quot;123 Main St N,&quot; the PostDirectional is &quot;N.&quot;</td>
        </tr>
        <tr>
            <td className={"field-name"}>Unit Type</td>
            <td className={"field-description"}>The secondary unit designator (SUD) for the output street address. For example, if the output street address is &quot;123 Main St Apt 12,&quot; the SUD is &quot;Apt.&quot;</td>
        </tr>
        <tr>
            <td className={"field-name"}>Unit #</td>
            <td className={"field-name"}>The unit number for the output street address. For example, if the output street address is &quot;123 Main St Apt 12,&quot; the unit number is &quot;12.&quot;</td>
        </tr>
        <tr>
            <td className={"field-name"}>PMB</td>
            <td className={"field-description"}>Private Mail Box address information.</td>
        </tr>
        </tbody>
    </table>
</>)

export default ParsedAddressFieldDescriptions;
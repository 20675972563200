import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { DataGridCellDatePicker } from './DataGridCellDatePicker';

/**
 * DateCell
 *
 * @typedef {import('@mui/x-data-grid-pro').GridRowId} GridRowId
 * @typedef {import('@mui/x-data-grid-pro').GridCellMode} GridCellMode
 * @typedef {import('@mui/x-date-pickers').DatePickerProps} DatePickerProps
 * @param {{
 *  field: string;
 *  row: object;
 *  id: GridRowId;
 *  onValueChanged?: (params: DataGridChangeUnit) => void;
 *  errorMessage?: string;
 *  cellMode?: GridCellMode;
 *  onBlur?: () => void;
 * } & DatePickerProps} props - props
 * @returns {React.ReactNode} - JSX Element
 */
export default function DateCell(props) {
  const { field, row, id, errorMessage, onValueChanged, cellMode, onBlur, ...rest } = props;

  useEffect(() => {
    if (cellMode && onBlur && cellMode === 'view') {
      onBlur();
    }
  }, [cellMode]);

  useEffect(() => {
    return () => {
      if (cellMode && onBlur) {
        onBlur();
      }
    };
  }, []);

  const value = row[field];

  return (
    <DataGridCellDatePicker
      {...rest}
      id={`${field}-${row.id}`}
      name={`${field}-${row.id}`}
      value={value}
      isEditable={cellMode === 'edit'}
      onChange={date => {
        if (onValueChanged) {
          onValueChanged({ id, field, value: date.format('YYYY-MM-DD') });
        }
      }}
      onClose={() => {}}
      onOpen={() => {}}
      onEdit={() => {}}
      errorMessage={errorMessage}
      sx={{}}
    />
  );
}

DateCell.propTypes = {
  field: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  id: PropTypes.number,
  onValueChanged: PropTypes.func,
  errorMessage: PropTypes.string,
  cellMode: PropTypes.string,
  onBlur: PropTypes.func
};

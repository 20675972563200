(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("prop-types"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("standard-library", ["PropTypes", "React", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["standard-library"] = factory(require("prop-types"), require("react"), require("react-dom"));
	else
		root["standard-library"] = factory(root["PropTypes"], root["React"], root["ReactDOM"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__3343__, __WEBPACK_EXTERNAL_MODULE__4639__, __WEBPACK_EXTERNAL_MODULE__1346__) {
return 
import Box from "@mui/material/Box";
import Tab from '@mui/material/Tab';
import * as React from "react";
import {useEffect, useState} from "react";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CloseIcon from "@mui/icons-material/Close";
import {Alert} from "@ghs/grayhair-component-library";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import {useAuth0} from "@auth0/auth0-react";
import useAuth0WithErrorHandling from "../../hooks/useAuth0WithErrorHandling";
import {postFileProcessingKillFile} from "../../services/FileProcessingService";
import {useSearchParams} from "react-router-dom";
import {getSearchParamOrDefault} from "../../util/FormUtil";
import FileProcessingApplication from "./FileProcessingApplication";

const application = {
    GHSelect: "GHSELECT",
    MTSelect: "MTSELECT",
    BACInternalAndPreprocessor: "BACINTERNALANDPREPROCESSOR"
}

const FileProcessing = () => {
    const {user} = useAuth0();
    const [searchParams, setSearchParams] = useSearchParams();
    const {getAccessTokenSilently} = useAuth0WithErrorHandling();
    const [isRequestError, setIsRequestError] = useState(null)

    let currentApplication = getSearchParamOrDefault(searchParams, "application", application.MTSelect)

    let showCompletedFiles = getSearchParamOrDefault(searchParams, "showCompletedFiles", false)

    const searchParamRefresh = (applicationForRefresh, showCompletedFilesFlag) => {
        searchParams.set("application", applicationForRefresh)
        searchParams.set("showCompletedFiles", showCompletedFilesFlag)
        setSearchParams(searchParams)
    }

    useEffect(() => {
        if (searchParams.has("application") || (searchParams.has("showCompletedFiles"))) {
            //We check for showCompletedFiles param even if the default value is a boolean if the param is a string because we want the URL to maintain consistent.
            if (!Object.values(application).includes(currentApplication) || (searchParams.has("showCompletedFiles") && !(searchParams.get("showCompletedFiles").toLowerCase() === 'true' || searchParams.get("showCompletedFiles").toLowerCase() === 'false'))) {
                currentApplication = (Object.values(application).includes(currentApplication) ? currentApplication : application.MTSelect)
                searchParamRefresh(currentApplication, showCompletedFiles)
            }
        } else {
            searchParamRefresh(currentApplication, showCompletedFiles)
        }
    }, [])

    const handleKill = async (logId, applicationToKillFrom) => {
        try {
            const accessToken = await getAccessTokenSilently();
            const fileProcessingKillRequest = {logId: logId, fileProcessingApplication: applicationToKillFrom}
            await postFileProcessingKillFile(fileProcessingKillRequest, accessToken);
            //Could be causing page to render more than needed. Which can cause more request to be executed than needed.
            setIsRequestError(null)
        } catch (err) {
            setIsRequestError(dataLoadErrorAlert(err?.response?.headers?.get('Request-Id')));
        }
    }

    const handleChange = (_event, applicationToChangeTo) => {
        searchParamRefresh(applicationToChangeTo, false)
    };

    const dataLoadErrorAlert = (requestId) => {
        return {
            severity: "error",
            title: "Oops!",
            message: <>There was an error loading the address hygiene files for the selected application.<br/><br/>Email: {user.email}<Typography sx={{overflowWrap: "anywhere"}}>URL: {window.location.href}</Typography><br/>{requestId && <span>Request ID: {requestId}</span>}</>
        };
    };

    return (
        <div id="file-processing">
            <Collapse data-testid="error-collapsable" in={!!isRequestError && (!!isRequestError.title || !!isRequestError.message)}>
                <Alert severity={isRequestError?.severity} action={<IconButton onClick={() => setIsRequestError({
                    ...isRequestError, ...{
                        title: '',
                        message: ''
                    }
                })}><CloseIcon/></IconButton>}>
                    <Typography variant="h6">{isRequestError?.title}</Typography>
                    <Typography>{isRequestError?.message}</Typography>
                </Alert>
            </Collapse>
            <Box sx={{width: '100%'}}>
                <TabContext value={currentApplication}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}>
                            <Tab label="MTSelect" value={application.MTSelect}/>
                            <Tab label="GHSelect" value={application.GHSelect}/>
                            <Tab label="BAC Internal and Preprocessor" value={application.BACInternalAndPreprocessor}/>
                        </TabList>
                    </Box>
                    <TabPanel data-testid="mtselect-panel" value={application.MTSelect}><FileProcessingApplication searchParamRefresh={searchParamRefresh} currentApplication={application.MTSelect} showCompletedFiles={showCompletedFiles} handleKill={handleKill} dataLoadErrorAlert={dataLoadErrorAlert} setIsRequestError={setIsRequestError} getAccessTokenSilently={getAccessTokenSilently} /></TabPanel>
                    <TabPanel data-testid="ghselect-panel" value={application.GHSelect}><FileProcessingApplication searchParamRefresh={searchParamRefresh} currentApplication={application.GHSelect} showCompletedFiles={showCompletedFiles} handleKill={handleKill} dataLoadErrorAlert={dataLoadErrorAlert} setIsRequestError={setIsRequestError} getAccessTokenSilently={getAccessTokenSilently} /></TabPanel>
                    <TabPanel data-testid="bacinternalandpreprocessor-panel" value={application.BACInternalAndPreprocessor}><FileProcessingApplication searchParamRefresh={searchParamRefresh} currentApplication={application.BACInternalAndPreprocessor} showCompletedFiles={showCompletedFiles} handleKill={handleKill} dataLoadErrorAlert={dataLoadErrorAlert} setIsRequestError={setIsRequestError} getAccessTokenSilently={getAccessTokenSilently} /></TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}

export default FileProcessing;
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogTitle, Divider, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import { Fragment, useEffect, useState } from 'react';
import { $$batchEditVersionNameDialog } from './model';

/**
 * CampaignVersionsDialog
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function BatchEditVersionNameDialog() {
  const open = useUnit($$batchEditVersionNameDialog.$isOpen);
  const state = useUnit($$batchEditVersionNameDialog.$state);

  const [newVersionName, setNewVersionName] = useState('');
  const [options, setOptions] = useState(/** @type {Pick<Reseller.Version, "id" | "name">[]} */ ([]));

  useEffect(() => {
    if (!open) {
      setNewVersionName('');
    }
    if (open) {
      setTimeout(() => {
        document.getElementsByName('newVersionName')[0]?.focus();
      }, 0);
    }
  }, [open]);

  useEffect(() => {
    if (state?.versionNames) {
      setOptions(state.versionNames);
    } else {
      setTimeout(() => {
        setOptions([]);
      }, 250);
    }
  }, [state?.versionNames]);

  const handleSubmit = () => {
    if (state) {
      $$batchEditVersionNameDialog.changesApproved({
        campaignId: state.campaignId,
        versionNames: options.map(option => ({ ...option, name: newVersionName }))
      });
      $$batchEditVersionNameDialog.close();
    }
  };

  return (
    <Dialog
      id="batch-edit-version-names-dialog"
      data-testid="batch-edit-version-names-dialog"
      open={open}
      onClose={() => $$batchEditVersionNameDialog.close()}
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{ sx: { borderRadius: 2, padding: 4, gap: 1 } }}
      disableEnforceFocus
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', flex: 1, padding: 0 }}>
        <Typography variant="h6">Batch edit version name</Typography>
        <IconButton onClick={() => $$batchEditVersionNameDialog.close()}>
          <CloseIcon data-testid="close-batch-edit-version-names-dialog" />
        </IconButton>
      </DialogTitle>
      <TextField
        id="new-version-name-input"
        name="newVersionName"
        label="New Version Name"
        value={newVersionName}
        onChange={e => setNewVersionName(e.target.value)}
        fullWidth
        onKeyUp={e => {
          if (e.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
      <List>
        {options.map(option => (
          <Fragment key={option.id}>
            <ListItem>
              <ListItemText primary={option.name} />
              <IconButton>
                <CloseIcon
                  onClick={() => {
                    setOptions(options.filter(o => o.id !== option.id));
                  }}
                />
              </IconButton>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <DialogActions sx={{ padding: 0 }}>
        <Button data-testid="cancel-button" variant="outlined" color="primary" sx={{ flex: 1 }} onClick={() => $$batchEditVersionNameDialog.close()}>
          Cancel
        </Button>
        <Button data-testid="apply-changes-button" variant="contained" onClick={handleSubmit}>
          Apply change to all selected
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

import {
  Card as MuiCard,
  CardMedia,
  CardContent,
  CardActions,
  CardHeader
} from '@mui/material';

import Button, {
  buttonVariants,
  buttonColors,
  buttonSizes
} from '~/components/Button';

const Card = ({
  children,
  header,
  subheader,
  cardActionsProps,
  actions,
  media,
  ...overrides
}) => (
  <MuiCard {...overrides}>
    {media}
    {header || subheader ? (
      <CardHeader subheader={subheader} title={header} />
    ) : null}
    <CardContent sx={{ color: 'text.secondary' }}>{children}</CardContent>
    {actions ? (
      <CardActions {...cardActionsProps}>
        {actions.map(({ label, href, onClick, ...actionProps }) => (
          <Button
            key={label}
            href={href}
            onClick={onClick}
            variant={buttonVariants.TEXT}
            color={buttonColors.PRIMARY}
            size={buttonSizes.SMALL}
            {...actionProps}
          >
            {label}
          </Button>
        ))}
      </CardActions>
    ) : null}
  </MuiCard>
);

Card.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  subheader: PropTypes.node,
  cardActionsProps: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  media: PropTypes.node
};

export default Card;
export { CardMedia };

import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import Card, { CardMedia } from '.';

const WidgetCard = ({
  children,
  header,
  imgSrc,
  imgAlt,
  imgHeight,
  actions,
  ...overrides
}) => (
  <Card
    {...(imgSrc &&
      imgAlt && {
        media: (
          <CardMedia
            component="img"
            alt={imgAlt}
            height={imgHeight}
            image={imgSrc}
          />
        )
      })}
    actions={actions}
    {...overrides}
  >
    {header ? (
      <Typography
        variant="body1"
        component="div"
        sx={{
          color: 'text.primary',
          fontWeight: 700,
          letterSpacing: -0.35,
          lineHeight: '175%'
        }}
      >
        {header}
      </Typography>
    ) : null}
    <div>{children}</div>
  </Card>
);

WidgetCard.propTypes = {
  children: PropTypes.node,
  header: Card.propTypes.header,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  imgHeight: PropTypes.number,
  actions: Card.propTypes.actions
};

export default WidgetCard;

import { prop, any, anyPass, isNil, isEmpty } from 'ramda';

export const requiredOnProp =
  (...dependentProps) =>
  (props, propName, componentName) => {
    const propValue = prop(propName, props);

    if (isNil(propValue)) {
      const hasDependentProp = any(dependentProp => {
        const dependentValue = prop(dependentProp, props);

        return !anyPass([isNil, isEmpty])(dependentValue);
      }, dependentProps);

      if (hasDependentProp) {
        return new Error(
          `in ${componentName}, ${propName} is required when '${dependentProps.join(
            ', '
          )}' is defined (instead found ${propValue})`
        );
      }
    }
  };

export const requiredUnlessProp =
  (...dependentProps) =>
  (props, propName, componentName) => {
    const propValue = prop(propName, props);

    if (isNil(propValue)) {
      const hasDependentProp = any(dependentProp => {
        const dependentValue = prop(dependentProp, props);

        return !anyPass([isNil, isEmpty])(dependentValue);
      }, dependentProps);

      if (!hasDependentProp) {
        return new Error(
          `in ${componentName}, ${propName} is required when '${dependentProps.join(
            ', '
          )}' is not defined (instead found ${propValue})`
        );
      }
    }
  };

export const requiredIfTrue =
  dependentProp => (props, propName, componentName) => {
    const propValue = prop(propName, props);

    if (isNil(propValue)) {
      const dependentValue = prop(dependentProp, props);

      if (dependentValue === true) {
        return new Error(
          `in ${componentName}, ${propName} is required when '${dependentValue}' is true (instead found ${propValue})`
        );
      }
    }
  };

export default {
  requiredOnProp
};

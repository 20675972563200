import React from 'react';
import PropTypes from 'prop-types';
import { Fab } from '@mui/material';

export const floatingActionButtonVariants = {
  CIRCULAR: 'circular',
  EXTENDED: 'extended'
};

export const floatingActionButtonSizes = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small'
};

const FloatingActionButton = ({
  children,
  variant,
  size,
  icon,
  disabled,
  onClick,
  sx,
  ...overrides
}) => {
  return (
    <Fab
      variant={variant}
      size={size}
      onClick={onClick}
      disabled={disabled}
      color="primary"
      sx={{
        gap: '13px',
        ...sx
      }}
      {...overrides}
    >
      {variant === floatingActionButtonVariants.EXTENDED && children}
      {icon}
    </Fab>
  );
};

FloatingActionButton.propTypes = {
  children: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(floatingActionButtonVariants)),
  size: PropTypes.oneOf(Object.values(floatingActionButtonSizes)),
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  sx: PropTypes.shape()
};

FloatingActionButton.defaultProps = {
  variant: floatingActionButtonVariants.EXTENDED,
  size: floatingActionButtonSizes.MEDIUM,
  disabled: false
};

export default FloatingActionButton;

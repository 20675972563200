import * as React from "react";

/**
 * User context containing data on the user's permissions and personas.
 *
 * NOTE: At some point we can consider incorporating the `user` object from `useAuth0()` hook in here as well.
 *
 * @type {React.Context<{isMt: boolean, activePersona: {}, permissions: *[], isSt: boolean, personas: *[]}>}
 */
export const UserContext = React.createContext({
    permissions: [],
    personas: [],
    activePersona: {},
    isMt: false,
    isSt: false
});

import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogContentText as MuiDialogContentText,
  DialogActions
} from '@mui/material';

export const DialogContentText = MuiDialogContentText;

export const dialogMaxWidths = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl'
};

const Dialog = ({
  children,
  open,
  fullWidth,
  maxWidth,
  onClose,
  title,
  actions,
  ...overrides
}) => (
  <MuiDialog
    open={open}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    onClose={onClose}
    {...overrides}
  >
    {title ? <DialogTitle>{title}</DialogTitle> : null}
    <DialogContent>{children}</DialogContent>
    {actions ? <DialogActions>{actions}</DialogActions> : null}
  </MuiDialog>
);

Dialog.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf(Object.values(dialogMaxWidths)),
  onClose: PropTypes.func,
  title: PropTypes.string,
  actions: PropTypes.node
};

export default Dialog;

import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';

export const colors = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  GREY: 'grey',
  TEXT: 'text'
};

export const typography = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'buttonLarge',
  'button',
  'buttonSmall',
  'caption',
  'overline',
  'avatarLetter',
  'inputLabel',
  'helperText',
  'inputText',
  'tooltip'
];

import React from 'react';
import { Container, Typography } from '@mui/material';
import BiDashboardList from "../components/BiDashboardList";

const BiDashboards = () => {

    return (
        <Container>
            <Typography variant="h5" color="text.primary">Dashboards</Typography>
            <BiDashboardList/>
        </Container>
    );
};

BiDashboards.propTypes = {
};

export default BiDashboards;
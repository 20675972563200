import React from 'react';
import PropTypes from 'prop-types';

import { Alert as MuiAlert, AlertTitle } from '@mui/material';

export const alertSeverities = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

export const alertVariants = {
  STANDARD: 'standard',
  OUTLINED: 'outlined',
  FILLED: 'filled'
};

const Alert = ({
  children,
  severity,
  variant,
  action,
  title,
  sx,
  ...overrides
}) => (
  <MuiAlert
    severity={severity}
    variant={variant}
    action={action}
    sx={{
      ...(variant === alertVariants.OUTLINED && {
        bgcolor: 'background.paper'
      }),
      ...sx
    }}
    {...overrides}
  >
    {title ? <AlertTitle>{title}</AlertTitle> : null}
    {children}
  </MuiAlert>
);

Alert.propTypes = {
  children: PropTypes.node,
  severity: PropTypes.oneOf(Object.values(alertSeverities)),
  variant: PropTypes.oneOf(Object.values(alertVariants)),
  action: PropTypes.node,
  title: PropTypes.string,
  sx: PropTypes.shape()
};

export default Alert;

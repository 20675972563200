import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import Card from '.';

const IntegrationCard = ({
  children,
  avatar,
  title,
  switch: switchProp,
  actions,
  ...overrides
}) => (
  <Card actions={actions} {...overrides}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      {avatar ? <Box sx={{ mr: 2 }}>{avatar}</Box> : null}
      <Typography
        variant="h6"
        component="div"
        sx={{ color: 'text.primary', mr: 3 }}
      >
        {title}
      </Typography>
      {switchProp ? <Box sx={{ ml: 'auto' }}>{switchProp}</Box> : null}
    </Box>
    <div>{children}</div>
  </Card>
);

IntegrationCard.propTypes = {
  children: PropTypes.node,
  avatar: PropTypes.node,
  title: PropTypes.string,
  switch: PropTypes.node,
  actions: Card.propTypes.actions
};

IntegrationCard.defaultProps = {
  avatar: null,
  title: null,
  onChange: null
};

export default IntegrationCard;

import { Cancel, DeleteForever, Edit, Save } from '@mui/icons-material';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { DataGridPro, gridClasses, GridRowModes } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { useUnit } from 'effector-react';
import { DataGridStyles } from '../../util/DataGridStyles';
import { numberWithCommas } from '../../util/JsonUtils';
import DateCell from '../DateCell';
import { EditableStringCell } from './EditableStringCell';
import { $$campaignVersionsDialog } from './model';

/** @typedef {import('./model').GridRowId} GridRowId */

/**
 * CampaignVersionsTable
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function CampaignVersionsTable() {
  const dataGridProps = $$campaignVersionsDialog.$$versions.useDataGrid();

  const errors = useUnit($$campaignVersionsDialog.$$versions.$errors);
  const highlightedRows = useUnit($$campaignVersionsDialog.$$versions.$highlightedRows);
  const selectedRowIds = useUnit($$campaignVersionsDialog.$$versions.$selectedRowsIds);
  const unsavedChanges = useUnit($$campaignVersionsDialog.$$versions.$unsavedChanges);
  const isValidationPending = useUnit($$campaignVersionsDialog.$isValidationPending);
  const isSavePending = useUnit($$campaignVersionsDialog.$isSavePending);

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Reseller.Version>} */
  const dateColumnProps = {
    field: '',
    editable: true,
    flex: 1,
    valueGetter: params => dayjs(params).toDate(),
    valueFormatter: date => dayjs(date).format('MM/DD/YYYY'),
    renderCell: ({ row, id, field }) => (
      <DateCell
        id={id}
        field={field}
        row={dataGridProps.rows.find(r => r.id === id) || row}
        onValueChanged={$$campaignVersionsDialog.$$versions.cellValueChanged}
        errorMessage={errors.find(e => e.id === id && e.field === field)?.error}
        cellMode="view"
      />
    ),
    renderEditCell: ({ row, id, field }) => (
      <DateCell
        id={id}
        field={field}
        row={dataGridProps.rows.find(r => r.id === id) || row}
        onValueChanged={$$campaignVersionsDialog.$$versions.cellValueChanged}
        errorMessage={errors.find(e => e.id === id && e.field === field)?.error}
        cellMode="edit"
      />
    ),
    type: 'date'
  };

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Reseller.Version>} */
  const editableStringColumnProps = {
    field: '',
    editable: true,
    flex: 1,
    renderCell: params => <EditableStringCell {...params} $$table={$$campaignVersionsDialog.$$versions} />,
    renderEditCell: params => <EditableStringCell {...params} $$table={$$campaignVersionsDialog.$$versions} />
  };

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Reseller.Version>[]} */
  const columns = [
    { ...editableStringColumnProps, field: 'name', headerName: 'Version Name' },
    { ...editableStringColumnProps, field: 'number', headerName: 'Version Number' },
    { ...dateColumnProps, field: 'firstMailDate', headerName: 'First Mail Date' },
    { ...dateColumnProps, field: 'lastMailDate', headerName: 'Last Mail Date' },
    { ...dateColumnProps, field: 'inHomeStartDate', headerName: 'In-Home Start' },
    { ...dateColumnProps, field: 'inHomeEndDate', headerName: 'In-Home End' },
    { field: 'mailClass', headerName: 'Mail Class', flex: 1, valueGetter: params => /** @type {Reseller.Version['mailClass']} */ (params).description },
    { field: 'mailQuantity', headerName: 'Mail Qty', flex: 1, valueGetter: numberWithCommas },
    {
      field: 'actions',
      type: 'actions',
      editable: false,
      headerName: 'Actions',
      headerAlign: 'center',
      getActions: ({ id }) => {
        const isInEditMode = dataGridProps.rowModesModel[id]?.mode === GridRowModes.Edit;
        const version = dataGridProps.rows.find(row => row.id === id);
        const isCellWithError = errors.some(e => e.id === id);
        const isRowHasUnsavedChanges = unsavedChanges.find(r => r.id === id);
        if (!version) {
          return [];
        }
        return isInEditMode
          ? [
              <Tooltip key={`version-save-button-${id}`} title={!isCellWithError && (isRowHasUnsavedChanges ? 'Save' : 'No changes to save')} arrow>
                <span>
                  <IconButton key={`version-save-button-${id}`} disabled={isCellWithError || !isRowHasUnsavedChanges} onClick={() => $$campaignVersionsDialog.singleRowSaveClicked(id)}>
                    {isValidationPending || isSavePending ? <CircularProgress size={24} color="inherit" /> : <Save fontSize="small" />}
                  </IconButton>
                </span>
              </Tooltip>,
              <IconButton key={`version-cancel-button-${id}`} onClick={() => $$campaignVersionsDialog.cancelClicked(id)}>
                <Cancel fontSize="small" />
              </IconButton>
            ]
          : [
              <IconButton key={`version-edit-button-${id}`} onClick={() => $$campaignVersionsDialog.editClicked(id)}>
                <Edit fontSize="small" color="primary" />
              </IconButton>,
              <Tooltip key={`version-delete-button-${id}`} title={version.hasScans ? 'Scans are associated with this version and it cannot be deleted' : 'Delete'} arrow>
                <span>
                  <IconButton onClick={() => $$campaignVersionsDialog.deleteSingleClicked(id)} disabled={version.hasScans} sx={{ '&.Mui-disabled': { pointerEvents: 'auto' } }}>
                    <DeleteForever fontSize="small" color={version.hasScans ? /** @type {'secondary'} */ ('secondary.enabled') : 'warning'} />
                  </IconButton>
                </span>
              </Tooltip>
            ];
      }
    }
  ];

  return (
    <DataGridPro
      data-testid="campaign-versions-table"
      {...dataGridProps}
      columns={columns}
      onRowDoubleClick={({ id }) => $$campaignVersionsDialog.editClicked(id)}
      onCellDoubleClick={(_, event) => {
        const target = /** @type {HTMLElement} */ (event.target);
        setTimeout(() => (target.tagName === 'INPUT' ? target : target.querySelector('input'))?.focus(), 0);
      }}
      pagination
      checkboxSelection={true}
      keepNonExistentRowsSelected={true}
      disableRowSelectionOnClick={true}
      pageSizeOptions={[5, 10, 25, 50]}
      autosizeOnMount
      autosizeOptions={{
        includeOutliers: true,
        includeHeaders: true
      }}
      initialState={{ columns: { columnVisibilityModel: { id: false } } }}
      getCellClassName={params =>
        Object.entries({
          [`${gridClasses.cell}--error`]: Boolean(errors.find(e => e.id === params.id && e.field === params.field)),
          'editable-date-cell': dataGridProps.rowModesModel[params.id]?.mode === GridRowModes.Edit && params.isEditable === true,
          'editable-date': dataGridProps.rowModesModel[params.id]?.mode === GridRowModes.Edit && params.colDef.type === 'date',
          [`${gridClasses.cell}--edited`]: unsavedChanges.find(r => r.id === params.id && r.field === params.field),
          [`${gridClasses.cell}--success`]: highlightedRows.includes(params.id),
          [`${gridClasses.cell}--selected`]: selectedRowIds.includes(params.id)
        })
          .filter(([, value]) => value)
          .map(([key]) => key)
          .slice(0, 1)
          .join(' ')
      }
      sx={DataGridStyles}
    />
  );
}

// @ts-ignore
import { Dialog, DialogContentText, dialogMaxWidths } from '@ghs/grayhair-component-library';
import { useUnit } from 'effector-react';
import { $$blockingDialog } from './model';

/**
 * BlockingDialog - a dialog without any buttons that must be opened and closed programmatically.
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function BlockingDialog() {
  const open = useUnit($$blockingDialog.$isOpen);
  const state = useUnit($$blockingDialog.$state);

  if (!state) {
    return null;
  }

  const { title, content } = state;

  return (
    <Dialog
      title={title}
      open={open}
      fullWidth={true}
      maxWidth={dialogMaxWidths.XS}
      data-testid="blocking-dialog"
    >
      <DialogContentText data-testid="blocking-dialog-text">{content}</DialogContentText>
    </Dialog>
  );
}

import {fetchDataAxios, postDataAxios} from "./DataService";

export const fetchFileProcessingGetFiles = async (fileProcessingGetFilesRequest, accessToken) => {
    return fetchDataAxios(`${process.env.REACT_APP_API_SERVER_URL}/fileProcessing/getFiles`, accessToken, {
        params: { ...Object.fromEntries(Object.entries(fileProcessingGetFilesRequest).filter(([, value]) => value !== '')) }
    });
}

export const postFileProcessingKillFile = async (fileProcessingKillRequest, accessToken) => {
    return postDataAxios(`${process.env.REACT_APP_API_SERVER_URL}/fileProcessing/killFile`, accessToken, null, {
        params: { ...Object.fromEntries(Object.entries(fileProcessingKillRequest).filter(([, value]) => value !== '')) }
    });
}

import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * Auth0ProviderWithHistory component for setting up Auth0.
 *
 * @param {object} props props for the component
 * @param {Array} props.children child components
 * @returns {Element} Auth0ProviderWithHistory component
 * @class
 */
export const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = async (appState) => {
    // Delete Sisense dashboards info so it's reloaded
    sessionStorage.removeItem('dashboards-info');

    if (appState) {
      navigate(appState?.returnTo || window.location.pathname);
    } else {
      console.log("There is no state");
      // TODO: Handle callback with no state
      navigate("/");
    }
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      useRefreshTokensFallback
    >
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.element,
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SpeedDial as MuiSpeedDial, SpeedDialAction } from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

export const speedDialDirections = {
  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right'
};

export const speedDialSizes = {
  SMALL: 'small',
  MEDIUM: 'medium'
};

const SpeedDial = ({
  direction,
  size,
  children,
  FabProps,
  initialOpen,
  ...overrides
}) => {
  const [open, setOpen] = useState(initialOpen);

  return (
    <MuiSpeedDial
      direction={direction}
      icon={<SpeedDialIcon />}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      FabProps={{
        size,
        ...FabProps
      }}
      ariaLabel="speed-dial"
      {...overrides}
    >
      {children}
    </MuiSpeedDial>
  );
};

SpeedDial.propTypes = {
  direction: PropTypes.oneOf(Object.values(speedDialDirections)),
  size: PropTypes.oneOf(Object.values(speedDialSizes)),
  children: PropTypes.node,
  initialOpen: PropTypes.bool,
  FabProps: PropTypes.object.isRequired
};

SpeedDial.defaultProps = {
  direction: speedDialDirections.UP,
  size: speedDialSizes.MEDIUM,
  initialOpen: false
};

export { SpeedDialAction };

export default SpeedDial;

import PropTypes from "prop-types";

export const FormDataPropTypes = PropTypes.shape({
    company: PropTypes.string,
    street: PropTypes.string,
    street2: PropTypes.string,
    urbanization: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    zipPlusFour: PropTypes.string,
    standardizeStreets: PropTypes.bool,
    useMixedCase: PropTypes.bool,
    retainAddressLocation: PropTypes.bool,
    suiteLinkToAddress2: PropTypes.bool,
})

export const IsRequestErrorPropTypes = PropTypes.shape({
    severity: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ])
})

export const SearchResultsPropTypes = PropTypes.shape({
    barCode: PropTypes.string,
    crrt: PropTypes.string,
    city: PropTypes.string,
    company: PropTypes.string,
    congressionalDistrict: PropTypes.string,
    county: PropTypes.string,
    countyNumber: PropTypes.string,
    dpv: PropTypes.string,
    dpvCode: PropTypes.string,
    dpvDescription: PropTypes.string,
    deliveryPoint: PropTypes.string,
    houseNumber: PropTypes.string,
    isHighriseDefault: PropTypes.bool,
    isRuralRouteDefault: PropTypes.bool,
    lacs: PropTypes.string,
    lineOfTravel: PropTypes.string,
    lineOfTravelDirection: PropTypes.string,
    lookupReturnCode: PropTypes.string,
    lookupReturnDescription: PropTypes.string,
    pmb: PropTypes.string,
    postDirectional: PropTypes.string,
    preDirectional: PropTypes.string,
    rdi: PropTypes.string,
    recordType: PropTypes.string,
    secondaryUnitDesignator: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    street2: PropTypes.string,
    streetName: PropTypes.string,
    streetSuffix: PropTypes.string,
    suite: PropTypes.string,
    unitNumber: PropTypes.string,
    urbanization: PropTypes.string,
    zip: PropTypes.string,
    zipPlusFour: PropTypes.string,
})
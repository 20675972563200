import React from 'react';
import PropTypes from 'prop-types';

import {
  List,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  ListItemButton,
  ListItemIcon,
  ListItemAvatar
} from '@mui/material';

const ListItemText = ({ inset, ...overrides }) => (
  <MuiListItemText
    inset={inset}
    primaryTypographyProps={inset && { variant: 'body2' }}
    {...overrides}
  />
);

ListItemText.propTypes = {
  inset: PropTypes.bool
};

const ListItem = ({ sx, ...overrides }) => (
  <MuiListItem
    sx={{
      '& .MuiListItemIcon-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        mr: 1
      },
      ...sx
    }}
    {...overrides}
  />
);

ListItem.propTypes = {
  sx: PropTypes.shape()
};

export default List;
export { ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar };

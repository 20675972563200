import PropTypes from "prop-types";

/**
 * Content for the delete In-Home window confirmation dialog.
 *
 * @param {object} props the props for this component
 * @param {string} props.customer the customer name for the in home window
 * @param {string} props.lineOfBusiness the LOB name for the in home window
 * @param {string} props.mailClass the mail class of the in home window
 * @returns {JSX.Element} the dialog content
 * @constructor
 */
function DeleteInHomeWindowConfirmationContent({ customer, lineOfBusiness, mailClass }) {
  return <>
    {`Are you sure you want to delete the In-Home window?`}
    <br/>
    {`NOTE: This will not change any existing campaigns.`}
    <br/>
    <br/><strong>Customer: </strong>{`${customer}`}
    {lineOfBusiness ? <><br/><strong>Line of Business: </strong>{`${lineOfBusiness}`}</> : null}
    <br/><strong>Mail Class: </strong>{`${mailClass}`}
  </>
}

DeleteInHomeWindowConfirmationContent.propTypes = {
  customer: PropTypes.string.isRequired,
  lineOfBusiness: PropTypes.string,
  mailClass: PropTypes.string.isRequired
}

export default DeleteInHomeWindowConfirmationContent;
import React from 'react';
import PropTypes from 'prop-types';
import { pick } from 'ramda';

import { Radio as MuiRadio, FormControlLabel } from '@mui/material';

import { colors as themeColors } from '~/theme';

export const radioColors = {
  DEFAULT: 'default',
  ...pick(
    ['PRIMARY', 'SECONDARY', 'SUCCESS', 'INFO', 'ERROR', 'WARNING'],
    themeColors
  )
};

const Radio = ({
  label,
  checked,
  color,
  disabled,
  id,
  name,
  value,
  onChange,
  small,
  ...overrides
}) => (
  <FormControlLabel
    control={<MuiRadio color={color} {...(small && { size: 'small' })} />}
    label={label}
    checked={checked}
    disabled={disabled}
    id={id}
    name={name}
    value={value}
    onChange={onChange}
    {...overrides}
  />
);

Radio.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(radioColors)),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  small: PropTypes.bool
};

Radio.defaultProps = {
  small: false
};

export default Radio;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Accordion as MuiAccordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Container,
  Stack
} from '@mui/material';

import { ExpandMore } from '@mui/icons-material';

const Accordion = ({
  children,
  heading,
  secondaryHeading,
  disabled,
  initialExpanded,
  subtitle,
  ...overrides
}) => {
  const [isExpanded, setExpanded] = useState(initialExpanded);

  return (
    <MuiAccordion
      disabled={disabled}
      expanded={isExpanded}
      onChange={() => setExpanded(!isExpanded)}
      sx={{
        zIndex: 0,
        '&:before': {
          position: 'absolute'
        }
      }}
      {...overrides}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'stretch',
            position: 'relative'
          }}
        >
          <Stack direction="column" sx={{ flex: 1 }}>
            <Typography>{heading}</Typography>
            {subtitle && (
              <Typography color="text.secondary">{subtitle}</Typography>
            )}
          </Stack>
          {secondaryHeading && (
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ flex: 'auto', zIndex: '99999' }}
            >
              {secondaryHeading}
            </Typography>
          )}
        </Container>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

Accordion.propTypes = {
  heading: PropTypes.string.isRequired,
  secondaryHeading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  initialExpanded: PropTypes.bool,
  subtitle: PropTypes.string
};

Accordion.defaultProps = {
  initialExpanded: false
};

export default Accordion;

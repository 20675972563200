export const addressInputHelpDescriptions = {
    company: (
        <>
            Name of company, business or non-residential building e.g. &quot;Kingswood Elementary School / Walmart&quot;
        </>
    ),
    street: (
        <>
            Sets the &quot;Street&quot; field of the lookup record. Use this field type to pass all information relating to the primary street address, including the street name, house number, directionals and street suffix. You can also use this field type to pass all other acceptable forms of primary
        </>
    ),
    street2: (
        <>
            Sets the &quot;Street2&quot; field of the lookup record. Use of this field type is intended to pass information relating to the secondary street address information (suite and apartment information).
        </>
    ),
    urbanization: (
        <>
            Puerto Rico urbanization
        </>
    ),
    city: (
        <>
            Sets the &quot;City&quot; field of the lookup record. Use this field type to pass either an input city name or an entire input &quot;last line&quot; string — that is, a formatted city/state/ZIP string, such as &quot;Baltimore, MD 21234&quot;
        </>
    ),
    state: (
        <>
            Uses either the 2-character state abbreviation or the full state name e.g. &quot;FL&quot;, &quot;Florida&quot;
        </>
    ),
    zip: (
        <>
            5-digit numerical code or a formatted 9-digit code<br/>
            - IE: 08054-1234
        </>
    ),
}
export const advancedOptionsHelpDescriptions = {
    useMixedCase: (
        <>
            If this value is checked or &quot;true&quot;, the address will be returned in mixed case letters; if value is not checked or &quot;false&quot;, the address returns in all uppercase letters.
        </>
    ),
    retainAddressLocation: (
        <>
            If this value is checked or &quot;true&quot;, secondary address information returned in CASS standardized format will remain where it was located on input (in the primary or secondary address field); if value is not checked  or &quot;false&quot;, returns CASS standardized results in the format returned by the CASS engine.
        </>
    ),
    suiteLinkToAddress2: (
        <>
            If this value is checked or &quot;true&quot;, new secondary address created by SuiteLink processing will be appended to the data in Address Line 2; if value is not checked or &quot;false&quot;, returns CASS standardized results in the format returned by the CASS engine.
        </>
    ),
}
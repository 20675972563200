import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress as MuiCircularProgress,
  LinearProgress as MuiLinearProgress,
  Box,
  Typography
} from '@mui/material';

export const progressVariants = {
  BUFFER: 'buffer',
  DETERMINATE: 'determinate',
  INDETERMINATE: 'indeterminate'
};

export const progressColors = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  INHERIT: 'inherit'
};

const Progress = ({
  circular,
  variant,
  color,
  value,
  withLabel,
  valueBuffer,
  ariaLabel,
  ...overrides
}) => (
  <>
    {circular ? (
      <Box
        component="span"
        sx={{ position: 'relative', display: 'inline-flex' }}
      >
        <MuiCircularProgress
          value={value}
          color={color}
          variant={variant}
          aria-label={ariaLabel}
          {...overrides}
        />
        <Box
          component="span"
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {withLabel && (
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
            >
              {value}%
            </Typography>
          )}
        </Box>
      </Box>
    ) : (
      <Box
        component="span"
        sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
      >
        <Box component="span" sx={{ display: 'block', width: '100%', mr: 1 }}>
          <MuiLinearProgress
            valueBuffer={valueBuffer}
            value={value}
            color={color}
            variant={variant}
            aria-label={ariaLabel}
            {...overrides}
          />
        </Box>
        {withLabel && (
          <Box component="span" sx={{ display: 'block', minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">
              {value}%
            </Typography>
          </Box>
        )}
      </Box>
    )}
  </>
);

Progress.propTypes = {
  withLabel: PropTypes.bool,
  circular: PropTypes.bool,
  value: PropTypes.number,
  valueBuffer: PropTypes.number,
  ariaLabel: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(progressVariants)),
  color: PropTypes.oneOf(Object.values(progressColors))
};

Progress.defaultProps = {
  withLabel: null,
  value: null,
  circular: false,
  ariaLabel: null,
  valueBuffer: null,
  variant: progressVariants.INDETERMINATE,
  color: progressColors.PRIMARY
};

export default Progress;

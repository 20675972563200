import { TextField, Tooltip, Typography } from '@mui/material';
import { GridCellModes, GridRowModes } from '@mui/x-data-grid-pro';
import { useUnit } from 'effector-react';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

/**
 * EditableStringCell
 *
 * @template {object} T
 * @param {{
 *  $$table: DataGridFactory<T & object>;
 * } & import('@mui/x-data-grid-pro').GridRenderCellParams<T & object> & Omit<import('@mui/material').TextFieldProps, 'id'>} props - props
 * @returns {React.ReactNode} - JSX Element
 */
export function EditableStringCell(props) {
  const { id, field, $$table, ...rest } = props;

  const row = useUnit($$table.$rows.map(rows => rows.find(r => r.id === id) || null));
  const error = useUnit($$table.$errors.map(errors => errors.find(e => e.id === id && String(e.field) === field)?.error || null));
  const mode = useUnit($$table.$rowModesModel.map(rowModes => (rowModes[id]?.mode === GridRowModes.Edit ? GridCellModes.Edit : GridCellModes.View)));

  const value = row?.[field];

  return (
    <Tooltip
      disableHoverListener={false}
      title={error ? error : undefined}
      arrow
      componentsProps={{ popper: { sx: { [`.MuiTooltip-tooltip`]: { background: theme => theme.palette.error.main } } }, arrow: { sx: { color: theme => theme.palette.error.main } } }}
    >
      {useMemo(
        () =>
          mode === 'edit' ? (
            <TextField {...rest} value={value} onChange={e => $$table.cellValueChanged({ id, field, value: e.target.value })} sx={{ width: '100%', ...(rest.sx || {}) }} />
          ) : (
            <Typography variant="body2" sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
              {value}
            </Typography>
          ),
        [value, mode]
      )}
    </Tooltip>
  );
}

EditableStringCell.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  field: PropTypes.string.isRequired,
  $$table: PropTypes.object.isRequired
};

import { createEvent } from 'effector';
import { createDialog } from '../../util/createDialog.js';

/** @type {DialogFactory<{ campaignId: Reseller.Campaign['id'], versionNames: Pick<Reseller.Version,'id' | 'name'>[]  }>} */
const dialog = createDialog();

/** @type {import('effector').EventCallable<{ campaignId: Reseller.Campaign['id'], versionNames: Pick<Reseller.Version, 'id' | 'name' >[] }>} */
const changesApproved = createEvent();

export const $$batchEditVersionNameDialog = {
  ...dialog,

  changesApproved
};

import { DeleteForever } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DataGridStylesWithoutSelectionOverride } from '../../util/DataGridStyles';
import { $$manageUserRolesModal } from './model';
import {useUnit} from "effector-react";

/**
 * UserRolesTable component
 *
 * @returns {React.ReactNode} UserRolesTable
 */
export default function UserRolesTable() {
  const dataGridProps = $$manageUserRolesModal.$$rolesDataGrid.useDataGrid();
  const isManagingCurrentUser = useUnit($$manageUserRolesModal.$isManagingCurrentUser);

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Roles.Role, any, any>[]} */
  const customerTableColumns = [
    { field: 'name', headerName: 'Role', minWidth: 160, flex: 4 },
    { field: 'description', headerName: 'Description', minWidth: 160, flex: 6 },
    {
      field: 'actions',
      type: 'actions',
      editable: false,
      headerName: 'Actions',
      headerAlign: 'center',
      getActions: ({ id }) => [
        <IconButton key="delete" onClick={() => $$manageUserRolesModal.openDeleteConfirmation(id + '')} disabled={isManagingCurrentUser}>
          <DeleteForever fontSize="small" color={isManagingCurrentUser ? "disabled" : "warning"} />
        </IconButton>
      ]
    }
  ];

  return (
    <DataGridPro
      {...dataGridProps}
      autoHeight
      columns={customerTableColumns}
      disableRowSelectionOnClick
      editMode="row"
      getRowId={row => row.id}
      initialState={{
        columns: { columnVisibilityModel: { id: false } },
        pagination: { paginationModel: { pageSize: 10 } }
      }}
      pagination={false}
      hideFooter={true}
      autosizeOnMount
      autosizeOptions={{
        includeOutliers: true,
        includeHeaders: true
      }}
      sx={DataGridStylesWithoutSelectionOverride}
    />
  );
}

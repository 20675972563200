import '../field-description-table.css'

const AdvancedAddressFieldDescriptions = (<>
    <table className={"table"}>
        <thead>
        <tr>
            <th className={"field-description"}>Field</th>
            <th className={"field-description"}>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td className={"field-name"}>Routing Code Barcode</td>
            <td className={"field-description"}>Bar code for the address.</td>
        </tr>
        <tr>
            <td className={"field-name"}>Congressional District</td>
            <td className={"field-description"}>Congressional District of the address.</td>
        </tr>
        <tr>
            <td className={"field-name"}>County Name</td>
            <td className={"field-description"}>County in which the address resides.</td>
        </tr>
        <tr>
            <td className={"field-name"}>County Number</td>
            <td className={"field-description"}>Retrieves the output county number. This is the 3-digit USPS code for the county in which the address resides.</td>
        </tr>
        <tr>
            <td className={"field-name"}>Carrier Route</td>
            <td className={"field-description"}>Retrieves the output carrier route code. This is a 4-digit code assigned to each address on a mail carrier&rsquo;s route.</td>
        </tr>
        <tr>
            <td className={"field-name"}>Delivery Point Validation</td>
            <td className={"field-description"}>Delivery Point Validation indicators.
                <ul>
                    <li className={"unordered-list-item"}><strong>Y</strong>: Address was DPV/DSF2 confirmed for both primary and secondary numbers necessary to determine a valid delivery point.</li>
                    <li className={"unordered-list-item"}><strong>D</strong>: Address was DPV/DSF2 confirmed for the primary number only. Secondary information was missing.</li>
                    <li className={"unordered-list-item"}><strong>S</strong>: Address was DPV confirmed for the primary number only, the Secondary number information was present but not confirmed or a single trailing alpha on a primary number was dropped to make a DPV match, and Secondary information required.</li>
                    <li className={"unordered-list-item"}><strong>N</strong>: Both primary and secondary (if present) number information failed to DPV/DSF2 confirm.</li>
                    <li className={"unordered-list-item"}><strong>R</strong>: Address confirmed but assigned to phantom route R777 or R779 and USPS delivery is not provided.</li>
                    <li className={"unordered-list-item"}><strong>Blank</strong>: Address was not presented to DPV/DSF2.</li>
                </ul></td>
        </tr>
        <tr>
            <td className={"field-name"}>Delivery Point Validation Code</td>
            <td className={"field-name"}>Delivery Point Validation footnotes / codes. Comprised of 2-character codes.
                <ul>
                    <li className={"unordered-list-item"}><strong>AA</strong>: Input Address Matched to the ZIP+4 file.</li>
                    <li className={"unordered-list-item"}><strong>A1</strong>: Input Address Not Matched to the ZIP+4 file.</li>
                    <li className={"unordered-list-item"}><strong>M1</strong>: Input Address Primary Number Missing.</li>
                    <li className={"unordered-list-item"}><strong>M3</strong>: Input Address Primary Number Invalid.</li>
                    <li className={"unordered-list-item"}><strong>P1</strong>: Input Address PO, RR or HC Box number missing.</li>
                    <li className={"unordered-list-item"}><strong>P3</strong>: Input Address PO, RR, or HC Box number invalid.</li>
                    <li className={"unordered-list-item"}><strong>BB</strong>: Input Address Matched to DPV (all components).</li>
                    <li className={"unordered-list-item"}><strong>RR</strong>: Input Address Matched to CMRA and PMB information is present (12 Main St PMB 123).</li>
                    <li className={"unordered-list-item"}><strong>CC</strong>: Input Address Primary Number Matched to DPV but Secondary Number not Matched (present but invalid) - but Secondary is not needed.</li>
                    <li className={"unordered-list-item"}><strong>C1</strong>: Input Address Primary Number Matched to DPV but Secondary Number did not Match (present but invalid), and Secondary Number is needed.</li>
                    <li className={"unordered-list-item"}><strong>N1</strong>: Input Address Primary Number Matched to DPV but Address Missing Secondary Number.</li>
                    <li className={"unordered-list-item"}><strong>R1</strong>: Input Address Matched to CMRA but PMB information is not present (12 Main St).</li>
                    <li className={"unordered-list-item"}><strong>F1</strong>: Input Address Matched to a Military Address (CMR 23 BOX 456).</li>
                    <li className={"unordered-list-item"}><strong>U1</strong>: Input Address Matched to a Unique ZIP Code (a local college obtained a unique ZIP for all addresses on the campus, but all mal is delivered by the USPS to the college’s mail center).</li>
                    <li className={"unordered-list-item"}><strong>G1</strong>: Input Address Matched to a General Delivery Address (General Delivery, North Aurora IL 60542).</li>
                    <li className={"unordered-list-item"}><strong>TA</strong>: Input Address coded by dropping single trailing alpha from the Primary Number.</li>
                    <li className={"unordered-list-item"}><strong>PB</strong>: Input Address matched to a PBSA - PO Box Street Address equivalent.</li>
                    <li className={"unordered-list-item"}><strong>R7</strong>: Input Address coded to an R777/R779 address (physical address not delivered to).</li>
                    <li className={"unordered-list-item"}><strong>IA</strong>: Input Address matched to an Informed Address.</li>
                </ul></td>
        </tr>
        <tr>
            <td className={"field-name"}>Delivery Point Validation Description</td>
            <td className={"field-description"}>Reason for the DPV codes.</td>
        </tr>
        <tr>
            <td className={"field-name"}>Highrise Default</td>
            <td className={"field-description"}>True if the address is high rise default.</td>
        </tr>
        <tr>
            <td className={"field-name"}>Rural Route Default</td>
            <td className={"field-description"}>True if the address is rural route default.</td>
        </tr>
        <tr>
            <td className={"field-name"}>LACSLink</td>
            <td className={"field-description"}>A 1-character Locatable Address Conversion Service (LACS) code to identify records that have been converted to the LACS system. The LACS system is being used for many rural route addresses that are being modified to city style addresses so that emergency vehicles can more easily find these locations.</td>
        </tr>
        <tr>
            <td className={"field-name"}>Line of Travel</td>
            <td className={"field-description"}>4-digit Line of Travel (LOT) number. Indicates the order in which delivery will be made within a given ZIP+4. Use along with the Carrier Route and Line of Travel Direction to qualify for High Density, High Density Plus and Saturation discounts.</td>
        </tr>
        <tr>
            <td className={"field-name"}>Line of Travel Direction</td>
            <td className={"field-description"}>1-character sequence code (either &ldquo;A&rdquo; for ascending or &ldquo;D&rdquo; for descending) that indicates the order in which the mail carrier delivers mail within a given carrier route. Use along with LineOfTravel as part of qualifying for Enhanced Carrier Route rates.</td>
        </tr>
        <tr>
            <td className={"field-name"}>Error Description</td>
            <td className={"field-description"}>Retrieves the message text associated with the error code that Address Verification assigns if the input address could not be matched. This is a descriptive sentence or paragraph that describes the reason it could not correct the input record. If Address Verification matches the input address successfully, then this field returns a blank string.</td>
        </tr>
        <tr>
            <td className={"field-name"}>Residential Delivery Indicator</td>
            <td className={"field-description"}>Residential Delivery Indicator.</td>
        </tr>
        <tr>
            <td className={"field-name"}>Record Type</td>
            <td className={"field-description"}>Record Type
                <ul>
                    <li className={"unordered-list-item"}><strong>P</strong>: PO Box Address Match.</li>
                    <li className={"unordered-list-item"}><strong>G</strong>: General Delivery Address Match.</li>
                    <li className={"unordered-list-item"}><strong>F</strong>: Firm Address Match.</li>
                    <li className={"unordered-list-item"}><strong>S</strong>: Street Address Match (including military address APO/FPO/DPO).</li>
                    <li className={"unordered-list-item"}><strong>R</strong>: Rural Route Address Match.</li>
                    <li className={"unordered-list-item"}><strong>H</strong>: High-rise Address Match.</li>
                    <li className={"unordered-list-item"}><strong>Blank</strong>: Blank Non-deliverable Address.</li>
                </ul></td>
        </tr>
        </tbody>
    </table>
 </>)

export default AdvancedAddressFieldDescriptions;
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * UserCell component
 *
 * @param {Reseller.User} props user
 * @returns {React.ReactNode} UserCell
 */
export default function UserCell(props) {
  const { firstName, lastName, email } = props;
  return (
    <Box>
      <Typography variant="body2">{`${firstName || '-'} ${lastName || ''}`.trim()}</Typography>
      <Typography variant="body1">{email}</Typography>
    </Box>
  );
}

UserCell.propTypes = {
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string
};

import { createEffect, createEvent, createStore, sample } from 'effector';
import { ghApi } from '../../services/DataService';
import { $$snackbar } from '../../components/SnackbarRoot/model';

/**
 * @typedef {{ childId: number, lobName: string, ytdJobQuantity: number, ytdRecordCount: number}} LineOfBusiness
 * @typedef {{clientId: number; clientName: string; linesOfBusiness: Array<LineOfBusiness>; ytdJobQuantity: number; ytdRecordCount: number}} Client
 */

//-------------------------------- Stores --------------------------------
const $clientList = createStore(/** @type {Array<Client> | null} */ (null));
const $client = createStore(/** @type {Client} */ { linesOfBusiness: [], ytdJobQuantity: 0, ytdRecordCount: 0 });

//-------------------------------- Events --------------------------------
/** @type {import('effector').EventCallable<boolean>} */
const pageMounted = createEvent();
/** @type {import('effector').EventCallable<Client>} */
const clientSelected = createEvent();
/** @type {import('effector').EventCallable<void>} */
const noClientSelected = createEvent();

//-------------------------------- Effects -------------------------------
/** @type {import('effector').Effect<number>} */
const getClientFx = createEffect(async clientId => {
  return ghApi.get('/MailTrakClientLOB/getClient', { params: { clientId } }).then(response => response.data);
});

/** @type {import('effector').Effect<void>} */
const getClientListFx = createEffect(async () => {
  return ghApi.get('/MailTrakClientLOB/getClientForDisplay').then(response => response.data);
});

//-------------------------------- Samples -------------------------------

// When page is mounted call getClientListFx.
sample({
  clock: pageMounted.filter({ fn: Boolean }).map(() => ({})),
  target: getClientListFx
});

// When page is mounted call or no client is selected clear the $client store.
sample({
  clock: [pageMounted.filter({ fn: Boolean }).map(() => ({})), noClientSelected],
  target: $client.reinit
});

// When getClientListFx has been completed successfully update $clientList store.
sample({
  clock: getClientListFx.doneData,
  target: $clientList
});

// When a client is selected call getClientFx with its clientId
sample({
  clock: clientSelected,
  fn: client => client.clientId,
  target: getClientFx
});

// When getClientFx has been completed successfully update $client store.
sample({
  clock: getClientFx.doneData,
  target: $client
});

// When getClientFx or getClientListFx has been completed un-successfully open the snackbar with the corresponding message.
sample({
  clock: [getClientFx.failData, getClientListFx.failData],
  fn: err => {
    return {
      message: err?.response?.data?.message ?? "There's been an error retrieving client data",
      severity: /** @type {const} */ ('error'),
      autoHideDuration: 6000
    };
  },
  target: $$snackbar.open
});

export const $$mailTrakClientLOBPage = {
  // Stores
  $clientList,
  // While getClientListFx in progress the $clientListLoading is set to true and is updated to false once effect is completed successfully or not.
  $clientListLoading: getClientListFx.pending,
  $client,
  // While getClientFx is in progress the $clientListLoading is set to true and is updated to false once effect is completed successfully or not.
  $clientLoading: getClientFx.pending,

  // Events
  pageMounted,
  clientSelected,
  noClientSelected,

  // Effects
  getClientListFx,
  getClientFx
};

import { DataGrid, Radio } from "@ghs/grayhair-component-library";
import { Box } from "@mui/material";
import * as React from "react";
import { useUnit } from "effector-react";
import { $$theme } from "../../services/ThemeService/model";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import PropTypes from "prop-types";
import { DataGridStyles } from "../../util/DataGridStyles";


/**
 * Table for displaying logos sets (with logos for light and dark theme)
 *
 * @param {object} props props for this component
 * @param {Array} props.logoSets logo sets to display in the table
 * @param {boolean} props.loading whether data is loading
 * @param {Array} props.selectionModel selection model for the table
 * @param {Function} props.handleSelectionChange handler to run when the selected logo changes
 * @returns {Element} table for displaying the logos
 */
function LogosTable( { logoSets, loading, selectionModel, handleSelectionChange }) {
  const palettes = useUnit($$theme.$themePalettes);


  const columns = [
    {
      field: "radiobutton",
      headerName: "",
      width: 50,
      sortable: false,
      renderCell: params => <Radio checked={selectionModel[0] === params.id} value={params.id} />
    },
    { field: "name", headerName: "Name", flex: 0.5 },
    {
      field: "light",
      headerName: "Light",
      sortable: false,
      flex: 1,
      renderCell: params => (
        <Box sx={{ bgcolor: palettes.light.palette.primary.dark, p: 1, height: '50px' }}>
          <img alt="Light" src={params.value} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </Box>
      )
    },
    {
      field: "dark",
      headerName: "Dark",
      sortable: false,
      flex: 1,
      renderCell: params => (
        <Box sx={{ bgcolor: palettes.dark.palette.primary.dark, p: 1, height: '50px' }}>
          <img alt="Dark" src={params.value} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </Box>
      )
    }
  ];
  return (
    <Container id="logo-container" disableGutters>
      <Box sx={{ display: "flex" }} justifyContent="center">
        <CircularProgress sx={{ ...(!loading && { display: "none" }) }} />
      </Box>
      <DataGrid
        columns={columns}
        rows={logoSets}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={handleSelectionChange}
        disableColumnMenu
        disableColumnResize
        disableMultipleRowSelection
        autoHeight
        // Wrap long cells
        getRowHeight={() => "auto"}
        // Restore padding due to using getRowHeight
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
          '& .MuiDataGrid-cell:focus': { outline: 'none' },
          '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
          ...DataGridStyles,
          ...(loading && { display: "none" })
        }}
        disableVirtualization
      />
    </Container>
  );
}

LogosTable.propTypes = {
  logoSets: PropTypes.arrayOf(PropTypes.shape({

  })),
  loading: PropTypes.bool,
  selectionModel: PropTypes.array,
  handleSelectionChange: PropTypes.func
}

export default LogosTable;
import { attach, createEvent, createStore, restore, sample } from 'effector';
import { persist } from 'effector-storage/local';
import { keyBy } from 'lodash';
import { spread } from 'patronum';
import { $$roles } from '../../services/RolesService/model';
import createDataGridModel from '../../util/createDataGridModel';
import { createDialog } from '../../util/createDialog';
import { VOID } from '../../util/JsonUtils';
import { $$confirmationDialog } from '../ConfirmationDialog/model';
import { $$snackbar } from '../SnackbarRoot/model.js';
import { createDeleteConfirmationDialogArg } from './utils';

/** @type {DialogFactory<Partial<{ roleId: Roles.Role['id'] }>>} */
const dialog = createDialog();

/** @type {import('effector').EventCallable<Roles.Role['id']>} */
const openDeleteConfirmation = createEvent();

/** @type {import('effector').EventCallable<boolean>} */
const deleteConfirmationSkipChecked = createEvent();

/** @type {import('effector').EventCallable<Users.User | null>} */
const userSelected = createEvent();

/** @type {import('effector').EventCallable<Users.User['userId']>} */
const deleteConfirmed = createEvent();

/** @type {import('effector').EventCallable<void>} */
const saveClicked = createEvent();

/** @type {import('effector').EventCallable<void>} */
const refetch = createEvent();

const $deleteConfirmationSkipped = createStore(/** @type {boolean} */ (false));

/** @type {import('effector').Store<{ id: Users.User['userId']; label: string }[]>} */
// @ts-ignore
const $usersToAdd = restore(userSelected, []).reset(sample({ clock: [dialog.close, $$roles.assignUserToRoleFx.done] }));

const getFreshRowsFx = attach({
  source: dialog.$state.map(state => state?.roleId || ''),
  mapParams: /** @type {(arg1: Partial<{ page: number; size: number }>, arg2: Roles.Role['id']) => ({ page: number; size: number; roleId: Roles.Role['id'] })} */ (
    ({ page, size }, roleId) => ({ page, size, roleId })
  ),
  effect: $$roles.getUsersForRole
});

const $$usersDataGrid = createDataGridModel({
  type: 'server',
  effect: getFreshRowsFx,
  rowIdField: 'userId',
  refetch
});

/** @type {import('effector').Store<Roles.Role | null>} */
const $role = sample({
  source: {
    roles: $$roles.$roles,
    roleId: dialog.$state.map(params => params?.roleId || null)
  },
  fn: ({ roles, roleId }) => roles.find(role => role.id === roleId) || null
});

persist({ store: $deleteConfirmationSkipped, key: 'gh:admin-roles-users:skip-role-delete-confirm' });

sample({
  clock: deleteConfirmationSkipChecked,
  target: $deleteConfirmationSkipped
});

sample({
  source: {
    users: $$usersDataGrid.$data,
    shouldSkip: $deleteConfirmationSkipped
  },
  clock: openDeleteConfirmation,
  fn: ({ shouldSkip, users }, userId) =>
    shouldSkip ? { deleteConfirmed: userId } : { confirm: createDeleteConfirmationDialogArg({ user: keyBy(users, 'userId')[userId], deleteConfirmationSkipChecked, deleteConfirmed }) },
  target: spread({ deleteConfirmed, confirm: $$confirmationDialog.open })
});

sample({
  source: dialog.$state.map(params => params?.roleId || ''),
  clock: deleteConfirmed,
  fn: (roleId, userId) => ({ roleId, userIds: [userId] }),
  target: $$roles.deleteUsersFromRoleFx
});

sample({
  source: {
    userIds: $usersToAdd.map(users => users.map(user => user.id)),
    roleId: dialog.$state.map(params => params?.roleId || '')
  },
  clock: saveClicked,
  fn: ({ userIds, roleId }) => (userIds.length ? { saveFx: /** @type  {{ roleId: Roles.Role['id']; userIds: Users.User['userId'][] }} */ ({ roleId, userIds }) } : { close: VOID }),
  target: spread({ saveFx: $$roles.assignUserToRoleFx, close: dialog.close })
});

sample({
  clock: [$$roles.assignUserToRoleFx.done, $$roles.deleteUsersFromRoleFx.done],
  target: refetch
});

sample({
  clock: [$$roles.assignUserToRoleFx.done.map(params => ({ params, action: 'assign' })), $$roles.deleteUsersFromRoleFx.done.map(params => ({ params, action: 'delete' }))],
  filter: dialog.$state.map(params => !!params?.roleId),
  fn: ({ action }) => ({ message: action === 'assign' ? 'User(s) assigned to role' : 'User deleted from role', severity: 'success' }),
  target: $$snackbar.open
});

sample({
  clock: [$$roles.assignUserToRoleFx.fail.map(response => ({ response, action: 'assign' })), $$roles.deleteUsersFromRoleFx.fail.map(response => ({ response, action: 'delete' }))],
  fn: ({ response, action }) => {
    const { error } = response;
    let message = `An error occurred while ${action === 'assign' ? 'assigning' : 'deleting'} the user ${action === 'assign' ? 'to' : 'from'} the role.`;
    if (error.response?.data?.message) {
      message = error.response.data.message;
    }
    return { message: message, severity: 'error' };
  },
  target: $$snackbar.open
});

export const $$manageUsersAssignedToRoleModal = {
  ...dialog,

  $$rolesDataGrid: $$usersDataGrid,

  $role,
  $usersToAdd,

  openDeleteConfirmation,
  saveClicked,
  userSelected
};

import * as React from "react";
import {useState} from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Stack from "@mui/material/Stack";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

export const fieldStepDisplaySpecifications = {
    upload: {
        name: 'Upload',
        order: 0,
        steps: [
            {dbName: 'uploadStart', subStepName: 'Upload Start'},
            {dbName: 'uploadEnd', subStepName: 'Upload End'},
            {dbName: 'fileCopyStart', subStepName: 'File Copy Start'},
            {dbName: 'fileCopyEnd', subStepName: 'File Copy End'},
        ]
    },
    cassNcoa: {
        name: 'CASS & NCOA',
        order: 1,
        steps: [
            {dbName: 'cassNcoaStart', subStepName: 'CASS NCOA Start'},
            {dbName: 'cassNcoaEnd', subStepName: 'CASS NCOA End'},
        ]
    },
    fileImport: {
        name: 'File Import',
        order: 2,
        steps: [
            {dbName: 'fileImportStart', subStepName: 'File Import Start'},
            {dbName: 'fileImportEnd', subStepName: 'File Import End'},
        ]
    },
    acs: {
        name: 'ACS',
        order: 3,
        steps: [
            {dbName: 'acsStart', subStepName: 'ACS Start'},
            {dbName: 'acsFinish', subStepName: 'ACS End'},
        ]
    },
    imbGeneration: {
        name: 'IMB Generation',
        order: 4,
        steps: [
            {dbName: 'imbStart', subStepName: 'IMB Start'},
            {dbName: 'imbEnd', subStepName: 'IMB End'},
        ]
    },
    fileExport: {
        name: 'File Export',
        order: 5,
        steps: [
            {dbName: 'fileExportStart', subStepName: 'File Export Start'},
            {dbName: 'fileExportEnd', subStepName: 'File Export End'},
        ]
    },
    fileDownload: {
        name: 'File Download',
        order: 6,
        steps: [
            {dbName: 'downloadStart', subStepName: 'File Download Start'},
            {dbName: 'downloadEnd', subStepName: 'File Download End'},
        ]
    },
    merge: {
        name: 'Merge',
        order: 7,
        steps: [
            {dbName: 'mergeEnd', subStepName: 'Merge End'}
        ]
    }
}

const StepTimelineDisplay = ({stepSubsteps}) => {

    return(
        <Timeline align="left" sx={{ marginTop: 2, padding: 0}}>
            {stepSubsteps.map((subStep, index) => (
                <TimelineItem data-testid={`timeline-item`} key={index} sx={{paddingLeft: 3.5}}>
                    <TimelineOppositeContent color="text.secondary" sx={{paddingX: 1, paddingY: 0}}>
                        <Typography sx={{display: 'inline-flex'}} variant="body2" color="textSecondary">
                            {subStep.subStepName}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color={subStep.value === '' ? 'grey' : 'success'} sx={{margin: 0}} />
                        {index < stepSubsteps.length - 1 && <TimelineConnector sx={{backgroundColor: (theme) => subStep.value === '' ? theme.palette.grey[400] : theme.palette.success.main}} />}
                    </TimelineSeparator >
                    <TimelineContent sx={{paddingX: 1, paddingY: 0, display: 'flex'}}>
                        <Typography sx={{display: 'flex', whiteSpace: 'pre-wrap'}} variant="body2" color="textSecondary">
                            {(new Date(subStep.value)).toString() === "Invalid Date" ? "" : `${(new Date(subStep.value)).toLocaleString('en-US', { timeZone: "America/New_York" }).replace(", ", "\n")}`}
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
}
StepTimelineDisplay.propTypes = {
    stepSubsteps: PropTypes.arrayOf(
        PropTypes.shape({
            dbName: PropTypes.string,
            subStepName: PropTypes.string,
            value: PropTypes.number,
        })
    )
};

export const AdditionalDatesDisplay = ({stepDetails}) => {

    const lastNonEmptyIndex = stepDetails.findIndex(step => step.hasNonEmptyValue === false)

    const [activeStep] = useState(lastNonEmptyIndex <= (stepDetails.length - 1) && lastNonEmptyIndex >= 0 ? lastNonEmptyIndex - 1: stepDetails.length - 1);
    const [selectedStep, setSelectedStep] = useState(null);

    const handleStepClick = (step) => {
        if (selectedStep === step) {
            setSelectedStep(null);
        }
        else {
            setSelectedStep(step);
        }
    };

    return (
        <Box sx={{margin: 1, overflowX: 'auto'}}>
            <Stepper connector={<KeyboardDoubleArrowRightIcon/>} activeStep={activeStep} sx={{alignItems: 'start' , paddingY: 0.5, justifyContent: 'space-evenly', minWidth: '1300px', flexShrink: 0}} >
                {stepDetails.map((step, index) => (
                    <Step data-testid="stepper-step" sx={{display: 'contents'}} key={step} onClick={() => handleStepClick(index)} completed={index < activeStep || activeStep === (stepDetails.length - 1)}>
                        <Stack>
                            <StepLabel>
                                <Typography sx={{textAlign: 'center', overflowWrap: "anywhere", fontWeight: 'bold', fontStyle: 'italic'}}>
                                    {step.stepName}
                                </Typography>
                            </StepLabel>
                            {selectedStep === index && (
                                <StepTimelineDisplay stepSubsteps={step.subSteps}/>
                            )}
                        </Stack>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};
AdditionalDatesDisplay.propTypes = {
    stepDetails: PropTypes.arrayOf(
        PropTypes.shape({
            stepName: PropTypes.string,
            hasNonEmptyValue: PropTypes.bool,
            subSteps: PropTypes.array
        })
    )
};
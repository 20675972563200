import { IconButton as MuiIconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { DeleteForever, Download, Edit } from '@mui/icons-material';

export const iconButtonConfig = {
    EDIT: {
        type: 'edit',
        icon: <Edit fontSize="small" color="primary" />,
        tooltip: 'Edit'
    },
    DELETE: {
        type: 'delete',
        icon: <DeleteForever fontSize="small" color="warning" />,
        tooltip: 'Delete'
    },
    DOWNLOAD: {
        type: 'download',
        icon: <Download color="primary" />,
        tooltip: 'Download'
    },
    CUSTOM_CHILDREN: {
        type: 'custom_children',
        icon: null,
        tooltip: ''
    }
};

/**
 * IconButton
 *
 * @typedef {{
 *  type: 'edit' | 'delete' | 'download' | custom_children;
 *  key: string;
 *  tooltip: string;
 *  onClick?: (value: boolean) => void;
 *  children?: node;
 * }} IconButtonProps
 * @param {IconButtonProps} props - props
 * @returns {React.ReactNode} IconButton component
 */
export default function IconButton(props) {
    const { type, key, tooltip, onClick = () => {}, children } = props;
    const config = Object.values(iconButtonConfig).find(cfg => cfg.type === type) || iconButtonConfig.CUSTOM_CHILDREN;
    return (
        <Tooltip {...(key && { key: `${key}-tooltip` })} title={tooltip ? tooltip : config.tooltip} arrow>
            <MuiIconButton {...(key && { key: `${key}-button` })} onClick={() => onClick()}>
                { (!type || type === iconButtonConfig.CUSTOM_CHILDREN.type) ? children : config.icon }
            </MuiIconButton>
        </Tooltip>
    );
}

IconButton.defaultProps = {
    type: iconButtonConfig.CUSTOM_CHILDREN.type
};

IconButton.propTypes = {
    type: PropTypes.oneOf(Object.keys(iconButtonConfig).map(key => iconButtonConfig[key].type)),
    key: PropTypes.string,
    tooltip: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node
};

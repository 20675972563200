// @ts-ignore
import { textFieldVariants } from '@ghs/grayhair-component-library';
import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, FormControl, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useResellerFormDialog } from './model';
import { emailRegex } from '../../util/JsonUtils';

/**
 * ResellerUserDialog
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function ResellerUserDialog() {
  const { isOpen, close, state, formValues, formErrors, isSubmitLoading, setFormValue, formSubmitted } = useResellerFormDialog();

  const isValidEmail = emailRegex.test(formValues.email);

  return (
    <Dialog open={isOpen} onClose={() => close()} fullWidth={true} PaperProps={{ sx: { borderRadius: 2, padding: 4, gap: 1 } }} disableEnforceFocus>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', flex: 1, padding: 0 }}>
        <Typography variant="h6">{state?.title || 'Add User'}</Typography>
        <IconButton onClick={() => close()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <FormControl
        component="form"
        onSubmit={e => {
          e.preventDefault();
          formSubmitted();
        }}
      >
        <Stack sx={{ gap: 2 }}>
          <Stack direction="row" sx={{ gap: 2 }}>
            <TextField
              label={`${state?.editMode ? "Edit " : ""}First Name`}
              placeholder="First Name"
              InputLabelProps={{ shrink: true }}
              value={formValues.firstName}
              onChange={e => {
                setFormValue({ name: 'firstName', value: e?.target.value || '' });
              }}
              variant={textFieldVariants.OUTLINED}
              fullWidth
            />
            <TextField
              label={`${state?.editMode ? "Edit " : ""}Last Name`}
              placeholder="Last Name"
              InputLabelProps={{ shrink: true }}
              value={formValues.lastName}
              onChange={e => {
                setFormValue({ name: 'lastName', value: e?.target.value || '' });
              }}
              variant={textFieldVariants.OUTLINED}
              fullWidth
            />
          </Stack>
          <Tooltip
            open={Boolean(formErrors.email)}
            title={formErrors.email}
            arrow
            componentsProps={{ popper: { sx: { [`.MuiTooltip-tooltip`]: { background: theme => theme.palette.error.main } } }, arrow: { sx: { color: theme => theme.palette.error.main } } }}
            placement="bottom-start"
          >
            <Tooltip title={state?.editMode ? 'Email address cannot be edited. If a change is needed, delete the user and create a new one' : ''} arrow>
              <TextField
                label="Email"
                placeholder="Email"
                InputLabelProps={{ shrink: true }}
                value={formValues.email}
                onChange={e => {
                  setFormValue({ name: 'email', value: e?.target.value || '' });
                }}
                variant={textFieldVariants.OUTLINED}
                fullWidth
                disabled={state?.editMode}
              />
            </Tooltip>
          </Tooltip>
          {formValues.email && !isValidEmail && (
            <Typography variant="caption" color="error.main" sx={{ mt: -2, ml: 1 }}>
              Please enter a valid email address
            </Typography>
          )}
          <DialogActions sx={{ padding: 0 }}>
            <Button variant="outlined" color="primary" sx={{ minWidth: 150 }} onClick={() => close()}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" disabled={Object.values(formErrors).length > 0 || isSubmitLoading || !isValidEmail}>
              {isSubmitLoading ? <CircularProgress size={24} color="inherit" /> : 'Save Changes'}
            </Button>
          </DialogActions>
        </Stack>
      </FormControl>
    </Dialog>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

import { useTheme, AvatarGroup as MuiAvatarGroup } from '@mui/material';

export const avatarGroupSpacings = {
  MEDIUM: 'medium',
  SMALL: 'small'
};

const Avatar = ({ children, spacing, max, total, sx, ...overrides }) => {
  const theme = useTheme();

  return (
    <MuiAvatarGroup
      spacing={spacing}
      max={max}
      total={total}
      sx={{
        '& 	.MuiAvatarGroup-avatar': {
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText
        },
        ...sx
      }}
      {...overrides}
    >
      {children}
    </MuiAvatarGroup>
  );
};

Avatar.propTypes = {
  children: PropTypes.node,
  spacing: PropTypes.oneOf(Object.values(avatarGroupSpacings)),
  max: PropTypes.number,
  total: PropTypes.number,
  sx: PropTypes.shape()
};

export default Avatar;

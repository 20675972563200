import * as React from 'react';
import { useNavigate, useSearchParams, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { darken, lighten } from '@mui/material/styles';

import { Alert, Button, Card, DataGrid, Link } from '@ghs/grayhair-component-library';
import { Status, Wrapper } from '@googlemaps/react-wrapper';

import jsPDF from 'jspdf';
import { toCanvas } from 'html-to-image';
import { DeliveryMap } from '../components/DeliveryMap';
import { fetchAcsEvents, fetchMailDetails, fetchScanData } from '../services/DataService';
import useAuth0WithErrorHandling from '../hooks/useAuth0WithErrorHandling';
import { formatAddressCityStateZip, formatCityStateZip, formatDestination, trimObject } from '../util/AddressUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { isTimeoutError, generateTimeoutErrorAlert, generateErrorAlertWithContact } from '../util/ErrorUtils';
import { hasAddressVerificationPermission } from '../services/UserService/UserService';
import { UserContext } from '../UserContext';
import { useUnit } from 'effector-react';
import { $$contactService } from '../services/ContactService/model';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDsjP_bJpNny1UPNjus_3K3oSs5qALVtUw';

const renderMap = status => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};
const SplResults = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isExportingPdf, setIsExportingPdf] = React.useState(false);
  const [mailDetails, setMailDetails] = React.useState(null);
  const [acsEvents, setAcsEvents] = React.useState([]);
  const [scans, setScans] = React.useState([]);
  const [alertData, setAlertData] = React.useState(null);
  const { getAccessTokenSilently } = useAuth0WithErrorHandling();
  const { user } = useAuth0();
  const contactInfo = useUnit($$contactService.$contactInfo);

  const dataNotFoundErrorAlert = (imb, packageId) => {
    return {
      severity: 'error',
      title: 'Oops!',
      message: (
        <React.Fragment>
          We didn&apos;t find any results for the provided search parameters. Check your IMb and PkgId or return to the{' '}
          <Link to="/lookup" component={RouterLink}>
            Single Piece Lookup
          </Link>{' '}
          page to try another search.
          <br />
          <br />
          IMb: {imb}
          <br />
          PkgId: {packageId}
        </React.Fragment>
      )
    };
  };
  const handleExportPdf = async () => {
    setIsExportingPdf(true);
    try {
      await exportPDF(mailDetails.imb, mailDetails, acsEvents, scans);
    } catch (error) {
      console.error(error);
      setAlertData(
        generateErrorAlertWithContact({
          contactInfo: contactInfo,
          userEmail: user?.email,
          errorMessage: 'There was an error exporting this page to PDF'
        })
      );
    }
    setIsExportingPdf(false);
  };

  const pkgId = searchParams.get('pkgId');
  const trackCode = searchParams.get('trackCode');

  React.useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const accessToken = await getAccessTokenSilently();

        const fetchedMailDetails = await fetchMailDetails(trackCode, pkgId, accessToken);
        setMailDetails(trimObject(fetchedMailDetails));
        const [fetchedAcsEvents, fetchedScans] = await Promise.all([
          fetchAcsEvents(trackCode, pkgId, fetchedMailDetails.mailDate, accessToken),
          fetchScanData(trackCode, pkgId, fetchedMailDetails.mailDate, accessToken)
        ]);

        setAcsEvents(fetchedAcsEvents.map(trimObject));
        setScans(fetchedScans.map(trimObject));
      } catch (err) {
        if (err?.response?.status === 404) {
          setAlertData(dataNotFoundErrorAlert(trackCode, pkgId));
        } else if (isTimeoutError(err)) {
          setAlertData(generateTimeoutErrorAlert(user?.email, window.location.href));
        } else {
          console.error('Error fetching data: ', err);
          setAlertData(
            generateErrorAlertWithContact({
              contactInfo: contactInfo,
              userEmail: user?.email,
              requestId: err?.response?.headers?.get('Request-Id'),
              errorMessage: 'There was an error loading your search results. Please retry your search'
            })
          );
        }
      }

      setIsLoading(false);
    };

    loadData();
  }, []);
  return (
    <Container id="spl-results-container">
      <Collapse in={!!alertData && (!!alertData.title || !!alertData.message)}>
        <Alert
          severity={alertData?.severity}
          action={
            <IconButton onClick={() => setAlertData({ ...alertData, ...{ title: '', message: '' } })}>
              <CloseIcon />
            </IconButton>
          }
        >
          <Typography variant="h6">{alertData?.title}</Typography>
          <Typography>{alertData?.message}</Typography>
        </Alert>
      </Collapse>
      <Typography color="text.primary">Single Piece Lookup Results</Typography>
      <Button variant="outlined" onClick={() => navigate(-1)} sx={{ m: 1 }}>
        Back
      </Button>
      <Button color="primary" variant="contained" onClick={handleExportPdf} sx={{ m: 1 }} disabled={isLoading || isExportingPdf}>
        Export to PDF
      </Button>
      <Box>
        <Grid container>
          <Grid xs={12} md={5}>
            <Address data={mailDetails} />
          </Grid>
          <Grid xs={12} md={7}>
            <MailingInformation data={mailDetails}></MailingInformation>
          </Grid>
        </Grid>
        <AcsEvents data={acsEvents} sx={{ mb: 2 }} />
        <MailingDetails data={scans} isLoading={isLoading} sx={{ mb: 2 }} />
        <Wrapper apiKey={GOOGLE_MAPS_API_KEY} render={renderMap}>
          <DeliveryMap scans={scans} acsEvents={acsEvents} mailDetails={mailDetails} />
        </Wrapper>
      </Box>
    </Container>
  );
};

SplResults.propTypes = {};

/**
 * Component for displaying an address.
 *
 * @param {object} props props for this component
 * @param {object} props.data the address data
 * @returns {Element} Address component
 * @class
 */
function Address({ data }) {
  const userDetails = React.useContext(UserContext);
  const navigate = useNavigate();

  const contents = data ? (
    <Box id="address-container" sx={{ pl: 0 }}>
      {data.name ? <Typography id="address-name">{data.name}</Typography> : null}
      {data.businessName ? <Typography id="business-name">{data.businessName}</Typography> : null}
      <Typography>{data.address}</Typography>
      <Typography>{formatCityStateZip(data.city, data.state, data.zip)}</Typography>
      <Tooltip title={'See the Address Verification Results of your input address for detailed CASS information.'}>
        <Button
          variant="outlined"
          disabled={false}
          onClick={() => navigate(`/addressVerification?street=${data.address}&city=${data.city}&state=${data.state}&zip=${data.zip}`)}
          sx={{ float: 'right', mt: 1, mb: 1, ...(!hasAddressVerificationPermission(userDetails.permissions) && { display: 'none' }) }}
        >
          Verification Results
        </Button>
      </Tooltip>
    </Box>
  ) : (
    <Stack id="address-skeleton" spacing={1}>
      <Skeleton variant="rectangular"></Skeleton>
      <Skeleton variant="rectangular"></Skeleton>
      <Skeleton variant="rectangular"></Skeleton>
    </Stack>
  );

  return (
    <Card header="Address" sx={{ m: 1 }}>
      {contents}
    </Card>
  );
}

Address.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    businessName: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string
  })
};

/**
 * Component for displaying mailing information.
 *
 * @param {object} props props for this component
 * @param {object} props.data the mailing information data
 * @returns {Element} MailingInformation component
 * @class
 */
function MailingInformation({ data }) {
  return (
    <Card header="Mailing Information" sx={{ m: 1 }}>
      <Grid container spacing={0}>
        <MailingInformationAttribute label="Unique Record ID: " value={data?.uniqueRecordId} isLoaded={!!data} />
        <MailingInformationAttribute label="IMb: " value={data?.imb} isLoaded={!!data} />
        <MailingInformationAttribute label="Mail Date: " value={data?.mailDate} isLoaded={!!data} />
        <MailingInformationAttribute label="Induction Date: " value={data?.inductionDate} isLoaded={!!data} />
        <MailingInformationAttribute label="Delivery Date: " value={data?.deliveryDate} isLoaded={!!data} hideIfEmpty={false} />
        <MailingInformationAttribute label="Line of Business: " value={data?.lineOfBusiness} isLoaded={!!data} />
        <MailingInformationAttribute label="Campaign: " value={data?.campaign} isLoaded={!!data} />
        <MailingInformationAttribute label="Version Nbr: " value={data?.versionNumber} isLoaded={!!data} />
        <MailingInformationAttribute label="Branch: " value={data?.branch} isLoaded={!!data} />
        <MailingInformationAttribute label="Mail Service Provider: " value={data?.mailServiceProvider} isLoaded={!!data} /> {/*Only MT*/}
        <MailingInformationAttribute label="User field 1: " value={data?.userField1} isLoaded={!!data} /> {/*Only ST*/}
        <MailingInformationAttribute label="User field 2: " value={data?.userField2} isLoaded={!!data} /> {/*Only ST*/}
        <MailingInformationAttribute label="User field 3: " value={data?.userField3} isLoaded={!!data} /> {/*Only ST*/}
      </Grid>
    </Card>
  );
}

MailingInformation.propTypes = {
  data: PropTypes.shape({
    uniqueRecordId: PropTypes.string,
    imb: PropTypes.string,
    mailDate: PropTypes.string,
    inductionDate: PropTypes.string,
    deliveryDate: PropTypes.string,
    lineOfBusiness: PropTypes.string,
    campaign: PropTypes.string,
    versionNumber: PropTypes.string,
    branch: PropTypes.string,
    mailServiceProvider: PropTypes.string,
    userField1: PropTypes.string,
    userField2: PropTypes.string,
    userField3: PropTypes.string
  })
};

/**
 * Component for displaying mailing information.
 *
 * @param {object} props props for this component
 * @param {string} props.label the mailing information data label
 * @param {string} props.value the mailing information data value
 * @param {boolean} props.isLoaded whether the data has completed loading
 * @param {boolean} props.hideIfEmpty whether to hide if the value is empty
 * @returns {Element} MailingInformationAttribute component
 * @class
 */
function MailingInformationAttribute({ label, value, isLoaded, hideIfEmpty = true }) {
  return isLoaded ? (
    <React.Fragment>
      <Grid xs={5} sx={{ ...(!value && hideIfEmpty && { display: 'none' }) }}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid xs={7} sx={{ ...(!value && hideIfEmpty && { display: 'none' }) }}>
        <Typography>{value}</Typography>
      </Grid>
    </React.Fragment>
  ) : (
    <Grid xs={12} sx={{ mb: 1 }}>
      <Skeleton variant="rectangular" />
    </Grid>
  );
}

MailingInformationAttribute.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  isLoaded: PropTypes.bool,
  hideIfEmpty: PropTypes.bool
};

/**
 * Component for displaying ACS events.
 *
 * @param {object} props props for this component
 * @param {Array} props.data the ACS events data
 * @returns {Element} AcsEvents component
 * @class
 */
function AcsEvents({ data, ...overrides }) {
  const columns = [
    { field: 'dateReceived', headerName: 'ACS Event Received', flex: 0.5 },
    { field: 'reason', headerName: 'Reason', flex: 0.5 },
    {
      valueGetter: (_value, row) => formatAddressCityStateZip(row.newAddress, row.newCity, row.newState, `${row.new5Zip}${row.newPlus4}`),
      headerName: 'New Address',
      flex: 1
    }
  ];

  return data.length ? (
    <Container {...overrides} id="acs-events-container">
      <Typography variant="h6" color="text.primary">
        ACS Events
      </Typography>
      <DataGrid
        columns={columns}
        rows={data}
        rowSelection={false}
        autoHeight
        // Wrap long cells
        getRowHeight={() => 'auto'}
        // Restore padding due to using getRowHeight & disable cell selection outline
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          '& .MuiDataGrid-cell:focus': { outline: 'none' },
          '& .MuiDataGrid-cell:focus-within': { outline: 'none' }
        }}
      />
    </Container>
  ) : null;
}

AcsEvents.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      newAddress: PropTypes.string,
      newCity: PropTypes.string,
      newState: PropTypes.string,
      new5Zip: PropTypes.string,
      newPlus4: PropTypes.string
    })
  ).isRequired
};

/**
 * Component for displaying mailing details.
 *
 * @param {object} props props for this component
 * @param {Array} props.data the mailing details data
 * @param {boolean} props.isLoading whether data is still loading
 * @returns {Element} MailingDetails component
 * @class
 */
function MailingDetails({ data, isLoading, ...overrides }) {
  const getBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7));

  const getHoverBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6));

  const columns = [
    { valueGetter: value => formatDestination(value), field: 'destination', headerName: 'Mail Piece Destination', sortable: false, flex: 1 },
    { field: 'scanDateTime', headerName: 'Scan Date/Time', sortable: false, flex: 0.75 },
    { field: 'scanSiteZip', headerName: 'Scan Site ZIP', sortable: false, flex: 0.5 },
    { field: 'scanCityState', headerName: 'Scan City/State', sortable: false, flex: 0.75 },
    { field: 'details', headerName: 'Activity', sortable: false, flex: 0.75 },
    { field: 'travelDays', headerName: 'Travel Days', flex: 0.5 }
  ];

  return !isLoading ? (
    <Container {...overrides} id="mailing-details-container">
      <Typography variant="h6" color="text.primary">
        Mailing Details
      </Typography>
      <DataGrid
        columns={columns}
        rows={data}
        rowSelection={false}
        getRowClassName={params => (params.row.remit ? `mailing-details-remit` : null)}
        autoHeight
        // Wrap long cells
        getRowHeight={() => 'auto'}
        sx={{
          // Restore padding due to using getRowHeight
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          // Disable cell selection outline
          '& .MuiDataGrid-cell:focus': { outline: 'none' },
          '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
          // Styling for remit scans
          '& .mailing-details-remit': {
            backgroundColor: theme => getBackgroundColor(theme.palette.info.main, theme.palette.mode),
            '&:hover': { backgroundColor: theme => getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode) }
          }
        }}
        localeText={{
          noRowsLabel: 'No scans received yet'
        }}
      />
      {data && data.some(scan => scan.remit) ? <Typography color="text.primary">* Blue rows indicate remittance mailpiece.</Typography> : null}
    </Container>
  ) : (
    <Skeleton variant="rectangular" height={600} />
  );
}

MailingDetails.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      destination: PropTypes.string,
      scanDateTime: PropTypes.string,
      scanSiteZip: PropTypes.string,
      scanCityState: PropTypes.string,
      details: PropTypes.string,
      travelDays: PropTypes.number,
      containerType: PropTypes.string,
      remit: PropTypes.bool
    })
  ).isRequired,
  isLoading: PropTypes.bool
};

/**
 * Generate mail info to add to PDF.
 *
 * @param {object} pdf jsPdf object
 * @param {object} mailDetails Piece mailing details to add to the PDF
 * @param {number} columnWidth Column width
 * @returns {{mailInfoLabels: *[], mailInfoValues: *[]}} Mail info labels and values in two separate objects
 */
function generateMailInfo(pdf, mailDetails, columnWidth) {
  const userField1 = mailDetails.userField1 ? pdf.splitTextToSize(`${mailDetails.userField1}`, columnWidth) : [];
  const userField2 = mailDetails.userField2 ? pdf.splitTextToSize(`${mailDetails.userField2}`, columnWidth) : [];
  const userField3 = mailDetails.userField3 ? pdf.splitTextToSize(`${mailDetails.userField3}`, columnWidth) : [];

  // Note about "Array(Math.max(userField1.length-1, 0)).fill('')": This is to add empty rows to compensate the number
  // of lines of the user field value. If the user field value needs 3 lines to fit, we display the user field label in
  // first line plus leave 2 empty lines more

  const mailInfoLabels = [
    ...(mailDetails.uniqueRecordId ? [`Unique Record ID:`] : []),
    ...(mailDetails.imb ? [`IMb:`] : []),
    ...(mailDetails.mailDate ? [`Mail Date:`] : []),
    ...(mailDetails.inductionDate ? [`Induction Date:`] : []),
    `Delivery Date:`,
    ...(mailDetails.lineOfBusiness ? [`Line of Business: `] : []),
    ...(mailDetails.campaign ? [`Campaign: `] : []),
    ...(mailDetails.versionNumber ? [`Version Nbr: `] : []),
    ...(mailDetails.branch ? [`Branch:`] : []),
    ...(mailDetails.mailServiceProvider ? [`Mail Service Provider:`] : []),
    ...(mailDetails.userField1 ? [`User field 1:`] : []), // Only ST
    ...Array(Math.max(userField1.length - 1, 0)).fill(''),
    ...(mailDetails.userField2 ? [`User field 2:`] : []), // Only ST
    ...Array(Math.max(userField2.length - 1, 0)).fill(''),
    ...(mailDetails.userField3 ? [`User field 3:`] : []), // Only ST
    ...Array(Math.max(userField3.length - 1, 0)).fill('')
  ];

  const mailInfoValues = [
    ...(mailDetails.uniqueRecordId ? [`${mailDetails.uniqueRecordId}`] : []),
    ...(mailDetails.imb ? [`${mailDetails.imb}`] : []),
    ...(mailDetails.mailDate ? [`${mailDetails.mailDate}`] : []),
    ...(mailDetails.inductionDate ? [`${mailDetails.inductionDate}`] : []),
    `${mailDetails.deliveryDate || ''}`,
    ...(mailDetails.lineOfBusiness ? [`${mailDetails.lineOfBusiness}`] : []),
    ...(mailDetails.campaign ? [`${mailDetails.campaign}`] : []),
    ...(mailDetails.versionNumber ? [`${mailDetails.versionNumber}`] : []),
    ...(mailDetails.branch ? [`${mailDetails.branch}`] : []),
    ...(mailDetails.mailServiceProvider ? [`${mailDetails.mailServiceProvider}`] : []), // Only MT
    ...userField1, // Only ST
    ...userField2, // Only ST
    ...userField3 // Only ST
  ];

  return { mailInfoLabels, mailInfoValues };
}

const exportPDF = async (imb, mailDetails, acsEvents, scans) => {
  const pdf = new jsPDF('p', 'mm', 'a4');
  const leftMargin = 10;
  const topMargin = 5;
  let y = topMargin;
  const newLine = 4;
  const font = 'Helvetica';
  pdf.setFontSize(10);

  const tableConfig = {
    autoSize: false,
    printHeaders: true,
    fontSize: 8,
    padding: 1
  };

  const logo = new Image();
  logo.src = `${process.env.PUBLIC_URL}/GrayHair-logo-black.t.png`;
  pdf.addImage(logo, 'PNG', leftMargin, topMargin, 72, 12, '', 'FAST');
  pdf.text('_'.repeat(90), leftMargin, (y += newLine * 3));

  // Add Address section
  y += newLine * 2;
  const mailDetailsYStart = y;
  pdf.setFont(font, 'bold');
  pdf.text('Address', leftMargin, y);
  pdf.text('________', leftMargin, y);
  pdf.setFont(font, 'normal');
  const addressMaxLength = 75;
  const addressFields = [
    ...(mailDetails.name ? pdf.splitTextToSize(`${mailDetails.name}`, addressMaxLength) : []),
    ...(mailDetails.businessName ? pdf.splitTextToSize(`${mailDetails.businessName}`, addressMaxLength) : []),
    ...(mailDetails.address ? pdf.splitTextToSize(`${mailDetails.address}`, addressMaxLength) : []),
    ...pdf.splitTextToSize(formatCityStateZip(mailDetails.city, mailDetails.state, mailDetails.zip), addressMaxLength)
  ];
  y += newLine;
  pdf.text(addressFields, leftMargin, y);

  // Add Mailing Information section
  const column2 = 90;
  const column3 = 130;
  const column3Width = 65;
  const { mailInfoLabels, mailInfoValues } = generateMailInfo(pdf, mailDetails, column3Width);
  y = mailDetailsYStart;
  pdf.setFont(font, 'bold');
  pdf.text('Mailing Information', column2, y);
  pdf.text('_________________', column2, y);
  pdf.text(mailInfoLabels, column2, (y += newLine));
  pdf.setFont(font, 'normal');
  pdf.text(mailInfoValues, column3, y);
  y += newLine * mailInfoLabels.length;

  // Add ACS Events table
  if (acsEvents?.length) {
    pdf.setFont(font, 'bold');
    pdf.text('ACS Events', leftMargin, (y += newLine));
    pdf.text('__________', leftMargin, y);
    const acsTableHeaders = ['ACS Event Received', 'Reason', 'New Address'];
    const acsTableData = acsEvents.map(acsEvent => {
      const formattedAddress = formatAddressCityStateZip(acsEvent.newAddress, acsEvent.newCity, acsEvent.newState, `${acsEvent.new5Zip || ''}${acsEvent.newPlus4 || ''}`);
      // Ensure minimum length for the column by adding empty spaces (MER-123)
      const spacesToAdd = Math.max(0, 40 - formattedAddress.length);
      return {
        // Replace nulls with a single space so that they render properly in PDF table
        'ACS Event Received': acsEvent?.dateReceived || ' ',
        Reason: acsEvent?.reason || ' ',
        'New Address': formattedAddress + ' '.repeat(spacesToAdd)
      };
    });
    pdf.table(leftMargin, (y += newLine), acsTableData, acsTableHeaders, tableConfig);
    y += newLine * 4 * acsEvents.length;
  }

  // Add Mailing Details section
  if (scans?.length) {
    pdf.setFontSize(10);
    pdf.setFont(font, 'bold');
    pdf.text(`Mailing Details ${mailDetails.inductionDate ? '(*Travel days are calculated off of Induction Date)' : ''}`, leftMargin, (y += newLine));
    pdf.text('_____________', leftMargin, y);
    const scansTableHeaders = ['Mail\u00A0Piece\u00A0Destination', 'Scan\u00A0Date/Time', 'Scan\u00A0Site\u00A0ZIP', 'Scan\u00A0City\u00A0State', 'Activity', 'Travel\u00A0Days'];
    const scansTableData = scans.map(scan => {
      return {
        // Replace nulls with a single space so that they render properly in PDF table
        'Mail\u00A0Piece\u00A0Destination': `${formatDestination(scan.destination)} `,
        'Scan\u00A0Date/Time': scan.scanDateTime || ' ',
        'Scan\u00A0Site\u00A0ZIP': scan.scanSiteZip || ' ',
        'Scan\u00A0City\u00A0State': scan.scanCityState?.trim() || ' ',
        Activity: scan.details?.trim() || ' ',
        'Travel\u00A0Days': scan.travelDays?.toString() || ' '
      };
    });

    pdf.table(leftMargin, y + newLine, scansTableData, scansTableHeaders, tableConfig);
  }

  // Add map image
  const map = document.getElementById('map');

  if (map) {
    try {
      // PDF via html-to-image (see options here: https://github.com/bubkoo/html-to-image#options)
      const canvas = await toCanvas(map, { skipFonts: true, includeQueryParams: true });
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL('img/png');
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', leftMargin, topMargin, imgWidth, imgHeight, '', 'FAST');
    } catch (err) {
      console.error('Error adding map to pdf.', err);
    }
  }

  pdf.save(`${imb}.pdf`);
};

export default SplResults;

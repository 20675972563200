// @ts-ignore
import { Autocomplete, textFieldVariants } from '@ghs/grayhair-component-library';
import { Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useUnit } from 'effector-react';
import IconButton from '../../../components/IconButton/IconButton';
import { DataGridStylesWithoutSelectionOverride } from '../../../util/DataGridStyles';
import { $$adminDataAccessPage } from './model';

/**
 * CustomersTable
 *
 * @returns {React.ReactNode} CustomersTable
 */
export default function CustomersTable() {
  const dataGridProps = $$adminDataAccessPage.$$customers.useDataGrid();
  const isResellerUser = useUnit($$adminDataAccessPage.$isResellerUser);
  const selectedCustomerId = useUnit($$adminDataAccessPage.$selectedFilterCustomerId);

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Reseller.Customer>[]} */
  const customerTableColumns = [
    {
      field: 'name',
      headerName: 'Customer',
      minWidth: 160,
      flex: 6,
      renderHeader: () =>
        isResellerUser && (
          <Typography variant="body2" fontWeight="500" sx={{ gap: 2, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <span>Customer</span>
            <IconButton type="download" tooltip="Download all users" onClick={() => $$adminDataAccessPage.downloadResellerUsersFx()} />
          </Typography>
        )
    }
  ];

  return (
    <>
      <Autocomplete
        id="admin-data-access-customers-autocomplete"
        label="Search Customer"
        placeholder="Select"
        options={dataGridProps.rows.map(row => ({ id: row.id, label: row.name }))}
        onChange={(_, value) => $$adminDataAccessPage.filterCustomerId(value?.id ?? null)}
        variant={textFieldVariants.OUTLINED}
        fullWidth
        sx={{ mb: 2 }}
        getOptionKey={option => option.id}
      />
      <DataGridPro
        data-testid="admin-data-access-customers-datagrid"
        {...dataGridProps}
        rows={dataGridProps.rows.filter(row => !selectedCustomerId || row.id === selectedCustomerId)}
        autoHeight
        columns={customerTableColumns}
        editMode="row"
        getRowId={row => row.id}
        onRowClick={({ id }) => $$adminDataAccessPage.selectCustomerId(Number(id))}
        initialState={{
          columns: { columnVisibilityModel: { id: false } },
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pagination
        pageSizeOptions={[5, 10]}
        autosizeOnMount
        autosizeOptions={{
          includeOutliers: true,
          includeHeaders: true
        }}
        sx={{
          ...DataGridStylesWithoutSelectionOverride,
          '.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--moving.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeader--last': { padding: 0 },
          '.MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100%'
          }
        }}
      />
    </>
  );
}

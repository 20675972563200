import { createEvent, createStore, sample } from 'effector';

/**
 * @typedef {import('@mui/material').SnackbarProps['message']} Message
 * @typedef {import('@mui/material').AlertProps['severity']} Severity
 * @typedef {import('@mui/material').SnackbarProps['autoHideDuration']} AutoHideDuration
 */

const $isOpen = createStore(/** @type {import('@mui/material').SnackbarProps['open']} */ (false));

const $state = createStore(/** @type {{ message: Message; severity: Severity; autoHideDuration: AutoHideDuration } | null} */ (null));

/** @type {import('effector').EventCallable<{ message: Message, severity: Severity }>} */
const open = createEvent();

/** @type {import('effector').EventCallable<void>} */
const close = createEvent();

sample({
  clock: open,
  target: $state
});

sample({
  clock: open,
  target: $isOpen,
  fn: () => true
});

sample({
  clock: close,
  target: $isOpen,
  fn: () => false
});

sample({
  source: $isOpen,
  filter: isOpen => isOpen === false,
  fn: () => null,
  target: $state
});

export const $$snackbar = {
  $isOpen: $isOpen.map(d => d),
  $state: $state.map(d => d),

  open,
  close
};

import * as React from "react";
import { Container } from "@mui/material";
import Lookup from "./Lookup";
import { hasDashboardPermission, hasSinglePieceLookupPermission } from "../services/UserService/UserService";
import Contact from "./Contact";
import {UserContext} from "../UserContext";

const Home = () => {
    const userDetails = React.useContext(UserContext);

    if (hasDashboardPermission(userDetails.permissions)) {
        // Cached dashboard in Layout will display
        return null;
    } else if (hasSinglePieceLookupPermission(userDetails.permissions)) {
        return (
            <Container>
                <Lookup/>
            </Container>
        );
    } else {
        return (
            <Container>
                <Contact/>
            </Container>
        );
    }
};

export default Home;
import React from 'react';
import PropTypes from 'prop-types';

import { Link as MuiLink } from '@mui/material';

import { typography as variants } from '~/theme';

export { variants as linkVariants };

export const linkColors = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  GREY: 'grey'
};

export const linkSizes = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small'
};

export const linkUnderlines = {
  NONE: 'none',
  HOVER: 'hover',
  ALWAYS: 'always'
};

const Link = ({
  children,
  href,
  variant,
  external,
  underline,
  ...overrides
}) => (
  <MuiLink
    className="Link-root"
    href={href}
    variant={variant}
    underline={underline}
    {...(external && {
      target: '_blank',
      rel: 'noopener'
    })}
    {...overrides}
  >
    {children}
  </MuiLink>
);

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(variants)),
  underline: PropTypes.oneOf(Object.values(linkUnderlines)),
  external: PropTypes.bool
};

Link.defaultProps = {
  children: null,
  href: undefined,
  variant: undefined,
  underline: linkUnderlines.ALWAYS,
  external: false
};

export default Link;

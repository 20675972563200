import { attach, createEvent, createStore, sample } from 'effector';
import { persist } from 'effector-storage/query';
import { debounce } from 'patronum';
import qs from 'qs';
import { pipe } from 'ramda';
import { $$personaService } from '../../services/PersonaService/model';
import { $$reseller } from '../../services/ResellerService/model';
import { $$user } from '../../services/UserService/UserService';
import createDataGridModel from '../../util/createDataGridModel';
import { treeShakeObject } from '../../util/JsonUtils';

//-------------------------------- Stores --------------------------------
const $isResellerUser = sample({
  source: $$personaService.$activePersona,
  fn: persona => !persona?.customerId
});
const $customers = createStore(/** @type {Reseller.Customer[]} */ ([]));
const $filters = createStore(/** @type {{ search?: string; customerId?: string }} */ ({}));

//-------------------------------- Events --------------------------------
/** @type {import('effector').EventCallable<boolean>} */
const tableMounted = createEvent();
/** @type {import('effector').EventCallable<{ key: keyof (typeof $filters)['__']; value: unknown }>} */
const setFilterByKey = createEvent();

//-------------------------------- Effects --------------------------------
const getFreshRowsFx = attach({
  source: $filters,
  /** @type {(params: DataGrid.ServerSideParams, filters: { customerId: Reseller.Customer['id']; search: string }) => (typeof $$user.getUsersFx)['done']['__']['params']} */
  mapParams: (pagination, filters) => treeShakeObject({ ...pagination, ...filters }),
  effect: $$user.getUsersFx
});

const $$usersDataGrid = createDataGridModel({
  type: 'server',
  effect: getFreshRowsFx,
  refetch: debounce({ source: $filters.updates, timeout: 500 }),
  rowIdField: 'userId'
});

sample({
  source: $isResellerUser,
  clock: tableMounted.filter({ fn: Boolean }).map(() => ({})),
  filter: isReseller => isReseller, // Only load customers for reseller level users
  target: $$reseller.getCustomersFx
});

sample({
  clock: $$reseller.getCustomersFx.doneData,
  fn: customers => customers.map(customer => ({ id: customer.id, name: customer.name })).sort((a, b) => a.name.localeCompare(b.name)),
  target: $customers
});

sample({
  source: $filters,
  clock: setFilterByKey,
  fn: (filters, { key, value }) => ({ ...filters, [key]: value }),
  target: $filters
});

persist({
  store: $filters,
  key: 'manage-users-filters',
  serialize: pipe(treeShakeObject, qs.stringify),
  deserialize: qs.parse
});

export const $$manageUsersTab = {
  ...$$usersDataGrid,
  $isResellerUser,
  $filters: $filters.map(filters => ({ ...filters })),
  $customers: $customers.map(d => d),

  tableMounted,
  setFilterByKey
};

import { useAuth0 } from '@auth0/auth0-react';
import { pages } from './pages/Pages';
import { Box, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useGate } from 'effector-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { UserContext } from './UserContext';
import InfoDialog from './components/InfoDialog/InfoDialog';
import Layout from './components/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import useAuth0WithErrorHandling from './hooks/useAuth0WithErrorHandling';
import { AddressVerification } from './pages/AddressVerification';
import BiDashboard from './pages/BiDashboard';
import BiDashboards from './pages/BiDashboards';
import Callback from './pages/Callback';
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import Home from './pages/Home';
import Lookup from './pages/Lookup';
import NoPage from './pages/NoPage';
import SplResults from './pages/SplResults';
import StartLogin from './pages/StartLogin';
import StartLogout from './pages/StartLogout';
import AdminCampaignsPage from './pages/admin/AdminCampaignsPage/AdminCampaignsPage';
import { AdminCustomers } from './pages/admin/AdminCustomersPage';
import AdminDataAccessPage from './pages/admin/AdminDataAccessPage/AdminDataAccessPage';
import { AdminInHomeWindows } from './pages/admin/AdminInHomeWindows';
import { AdminLogos } from './pages/admin/AdminLogos';
import AdminUsersPage from './pages/admin/AdminUsersPage/AdminUsersPage';
import GhsAdminBanners from './pages/ghsadmin/GhsAdminBanners';
import GhsAdminDashboards from './pages/ghsadmin/GhsAdminDashboards/GhsAdminDashboards';
import GhsAdminLogos from './pages/ghsadmin/GhsAdminLogos';
import { Auth0Gate } from './services/AuthService/model';
import { SisenseInfoPropTypes, getSisenseInfo, performBiServerLogout } from './services/BiService';
import { getTokenChanged } from './services/DataService';
import {
  fetchPermissionsFx,
  hasAddressVerificationPermission,
  hasAdminEditCampaignsVersionsPermissions,
  hasAdminEditCustomersLobsPermissions,
  hasAdminInHomeWindowsPermissions,
  hasAdminLogosPermissions,
  hasAdminManageUsersDataAccessPermissions,
  hasAdminRolesPermissionsPermissions,
  hasDashboardPermission,
  hasFileProcessingPermission,
  hasGhsAdminBannerPermissions,
  hasGhsAdminDashboardThumbnailsPermissions,
  hasGhsAdminLogoPermissions,
  hasMailTrakClientLobPermission,
  hasSinglePieceLookupPermission
} from './services/UserService/UserService';
import { $$personaService } from './services/PersonaService/model';
import { FileProcessing } from './pages/FileProcessing';
import BlockingDialog from './components/BlockingDialog/BlockingDialog';
import { $$contactService } from './services/ContactService/model';
import { MailTrakClientLOB } from './pages/MailTrakClientLOB';
import TutorialVideosModal from './components/TutorialVideosModal/TutorialVideosModal';

dayjs.extend(customParseFormat);

/**
 * @typedef {import('./services/BiService.js').DashboardView} DashboardView
 * @typedef {import('./services/BiService.js').SisenseInfo} SisenseInfo
 */

/**
 * Component for the main application.
 *
 * @returns {Element} App
 * @class
 */
function App() {
  const { getAccessTokenSilently } = useAuth0WithErrorHandling();
  const auth0 = useAuth0();

  useGate(Auth0Gate, auth0);

  const [sisenseInfo, setSisenseInfo] = useState(/** @type { SisenseInfo | null } */ (null));
  const [loadComplete, setLoadComplete] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = React.useState({});
  const [sisenseReloadCount, setSisenseReloadCount] = useState(0);

  useEffect(() => {
    getTokenChanged(getAccessTokenSilently);
    return () => {
      getTokenChanged(null);
    };
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          await updateUserDetails(token);
        }
      } finally {
        // This is to make sure all the permissions and dashboards info is available when executing the router.
        // If we don't do this, this will run asynchronously, hence these state variables would be "undefined" when,
        // for example, using them to decide whether the menu items should be displayed.
        // It's also helps to avoid suddenly adding the menu items that depend on specific conditions to be
        // displayed. For example, the dashboards and the admin menu icons.
        setLoadComplete(true);
      }
    };

    initialize();
  }, []);

  const updateUserDetails = async (token, isSwitchPersona = false) => {
    // Terminate Sisense session (if any). This is needed because Sisense works with cookie based authentication.
    // Sisense session tokens last for one day hence if there's an active Sisense session, a new one won't be open.
    // Taking into account that a single person can work with different users and personas, any open session is
    // terminated so a new one for the new user and persona is open
    await performBiServerLogout(!isSwitchPersona);

    const [userPermissions, userPersonas, userActivePersona] = await Promise.allSettled([
      fetchPermissionsFx(),
      $$personaService.getAvailablePersonasFx(),
      $$personaService.getActivePersonaFx(),
      $$contactService.getContactInfoFx()
    ]);
    setUserDetails({
      ...userDetails,
      ...{
        permissions: userPermissions.status === 'fulfilled' ? userPermissions.value : [],
        personas: userPersonas.status === 'fulfilled' ? userPersonas.value : [],
        activePersona: userActivePersona.status === 'fulfilled' ? userActivePersona.value : null,
        isMt: userActivePersona.status === 'fulfilled' ? userActivePersona.value.ghsSystem === 'MAP' : false,
        isSt: userActivePersona.status === 'fulfilled' ? userActivePersona.value.ghsSystem === 'ST' : false
      }
    });
    if (userPermissions.status === 'fulfilled' && hasDashboardPermission(userPermissions.value)) {
      // Increase reload count to force the refresh of the memoized dashboard in the home page
      const newCount = sisenseReloadCount + 1;
      setSisenseReloadCount(newCount);
      // Load Sisense status (Needed to check whether the dashboards menu item and the home dashboard or a banner must be rendered)
      await getSisenseInfo(token, newCount).then(setSisenseInfo);
    }
  };

  const handlePersonaChange = async persona => {
    await $$personaService.setActivePersonaFx(persona);
    // Get a new access token since the persona has changed
    const accessToken = await getAccessTokenSilently({ cacheMode: 'off' });

    await updateUserDetails(accessToken, true);

    navigate('/');
  };

  if (!loadComplete) {
    return (
      <Box display="flex" justifyContent="center" width="100" height="100vh" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  // Wrapping UserProvider will expose the 'value' props of UserContext and UserDispatchContext to the React
  // components down the tree
  return (
    <UserContext.Provider value={userDetails}>
      <Router sisenseInfo={sisenseInfo} handlePersonaChange={handlePersonaChange} />
    </UserContext.Provider>
  );
}

/**
 * Router component that will set the document title based on the pathname.
 *
 *
 * @param {object} props Properties
 * @param {object} props.userDetails user details including permissions, personas, etc
 * @param {SisenseInfo} props.sisenseInfo sisense info
 * @param {Function} props.handlePersonaChange handler for changing persona
 * @returns {Element} Router
 * @class
 */
function Router(props) {
  const userDetails = React.useContext(UserContext);
  const { sisenseInfo, handlePersonaChange } = props;

  const location = useLocation();
  useEffect(() => {
    const currentPage = pages.find(page => page.path === location.pathname);
    // Don't update the title for the dashboard page because it'll be replaced by the dashboard title
    if (currentPage) {
      if (currentPage.name !== 'Dashboard') {
        document.title = currentPage.name;
      }
    } else {
      document.title = 'GrayHair Client Portal';
    }
  }, [location]);

  const hasAccessToDashboards = () => {
    return (sisenseInfo?.authenticated && sisenseInfo?.dashboardsNo > 0) ?? false;
  };

  return (
    <>
      <InfoDialog />
      <BlockingDialog />
      <TutorialVideosModal />
      
      <Routes>
        <Route path="/login" element={<StartLogin />} />
        <Route path="/" element={<Layout pages={pages} handlePersonaChange={handlePersonaChange} sisenseInfo={sisenseInfo} />}>
          {/* The home page will be cached in the Layout to avoid mounting & unmounting the Sisense components
                     again and again. We need to keep the index route with ProtectedRoute rendering an empty container
                      for the Auth0 redirection to work */}
          <Route index element={<ProtectedRoute component={Home} />} />
          <Route path="dashboards" element={<ProtectedRoute component={BiDashboards} checkAccessFn={hasAccessToDashboards} />} />
          <Route path="lookup" element={<ProtectedRoute component={Lookup} checkAccessFn={() => hasSinglePieceLookupPermission(userDetails.permissions)} />} />
          <Route path="splResults" element={<ProtectedRoute component={SplResults} checkAccessFn={() => hasSinglePieceLookupPermission(userDetails.permissions)} />} />
          <Route path="contact" element={<ProtectedRoute component={Contact} />} />
          <Route path="faq" element={<ProtectedRoute component={Faq} />} />
          <Route path="logout" element={<StartLogout />} />
          <Route path="ghsAdmin/logos" element={<ProtectedRoute component={GhsAdminLogos} checkAccessFn={() => hasGhsAdminLogoPermissions(userDetails.permissions)} />} />
          <Route path="ghsAdmin/dashboards" element={<ProtectedRoute component={GhsAdminDashboards} checkAccessFn={() => hasGhsAdminDashboardThumbnailsPermissions(userDetails.permissions)} />} />
          <Route path="ghsAdmin/banners" element={<ProtectedRoute component={GhsAdminBanners} checkAccessFn={() => hasGhsAdminBannerPermissions(userDetails.permissions)} />} />
          <Route path="ghsAdmin/fileProcessing" element={<ProtectedRoute component={FileProcessing} checkAccessFn={() => hasFileProcessingPermission(userDetails.permissions)} />} />
          <Route path="ghsAdmin/mailTrakClientLOB" element={<ProtectedRoute component={MailTrakClientLOB} checkAccessFn={() => hasMailTrakClientLobPermission(userDetails.permissions)} />} />
          <Route path="admin/roles" element={<ProtectedRoute component={AdminUsersPage} checkAccessFn={() => hasAdminRolesPermissionsPermissions(userDetails.permissions)} />} />
          <Route path="admin/campaigns" element={<ProtectedRoute component={AdminCampaignsPage} checkAccessFn={() => hasAdminEditCampaignsVersionsPermissions(userDetails.permissions)} />} />
          <Route path="admin/inHomeWindows" element={<ProtectedRoute component={AdminInHomeWindows} checkAccessFn={() => hasAdminInHomeWindowsPermissions(userDetails.permissions)} />} />
          <Route path="admin/data-access" element={<ProtectedRoute component={AdminDataAccessPage} checkAccessFn={() => hasAdminManageUsersDataAccessPermissions(userDetails.permissions)} />} />
          <Route path="admin/customers" element={<ProtectedRoute component={AdminCustomers} checkAccessFn={() => hasAdminEditCustomersLobsPermissions(userDetails.permissions)} />} />
          <Route path="admin/logos" element={<ProtectedRoute component={AdminLogos} checkAccessFn={() => hasAdminLogosPermissions(userDetails.permissions)} />} />
          <Route path="callback" element={<Callback />} />
          <Route path="dashboard" element={<ProtectedRoute component={BiDashboard} checkAccessFn={() => hasDashboardPermission(userDetails.permissions)} unmountShouldUnloadEmbedSdk={false} />} />
          <Route path="addressVerification" element={<ProtectedRoute component={AddressVerification} checkAccessFn={() => hasAddressVerificationPermission(userDetails.permissions)} />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </>
  );
}

Router.propTypes = {
  sisenseInfo: SisenseInfoPropTypes,
  handlePersonaChange: PropTypes.func
};

export { App };

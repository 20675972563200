import { createEvent, createStore, sample } from 'effector';
import { useUnit } from 'effector-react';
import { debounce } from 'patronum';
import { $$reseller } from '../../services/ResellerService/model';
import { treeShakeObject } from '../../util/JsonUtils';
import { createDialog } from '../../util/createDialog';

/** @typedef {Pick<Reseller.User, "firstName" | "lastName" | "email"> & { userId: number; }} ResellerUserFormValues */

/** @type {DialogFactory<{ title?: string; data?: Partial<ResellerUserFormValues>; editMode?: boolean; onSubmit: (data: ResellerUserFormValues) => Promise<unknown> }>} */
const dialog = createDialog();

const $formValues = createStore(/** @type {ResellerUserFormValues} */ ({ firstName: '', lastName: '', email: '' }));

const $formErrors = createStore(/** @type {Record<keyof ResellerUserFormValues, string>} */ ({}));

/** @type {import('effector').EventCallable<{ name: keyof ResellerUserFormValues; value: string }>} */
const setFormValue = createEvent();

// When the form value event is triggered, update the form values store with the new value
sample({
  source: $formValues,
  clock: setFormValue,
  fn: (formValues, { name, value }) => ({ ...formValues, [name]: value }),
  target: $formValues
});

// When dialog opens, set the form values (from the "open" function argument) to the form values store
sample({
  source: dialog.$state.map(state => ({ ...$formValues.defaultState, ...(state?.data || {}) })),
  target: $formValues
});

// When the form is submitted successfully, close the dialog
sample({
  clock: [$$reseller.addCustomersUserFx.done, $$reseller.addLobUserFx.done, $$reseller.editCustomersUserFx.done, $$reseller.editLobUserFx.done],
  target: dialog.close
});

// When the dialog is closed, reset the form values and errors
sample({
  clock: dialog.close,
  target: [$formValues.reinit, $formErrors.reinit]
});

// $isSubmitPending is a store that is true when either of the two effects are pending. Pending is a boolean store so the result is also a store
const $isSubmitPending = sample({
  source: [$$reseller.addCustomersUserFx.pending, $$reseller.addLobUserFx.pending, $$reseller.editCustomersUserFx.pending, $$reseller.editLobUserFx.pending],
  fn: states => states.some(Boolean)
});

// When the email field is updated, debounce the value for 500ms, take the raw value, don't proceed if the value is the same as the previous one, and call the validateUsersEmailFx with the email value.
sample({
  source: dialog.$state.map(state => (state && state.data && 'email' in state.data ? state.data.email : '')),
  clock: debounce({ source: $formValues.map(values => /** @type {object} */ (values).email).updates, timeout: 500 }),
  filter: (oldValue, newValue) => oldValue !== newValue,
  fn: (_, email) => email,
  target: $$reseller.validateUsersEmailFx
});

// When the validateUsersEmailFx is done, take the errors and the result and update the form errors. treeShakeObject is a utility function that removes empty values from an object.
sample({
  source: $formErrors,
  clock: $$reseller.validateUsersEmailFx.doneData,
  fn: (errors, result) => treeShakeObject({ ...errors, email: result ? null : 'This user already exists and cannot be duplicated.' }),
  target: $formErrors
});

export const $$resellerUserDialog = {
  ...dialog
};

export const useResellerFormDialog = () => {
  const formValues = useUnit($formValues);
  const formErrors = useUnit($formErrors);
  const state = useUnit(dialog.$state);
  const isOpen = useUnit(dialog.$isOpen);
  const isSubmitLoading = useUnit($isSubmitPending);

  return {
    formValues,
    formErrors,
    state,
    isOpen,

    setFormValue: setFormValue,
    close: dialog.close,
    formSubmitted: () => {
      if (state) {
        state.onSubmit(/** @type {ResellerUserFormValues} */ (formValues));
      }
    },
    isSubmitLoading
  };
};

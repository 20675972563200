// @ts-ignore
import { Button, buttonVariants } from '@ghs/grayhair-component-library';
import { Cancel, DeleteForever, Edit, Save, Visibility } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { DataGridPro, gridClasses, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { useUnit } from 'effector-react';
import { isEqual } from 'lodash';
import { EditableStringCell } from '../../../components/CampaignVersionsDialog/EditableStringCell';
import { DataGridStyles } from '../../../util/DataGridStyles';
import { numberWithCommas } from '../../../util/JsonUtils';
import { $$adminCampaignsPage } from './model';

/**
 * CampaignsTable
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function CampaignsTable() {
  const dataGridProps = $$adminCampaignsPage.$$campaigns.useDataGrid();

  const rows = useUnit($$adminCampaignsPage.$$campaigns.$rows);
  const errors = useUnit($$adminCampaignsPage.$$campaigns.$errors);
  const rowsModes = useUnit($$adminCampaignsPage.$$campaigns.$rowModesModel);
  const highlightedRows = useUnit($$adminCampaignsPage.$$campaigns.$highlightedRows);
  const pendingValidationParams = useUnit($$adminCampaignsPage.$$validationStatuses.$statuses);
  const unsavedChanges = useUnit($$adminCampaignsPage.$$campaigns.$unsavedChanges);

  /** @type {import('@mui/x-data-grid').GridEventListener<'rowEditStop'>} */
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.enterKeyDown) {
      event.defaultMuiPrevented = true;
    }
    if (params.reason === GridRowEditStopReasons.enterKeyDown && !errors.some(e => e.id === params.id)) {
      $$adminCampaignsPage.saveClicked(params.id);
    }
  };

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Reseller.Campaign>[]} */
  const campaignColumns = [
    { field: 'customer', headerName: 'Customer', minWidth: 160, maxWidth: 300, flex: 1, valueGetter: params => /** @type {{ name: string }} */ (params).name },
    { field: 'lineOfBusiness', headerName: 'Line of Business', minWidth: 160, maxWidth: 300, flex: 1, valueGetter: params => /** @type {{ name: string }} */ (params).name },
    {
      field: 'name',
      headerName: 'Campaign Name',
      editable: true,
      minWidth: 160,
      maxWidth: 300,
      flex: 1,
      renderEditCell: params => <EditableStringCell {...params} $$table={$$adminCampaignsPage.$$campaigns} />
    },
    {
      field: 'number',
      headerName: 'Campaign Number',
      editable: true,
      minWidth: 100,
      maxWidth: 300,
      flex: 1,
      renderEditCell: params => <EditableStringCell {...params} $$table={$$adminCampaignsPage.$$campaigns} />
    },
    {
      field: 'mailDate',
      headerName: 'Mail Date',
      minWidth: 110,
      maxWidth: 150,
      valueFormatter: value => (value !== null ? dayjs(value).format('MM/DD/YYYY') : '')
    },
    { field: 'mailQuantity', headerName: 'Mail Qty', type: 'number', minWidth: 100, valueGetter: numberWithCommas },
    {
      field: 'versionCount',
      headerName: 'Versions',
      type: 'number',
      renderCell: ({ row, value }) => (
        <Box sx={{ m: 0, p: 0 }}>
          <Button variant={buttonVariants.TEXT} startIcon={<Visibility />} onClick={() => $$adminCampaignsPage.eyeIconClicked(row.id)} sx={{ width: '100%', justifyContent: 'flex-start' }}>
            {value}
          </Button>
        </Box>
      )
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      getActions: ({ id }) => {
        const isInEditMode = rowsModes[id]?.mode === GridRowModes.Edit;
        const campaign = rows.find(c => c.id === id);
        const isCellWithError = errors.some(e => e.id === id);
        const isRowHasUnsavedChanges = unsavedChanges.find(r => r.id === id);
        if (!campaign) {
          return [];
        }
        const isValidationPending = pendingValidationParams.some(params => isEqual(params, campaign));
        const saveButtonTooltipText = [isValidationPending && 'Validating changes...', !isRowHasUnsavedChanges && 'No changes to save', !isCellWithError && 'Save'].find(Boolean);
        return isInEditMode
          ? [
              <Tooltip key={`campaign-save-button-${id}`} title={saveButtonTooltipText} arrow>
                <span>
                  <IconButton
                    key={`campaign-save-button-${id}`}
                    data-testid={`campaign-save-button-${id}`}
                    onClick={() => {
                      $$adminCampaignsPage.saveClicked(id);
                    }}
                    disabled={isCellWithError || !isRowHasUnsavedChanges || isValidationPending}
                  >
                    <Save fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>,
              <IconButton key={`campaign-cancel-button-${id}`} data-testid={`campaign-cancel-button-${id}`} onClick={() => $$adminCampaignsPage.cancelClicked(Number(id))}>
                <Cancel fontSize="small" />
              </IconButton>
            ]
          : [
              <IconButton
                key={`campaign-edit-button-${id}`}
                data-testid={`campaign-edit-button-${id}`}
                onClick={() => {
                  $$adminCampaignsPage.$$campaigns.setRowMode({ id, mode: GridRowModes.Edit });
                }}
              >
                <Edit fontSize="small" color="primary" />
              </IconButton>,
              <Tooltip key={`campaign-delete-button-${id}`} title={campaign.hasScans ? 'Scans are associated with this campaign and it cannot be deleted' : 'Delete'} arrow>
                <span>
                  <IconButton
                    data-testid={`campaign-delete-button-${id}-${campaign.hasScans ? 'disabled' : 'enabled'}`}
                    onClick={() => $$adminCampaignsPage.deleteClicked(/** @type {number} */ (id))}
                    disabled={campaign.hasScans}
                    sx={{ '&.Mui-disabled': { pointerEvents: 'auto' } }}
                  >
                    <DeleteForever fontSize="small" color={campaign.hasScans ? /** @type {'secondary'} */ ('secondary.enabled') : 'warning'} />
                  </IconButton>
                </span>
              </Tooltip>
            ];
      }
    }
  ];

  return (
    <DataGridPro
      {...dataGridProps}
      autoHeight
      columns={campaignColumns}
      disableRowSelectionOnClick
      editMode="row"
      onRowEditStop={handleRowEditStop}
      onRowDoubleClick={({ id }) => $$adminCampaignsPage.$$campaigns.setRowMode({ id, mode: GridRowModes.Edit })}
      processRowUpdate={(response, { id }) => {
        $$adminCampaignsPage.saveClicked(id);
        return response;
      }}
      initialState={{
        columns: { columnVisibilityModel: { id: false } }
      }}
      localeText={{
        noRowsLabel: 'No results'
      }}
      pagination
      pageSizeOptions={[5, 10, 25, 50]}
      autosizeOnMount
      autosizeOptions={{
        includeOutliers: true,
        includeHeaders: true
      }}
      getCellClassName={params => {
        return Object.entries({
          [`${gridClasses.cell}--error`]: Boolean(errors.find(e => e.id === params.id && e.field === params.field)),
          'editable-date-cell': rowsModes[params.row.id]?.mode === 'edit' && params.isEditable === true,
          'editable-date': rowsModes[params.row.id]?.mode === 'edit' && params.colDef.type === 'date',
          [`${gridClasses.cell}--success`]: highlightedRows.includes(params.row.id)
        })
          .filter(([, value]) => value)
          .map(([key]) => key)
          .slice(0, 1)
          .join(' ');
      }}
      sx={DataGridStyles}
    />
  );
}

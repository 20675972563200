import { useAuth0 } from '@auth0/auth0-react';

/**
 * Wrapper hook to centralize error handling for useAuth0.
 *
 * @returns {{getAccessTokenSilently: ((function({cacheMode: *}=): Promise<string|undefined>)|*)}} wrapped auth0 functions
 */
const useAuth0WithErrorHandling = () => {
  const { getAccessTokenSilently: auth0GetAccessTokenSilently, loginWithRedirect } = useAuth0();

  /**
   * Wrapped `getAccessTokenSilently` function with error handling.
   *
   * @param {object} options See https://auth0.github.io/auth0-react/interfaces/GetTokenSilentlyOptions.html
   * @param {object} options.authorizationParams Parameters that will be sent back to Auth0 as part of a request.
   * @param {string} options.cacheMode whether to retrieve access token from cache.
   * @param {boolean} options.detailedResponse whether to retrieve the detailed response or just the access token.
   * @param {number} options.timeoutInSeconds maximum number of seconds to wait before declaring the background /authorize call as failed for timeout
   * @returns {Promise<any>} auth0 access token or detailed response
   */
  const getAccessTokenSilently = async (options = {}) => {
    const defaultOptions = {
      authorizationParams: null,
      cacheMode: 'on',
      detailedResponse: false,
      timeoutInSeconds: 60
    }
    try {
      return await auth0GetAccessTokenSilently({...defaultOptions, ...options});
    } catch (error) {
      console.error('Error while getting the access token:', error);
      if (error.error === 'login_required') {
        loginWithRedirect({appState: {returnTo: location.href.replace(location.origin, '')}});
      }
    }
  };

  return { getAccessTokenSilently };
};

export default useAuth0WithErrorHandling;

// @ts-ignore
import { Dialog, DialogContentText, dialogMaxWidths } from '@ghs/grayhair-component-library';
import { Button } from '@mui/material';
import { useUnit } from 'effector-react';
import { $$infoDialog } from './model';

/**
 * InfoDialog
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function InfoDialog() {
  const open = useUnit($$infoDialog.$isOpen);
  const state = useUnit($$infoDialog.$state);

  if (!state) {
    return null;
  }

  const { title, content, closeButtonText = 'Close' } = state;

  return (
    <Dialog
      title={title}
      open={open}
      fullWidth={true}
      maxWidth={dialogMaxWidths.XS}
      onClose={$$infoDialog.close}
      actions={<Button onClick={() => $$infoDialog.close()}>{closeButtonText}</Button>}
      data-testid="info-dialog"
    >
      <DialogContentText data-testid="info-dialog-text">{content}</DialogContentText>
    </Dialog>
  );
}

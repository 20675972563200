import PropTypes from 'prop-types';

import TextField, { textFieldVariants } from '~/components/TextField';

export { textFieldVariants as multilineVariants };

const Multiline = ({ rows, maxRows, variant, ...overrides }) => (
  <TextField
    multiline
    rows={rows}
    maxRows={maxRows}
    variant={variant}
    {...overrides}
  />
);

Multiline.propTypes = {
  rows: PropTypes.number,
  maxRows: PropTypes.number,
  variant: PropTypes.oneOf(Object.values(textFieldVariants))
};

Multiline.defaultProps = {
  variant: textFieldVariants.STANDARD
};

export default Multiline;

import { attach, createEvent, createStore, restore, sample } from 'effector';
import { persist } from 'effector-storage/local';
import { spread } from 'patronum';
import { $$auth } from '../../services/AuthService/model';
import { $$roles } from '../../services/RolesService/model';
import { $$user } from '../../services/UserService/UserService';
import createDataGridModel from '../../util/createDataGridModel';
import { createDialog } from '../../util/createDialog';
import { VOID } from '../../util/JsonUtils';
import { $$confirmationDialog } from '../ConfirmationDialog/model';
import { $$snackbar } from '../SnackbarRoot/model.js';
import { createDeleteConfirmationDialogArg } from './utils';

/** @type {DialogFactory<Partial<{ userId: Users.User['userId'] }>>} */
const dialog = createDialog();

/** @type {import('effector').EventCallable<Roles.Role['id'][]>} */
const rolesToAddChanged = createEvent();

/** @type {import('effector').EventCallable<Roles.Role['id']>} */
const openDeleteConfirmation = createEvent();

/** @type {import('effector').EventCallable<boolean>} */
const deleteConfirmationSkipChecked = createEvent();

/** @type {import('effector').EventCallable<Roles.Role['id']>} */
const deleteConfirmed = createEvent();

/** @type {import('effector').EventCallable<void>} */
const saveClicked = createEvent();

/** @type {import('effector').EventCallable<void>} */
const refetch = createEvent();

const $rolesOptions = createStore(/** @type {Roles.Role[]} */ ([])).reset(dialog.close);

const $deleteConfirmationSkipped = createStore(/** @type {boolean} */ (false));

const $rolesToAdd = restore(rolesToAddChanged, []).reset(sample({ clock: [dialog.close, $$user.assignRolesToUserFx.done] }));

const getFreshRowsFx = attach({
  source: dialog.$state.map(state => state?.userId || ''),
  /** @type {(params: void, filters: Users.User['userId']) => object} */
  mapParams: (_, userId) => userId,
  effect: $$user.getUserRolesFx
});

const $$rolesDataGrid = createDataGridModel({
  type: 'client',
  effect: getFreshRowsFx,
  refetch
});

const $isManagingCurrentUser = sample({
  source: {
    authorizedUserId: $$auth.$user.map(user => user?.sub || null),
    openedUserId: dialog.$state.map(state => state?.userId || null)
  },
  fn: ({ authorizedUserId, openedUserId }) => {
    return authorizedUserId === openedUserId;
  }
});

/** @type {import('effector').Store<Users.User | null>} */
const $user = sample({
  source: {
    users: $$user.$users,
    userId: dialog.$state.map(params => params?.userId || null),
    isManagingCurrentUser: $isManagingCurrentUser,
    authorizedUser: $$auth.$user.map(user => user || null)
  },
  fn: ({ users, userId, isManagingCurrentUser, authorizedUser }) => {
    const resultUser = users.find(user => user.userId === userId) || null;
    if (resultUser && isManagingCurrentUser) {
      // There are at least 3 places where the user avatar is displayed: Top right user menu, "My Roles" tab, "Manage user" modal (when clicking on the current user)
      // In the first 2 places, the information of the current user comes from Auth0 directly. However, in the user modal, the information comes from database (the user
      // may not even exist in Auth0 at that point).
      // So in order to at least display the same avatar for the current user in all places, we overwrite the user detail here only for the current user.
      return {
        ...resultUser,
        firstName: authorizedUser.given_name,
        lastName: authorizedUser.family_name,
        avatarUrl: authorizedUser.picture
      };
    }
    return resultUser;
  }
});

persist({ store: $deleteConfirmationSkipped, key: 'gh:admin-users-roles:skip-role-delete-confirm' });

sample({
  clock: dialog.open.map(params => params?.userId || null),
  filter: Boolean,
  target: [$$user.getUserByIdFx, $$roles.getManageableRolesFx.prepend(() => {})]
});

sample({
  clock: $$roles.getManageableRolesFx.doneData,
  target: $rolesOptions
});

sample({
  clock: deleteConfirmationSkipChecked,
  target: $deleteConfirmationSkipped
});

sample({
  // @ts-ignore
  source: {
    roles: $rolesOptions,
    users: $$user.$users,
    userId: dialog.$state.map(params => params?.userId || ''),
    shouldSkip: $deleteConfirmationSkipped
  },
  clock: openDeleteConfirmation,
  fn: ({ userId, shouldSkip, roles, users }, roleId) =>
    shouldSkip ? { deleteConfirmed: userId } : { confirm: createDeleteConfirmationDialogArg({ roles, users, roleId, userId, deleteConfirmationSkipChecked, deleteConfirmed }) },
  target: spread({ deleteConfirmed, confirm: $$confirmationDialog.open })
});

sample({
  source: dialog.$state.map(params => params?.userId || ''),
  clock: deleteConfirmed,
  fn: (userId, roleId) => ({ userId, roleId }),
  target: $$user.deleteRoleFromUserFx
});

if ($$rolesDataGrid.removeRowsByIds) {
  sample({
    clock: $$user.deleteRoleFromUserFx.done.map(({ params: { roleId } }) => [roleId]),
    target: $$rolesDataGrid.removeRowsByIds
  });
}

sample({
  clock: $$user.deleteRoleFromUserFx.done,
  fn: () => ({ message: 'Role deleted from user', severity: 'success' }),
  target: $$snackbar.open
});

sample({
  clock: $$user.deleteRoleFromUserFx.fail,
  fn: ({ error }) => ({ message: error?.response?.data?.message ?? 'Error deleting role from user', severity: 'error', autoHideDuration: 6000 }),
  target: $$snackbar.open
});

sample({
  source: {
    roles: $rolesToAdd,
    userId: dialog.$state.map(params => params?.userId || '')
  },
  clock: saveClicked,
  fn: ({ roles, userId }) => (roles.length ? { saveFx: /** @type  {{ userId: Users.User['userId']; roles:Roles.Role['id'][] }} */ ({ userId, roles }) } : { close: VOID }),
  target: spread({ saveFx: $$user.assignRolesToUserFx, close: dialog.close })
});

sample({
  clock: $$user.assignRolesToUserFx.done,
  fn: () => ({
    snackbar: { message: 'Role(s) assigned to user', severity: 'success' },
    refetch: VOID
  }),
  target: spread({
    snackbar: $$snackbar.open,
    refetch: refetch
  })
});

sample({
  clock: $$user.assignRolesToUserFx.fail,
  fn: ({ error }) => ({ message: error?.response?.data?.message ?? 'Error assigning role to user', severity: 'error', autoHideDuration: 6000 }),
  target: $$snackbar.open
});

export const $$manageUserRolesModal = {
  ...dialog,

  $$rolesDataGrid,

  $user,
  $rolesOptions,
  $rolesToAdd,
  $isManagingCurrentUser,

  rolesToAddChanged,
  openDeleteConfirmation,
  saveClicked
};

import * as React from 'react';
import PropTypes from 'prop-types';
import {
  useTheme,
  Box,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  InputBase
} from '@mui/material';

import { Search } from '@mui/icons-material';

const AppBar = ({
  toggleButton,
  title,
  breadcrumbs,
  onSearch,
  onSearchChange,
  menu,
  dense,
  ...overrides
}) => {
  const theme = useTheme();

  return (
    <MuiAppBar color="secondary" position="fixed" {...overrides}>
      <Toolbar
        variant={dense ? 'dense' : 'regular'}
        sx={{ px: 1.5, py: dense ? 0 : 1, bgcolor: 'secondary.dark' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {toggleButton && <Box sx={{ mr: 2 }}>{toggleButton}</Box>}
          <Typography
            className="AppBar-title"
            variant="h6"
            component="div"
            sx={{ display: 'flex', fontWeight: 600 }}
          >
            {title}
          </Typography>
          {breadcrumbs && (
            <Box
              className="AppBar-breadcrumbs"
              sx={{
                display: { xs: 'none', md: 'block' },
                ml: 5,
                '& .Breadcrumbs-link, & .MuiBreadcrumbs-separator': {
                  color: 'primary.contrastText'
                }
              }}
            >
              {breadcrumbs}
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
          {onSearch && (
            <Box
              className="AppBar-searchBar"
              component="form"
              onSubmit={onSearch}
              sx={{
                display: { xs: 'none', sm: 'flex' },
                alignItems: 'center',
                ml: 3,
                px: 2,
                height: 36,
                borderRadius: 0.5,
                color: 'primary.contrastText',
                bgcolor: 'misc.appBarInputBg',
                '&:hover, &:focus-within': {
                  bgcolor: 'misc.appBarInputBgHovered'
                },
                transition: theme.transitions.create('width'),
                maxWidth: '100%',
                width: 200,
                '&:focus-within': {
                  width: 246
                }
              }}
            >
              <Search sx={{ color: 'primary.contrastText' }} />
              <InputBase
                sx={{
                  ml: 1,
                  color: 'primary.contrastText',
                  '&::placeholder': {
                    color: 'primary.contrastText'
                  }
                }}
                placeholder="Search…"
                inputProps={{
                  'aria-label': 'search',
                  onChange: onSearchChange
                }}
              />
              <input type="submit" style={{ display: 'none' }} />
            </Box>
          )}
          {menu && (
            <Box
              className="AppBar-menu"
              sx={{
                ml: 1,
                display: 'flex',
                alignItems: 'center',
                '& .Link-root': {
                  color: 'primary.contrastText',
                  textTransform: 'uppercase'
                },
                '& > * + *': {
                  ml: 0.5
                }
              }}
            >
              {menu}
            </Box>
          )}
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

AppBar.propTypes = {
  toggleButton: PropTypes.node,
  title: PropTypes.node,
  breadcrumbs: PropTypes.node,
  onSearch: PropTypes.func,
  onSearchChange: PropTypes.func,
  menu: PropTypes.node,
  dense: PropTypes.bool
};

export default AppBar;

import { ThemeProvider as ComponentLibraryThemeProvider } from '@ghs/grayhair-component-library';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Theme provider for the application.
 *
 * @typedef {import('@mui/material/styles').Theme} Theme
 * @param {object} props Props.
 * @param {React.ReactNode} props.children Children.
 * @param {Theme} props.palette Palette.
 * @returns {React.ReactElement} Theme provider.
 */
export default function CombinedThemeProvider(props) {
  const { palette, children } = props;

  return (
    <MuiThemeProvider theme={palette}>
      <ComponentLibraryThemeProvider theme={palette}>{children}</ComponentLibraryThemeProvider>
    </MuiThemeProvider>
  );
}

CombinedThemeProvider.displayName = 'CombinedThemeProvider';

CombinedThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  palette: PropTypes.object.isRequired
};

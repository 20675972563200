import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  useTheme,
  Breadcrumbs as MuiBreadcrumbs,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem
} from '@mui/material';
import { MoreHoriz, Star } from '@mui/icons-material';
import { isEmpty } from 'ramda';

import Link from '../Link';
import IconButton from '../IconButton';

const Item = ({ item, icon }) => {
  const isActive = item?.isActive ?? false;
  const hasIcon = icon === true;

  const theme = useTheme();
  const colorTextPrimary = theme.palette.text.primary;
  const colorTextSecondary = theme.palette.text.secondary;

  return (
    <Link
      className="Breadcrumbs-link"
      href={item.url}
      variant="body1"
      underline="none"
      color={isActive ? colorTextPrimary : colorTextSecondary}
      fontWeight={isActive ? 700 : 'medium'}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      {hasIcon && <Star sx={{ mr: 0.5 }} fontSize="inherit" />}
      {item.label}
    </Link>
  );
};

const Breadcrumbs = ({ items, icon, separator, maxItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  // Checking if the data has exceeded the maximum limit.
  const isLimitExceeded = items.length > maxItems;
  const hasData = !isEmpty(items);

  const theme = useTheme();

  return hasData ? (
    <MuiBreadcrumbs aria-label="breadcrumb" separator={separator}>
      {isLimitExceeded && <Item icon={icon} item={items[0]} />}

      {isLimitExceeded && (
        <div className="collapse-menu">
          <IconButton
            aria-label="collapse"
            size="small"
            color="secondary"
            onClick={handleClick}
            sx={{
              backgroundColor: theme.palette.grey[100],
              fontSize: 'small',
              borderRadius: 0,
              padding: theme.spacing(0.5),
              marginLeft: theme.spacing(0.5),
              marginRight: theme.spacing(0.5)
            }}
          >
            <MoreHoriz fontSize="small" />
          </IconButton>
          <MuiMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {items.slice(1, items.length - 1).map(item => (
              <MuiMenuItem href={item.url} key={item.url}>
                {item.label}
              </MuiMenuItem>
            ))}
          </MuiMenu>
        </div>
      )}

      {isLimitExceeded && maxItems !== 1 && (
        <Item item={items[items.length - 1]} icon={icon} />
      )}

      {!isLimitExceeded &&
        items.map(item => <Item item={item} key={item.url} icon={icon} />)}
    </MuiBreadcrumbs>
  ) : null;
};

const itemShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isActive: PropTypes.bool
});

Breadcrumbs.propTypes = {
  separator: PropTypes.node,
  items: PropTypes.arrayOf(itemShape),
  maxItems: PropTypes.number,
  icon: PropTypes.bool
};

Item.propTypes = {
  item: itemShape,
  icon: PropTypes.bool
};

export default Breadcrumbs;

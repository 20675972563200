import {validStates} from "./UsStateUtils";

/**
 * Retrieves a search parameter value from the provided URLSearchParams object,
 * or returns a default value if the parameter is not found or invalid.
 *
 * @param {URLSearchParams} searchParams - The URLSearchParams object containing the query parameters.
 * @param {string} name - The name of the query parameter to retrieve.
 * @param {string|boolean} defaultValue - The default value to return if the parameter is not found or invalid.
 * @returns {string|boolean} The value of the specified query parameter, or the default value if not found or invalid.
 */
export const getSearchParamOrDefault = (searchParams ,name, defaultValue) => {
    let finalValue;
    if (typeof defaultValue === 'boolean') {
        if (searchParams.has(name)) {
            if (searchParams.get(name).toLowerCase() === 'true') {
                finalValue = true;
            } else if (searchParams.get(name).toLowerCase() === 'false') {
                finalValue = false;
            } else {
                finalValue = defaultValue;
            }
        } else {
            finalValue = defaultValue;
        }
    } else {
        finalValue = (searchParams.get(name) || defaultValue);
    }
    return finalValue;
}

/**
 * Meant to retrieve State search parameter value from the provided URLSearchParams object while verifying that the
 * State is present in out allowed list of States and if so returns abbreviation, or returns a default value if the
 * parameter is not found or invalid.
 *
 * @param {URLSearchParams} searchParams - The URLSearchParams object containing the query parameters.
 * @param {string} name - The name of the State query parameter.
 * @param {string} defaultValue - The default value to return if the parameter is not found or invalid.
 * @returns {string} The value of the specified query parameter, or the default value if not found or invalid.
 */
export const getStateSearchParamWithValidationOrDefault = (searchParams ,name, defaultValue) => {
    let finalValue = defaultValue;
    if (searchParams.has(name)) {
        const selectedState = validStates.find(state =>
            state.abbreviation.toLowerCase() === searchParams.get(name).toLowerCase() ||
            state.name.toLowerCase() === searchParams.get(name).toLowerCase()
        );

        if (selectedState) {
            finalValue = selectedState.abbreviation
        }
    }
    return finalValue;
}

/**
 * Constructs a new URLSearchParams object from the provided object, containing non-null, non-empty values.
 *
 * @param {object} object - The object from which to construct the URLSearchParams.
 * @returns {URLSearchParams} A new URLSearchParams object containing non-null, non-empty values from the provided object.
 */
export const getNewSearchParamsFromObject = (object) => {
    const newSearchParams = new URLSearchParams();

    // Iterate over formData and add non-null, non-empty values to newSearchParams
    Object.entries(object).forEach(([key, value]) => {
        if (value != null && value !== '') {
            newSearchParams.set(key, value);
        }
    });

    return newSearchParams
}

/**
 * Checks if all string values of the provided object are empty.
 *
 * @param {object} object - The object to check.
 * @returns {boolean} True if all string values are empty or if the object is empty, false otherwise.
 */
export const areObjectStringValuesEmpty = (object) => {
    return Object.values(object).every(v => (typeof v === 'string') ? (v === '') : true);
}
import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Avatar as MuiAvatar, useTheme } from '@mui/material';

import Badge, {
  badgeVariants,
  badgeColors,
  badgeOverlaps
} from '~/components/Badge';

import { requiredOnProp, requiredIfTrue } from '~/util/propTypes';

export const avatarVariants = {
  CIRCULAR: 'circular',
  ROUNDED: 'rounded',
  SQUARE: 'square'
};

const Avatar = forwardRef(
  (
    {
      children,
      alt,
      src,
      srcSet,
      sizes,
      variant,
      badge,
      badgeLabel,
      sx,
      ...overrides
    },
    ref
  ) => {
    const theme = useTheme();

    const avatarBase = (
      <MuiAvatar
        ref={ref}
        alt={alt}
        src={src}
        srcSet={srcSet}
        sizes={sizes}
        variant={variant}
        sx={{
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          ...sx
        }}
        {...overrides}
      >
        {children}
      </MuiAvatar>
    );

    return badge ? (
      <Badge
        label={badgeLabel}
        variant={badgeVariants.DOT}
        color={badgeColors.SUCCESS}
        overlap={badgeOverlaps.CIRCULAR}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        sx={{
          '& .MuiBadge-dot': {
            border: '1px solid',
            borderColor: theme.palette.background.default
          }
        }}
      >
        {avatarBase}
      </Badge>
    ) : (
      avatarBase
    );
  }
);

Avatar.displayName = 'Avatar';

Avatar.propTypes = {
  children: PropTypes.node,
  alt: requiredOnProp('src', 'srcSet'),
  src: PropTypes.string,
  srcSet: PropTypes.string,
  sizes: requiredOnProp('srcSet'),
  variant: PropTypes.oneOf(Object.values(avatarVariants)),
  badge: PropTypes.bool,
  badgeLabel: requiredIfTrue('badge'),
  sx: PropTypes.shape()
};

export default Avatar;

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import { IconButton as MuiIconButton } from '@mui/material';

import { colors as themeColors } from '../../theme';

export const iconButtonSizes = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small'
};

export const iconButtonColors = {
  INHERIT: 'inherit',
  ...omit(['TEXT'], themeColors)
};

const IconButton = forwardRef(
  (
    {
      children,
      'aria-label': ariaLabel,
      color,
      size,
      disabled,
      onClick,
      ...overrides
    },
    ref
  ) => (
    <MuiIconButton
      ref={ref}
      aria-label={ariaLabel}
      color={color}
      size={size}
      disabled={disabled}
      onClick={onClick}
      {...overrides}
    >
      {children}
    </MuiIconButton>
  )
);

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  children: PropTypes.node,
  'aria-label': PropTypes.string.isRequired,
  color: PropTypes.oneOf(Object.values(iconButtonColors)),
  size: PropTypes.oneOf(Object.values(iconButtonSizes)),
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

IconButton.defaultProps = {
  children: null,
  'aria-label': null,
  color: iconButtonColors.PRIMARY,
  size: iconButtonSizes.MEDIUM,
  disabled: false,
  onClick: null
};

export default IconButton;

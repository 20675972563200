import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {fetchFileProcessingGetFiles} from "../../services/FileProcessingService";
import {addLatestDateToData, getMergedDataWithFieldStepDisplaySpecifications} from "./utils/DataUtils";
import TabBody from "./utils/TabBody";

const FileProcessingApplication = ({searchParamRefresh, currentApplication, showCompletedFiles, handleKill, dataLoadErrorAlert, setIsRequestError, getAccessTokenSilently}) => {

    const [tableLoading, setTableLoading] = useState(true);
    const [resultData, setResultData] = useState([])

    const getRefreshedResults = () => {
        searchParamRefresh(currentApplication, showCompletedFiles)
    }

    const getCompletedFilesResults = () => {
        searchParamRefresh(currentApplication, !showCompletedFiles)
    }

    const handleKillApplication = async (logId) => {
        await handleKill(logId, currentApplication)
    }

    useEffect(() => {

        setTableLoading(true)

        const makeRequest = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                const fileProcessingGetFilesRequest = {includeCompleted: showCompletedFiles, fileProcessingApplication: currentApplication}
                const result = await fetchFileProcessingGetFiles(fileProcessingGetFilesRequest, accessToken);
                //Could be causing page to render more than needed. Which can cause more request to be executed than needed.
                setIsRequestError(null)
                setResultData(result)
            } catch (err) {
                setIsRequestError(dataLoadErrorAlert(err?.response?.headers?.get('Request-Id')));
            } finally {
                setTableLoading(false)
            }
        }

        if (resultData.length === 0) {
            makeRequest()
        }

    }, [])

    const dataMergedWithFieldStepDisplaySpecifications = getMergedDataWithFieldStepDisplaySpecifications(resultData)
    const finalData = addLatestDateToData(resultData, dataMergedWithFieldStepDisplaySpecifications)


    return(
        <>
            <TabBody tableLoading={tableLoading} finalData={finalData} dataMergedWithFieldStepDisplaySpecifications={dataMergedWithFieldStepDisplaySpecifications} handleKill={handleKillApplication} showCompletedFiles={showCompletedFiles} getCompletedFilesResults={getCompletedFilesResults} getRefreshedResults={getRefreshedResults}/>
        </>
    );
}
FileProcessingApplication.propTypes ={
    searchParamRefresh: PropTypes.func,
    currentApplication: PropTypes.string,
    showCompletedFiles: PropTypes.bool,
    fileSelectedRef: PropTypes.object,
    handleKill: PropTypes.func,
    dataLoadErrorAlert: PropTypes.func,
    setIsRequestError: PropTypes.func,
    getAccessTokenSilently: PropTypes.func
};

export default FileProcessingApplication;
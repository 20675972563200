import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  IconButton,
  InputAdornment as MuiInputAdornment,
  TextField as MuiTextField,
  styled
} from '@mui/material';
import { red } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';
import { useRef } from 'react';

export const textFieldSizes = {
  MEDIUM: 'medium',
  SMALL: 'small'
};

export const textFieldVariants = {
  STANDARD: 'standard',
  FILLED: 'filled',
  OUTLINED: 'outlined'
};

const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: prop => prop !== 'error'
})(({ error }) => {
  return {
    '& .MuiInputBase-root': {
      '&.Mui-disabled': {
        '&:before': {
          borderBottomWidth: '2px'
        }
      }
    },
    '.MuiInputBase-root:after': {
      borderBottomWidth: '2px',
      ...(error && {
        borderBottomColor: red['900']
      })
    },
    ...(error && {
      '& .MuiFormLabel-root, &.Mui-focused .MuiFormLabel-root': {
        color: red['900']
      },
      '& MuiFormHelperText-root': {
        color: red['900']
      }
    })
  };
});

const TextField = ({
  type,
  label,
  disabled,
  helperText,
  prefix,
  suffix,
  size,
  error,
  onChange,
  value,
  InputProps,
  variant,
  ...overrides
}) => {
  const inputRef = useRef(/** @type {HTMLInputElement | null} */ (null));

  const hasPrefix = !isEmpty(prefix) && !isNil(prefix);
  const hasSuffix = !isEmpty(suffix) && !isNil(suffix);

  const isSearchType = type === 'search';
  const fieldSize = size === textFieldSizes.SMALL ? 'small' : '';

  const clearInput = () => {
    if (isSearchType) {
      const input = inputRef.current?.querySelector('input');

      if (input) {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value'
        )?.set;

        if (nativeInputValueSetter) {
          nativeInputValueSetter.call(input, '');
          const event = new Event('input', { bubbles: true });

          input.dispatchEvent(event);
        }
      }
    }
  };

  return (
    <StyledTextField
      label={label}
      type={isSearchType ? 'text' : type}
      value={value}
      size={fieldSize}
      error={error}
      color={error ? '' : 'primary'}
      variant={variant}
      disabled={disabled}
      onChange={onChange}
      helperText={helperText}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        ref: inputRef,
        ...((hasPrefix || isSearchType) && {
          startAdornment: (
            <MuiInputAdornment position="start">
              {hasPrefix ? prefix : <SearchIcon />}
            </MuiInputAdornment>
          )
        }),
        ...((hasSuffix || isSearchType) && {
          endAdornment: hasSuffix ? (
            <MuiInputAdornment position="end">
              {hasSuffix ? suffix : <ClearIcon fontSize="small" />}
            </MuiInputAdornment>
          ) : (
            <IconButton onClick={clearInput}>
              <ClearIcon fontSize="small" />
            </IconButton>
          )
        }),
        ...InputProps
      }}
      {...overrides}
    />
  );
};

TextField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired
  ]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(Object.values(textFieldSizes)),
  InputProps: PropTypes.object,
  variant: PropTypes.oneOf(Object.values(textFieldVariants))
};

TextField.defaultProps = {
  size: textFieldSizes.MEDIUM,
  variant: textFieldVariants.FILLED
};

export default TextField;

import React from 'react';
import PropTypes from 'prop-types';

import {
  Timeline as MuiTimeline,
  TimelineItem,
  TimelineDot,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent
} from '@mui/lab';

import { isEmpty } from 'ramda';

export const timelineColors = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
  MAIN: 'gray'
};

export const timelineVariants = {
  FILLED: 'filled',
  OUTLINED: 'outlined'
};

export const timelinePositions = {
  ALTERNATE: 'alternate',
  LEFT: 'left',
  RIGHT: 'right'
};

const Timeline = ({ items, position, variant, color, ...overrides }) => {
  return (
    <MuiTimeline position={position} {...overrides}>
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;
        const hasOppositeContent = !isEmpty(item?.oppositeContent);

        return (
          <TimelineItem key={index}>
            {hasOppositeContent && (
              <TimelineOppositeContent color="text.secondary">
                {item?.oppositeContent}
              </TimelineOppositeContent>
            )}
            <TimelineSeparator>
              <TimelineDot
                color={color === 'gray' ? 'grey' : color}
                variant={variant}
              />
              {!isLastItem && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>{item.content}</TimelineContent>
          </TimelineItem>
        );
      })}
    </MuiTimeline>
  );
};

Timeline.propTypes = {
  variant: PropTypes.oneOf(Object.values(timelineVariants)),
  position: PropTypes.oneOf(Object.values(timelinePositions)),
  color: PropTypes.oneOf(Object.values(timelineColors)),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
      oppositeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    })
  ).isRequired
};

Timeline.defaultProps = {
  variant: timelineVariants.FILLED,
  position: timelinePositions.LEFT,
  color: timelineColors.MAIN
};

export default Timeline;

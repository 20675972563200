import {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ParsedAddress, ParsedAddressFieldDescriptions} from "./ParsedAddress";
import {ReturnedData, ReturnedDataFieldDescriptions} from "./ReturnedData";
import Button from "@mui/material/Button";
import {Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import {AdvancedAddressFieldDescriptions, AdvancedAddressInformation} from "./AdvancedAddressInformation";
import {areObjectStringValuesEmpty} from "../../../util/FormUtil";
import {parseJSONWithFallback} from "../../../util/JsonUtils";
import {IsRequestErrorPropTypes, SearchResultsPropTypes} from "../utils/PropTypes";


/**
 * The OutputAccordion component is used for displaying the data received in the searchResults object in three separate groupings:
 * - ReturnedData
 * - ParsedAddress
 * - ReturnedData
 *
 * @param {object} props - The properties passed to the component.
 * @param {object} props.searchResults - The searchResults data object that contains the data from VerifyAQ request.
 * @param {object} props.isRequestError - An isRequestError object that will contain an object if there was an error making a request to VerifyAQ.
 * @returns {Element} React JSX element representing the OutputAccordion component.
 */
const OutputDetails = ({searchResults, isRequestError}) => {

    const defaultPanelState = ["returnedDataPanel"];
    const addressVerificationOutputAccordionState = localStorage.getItem('address-verification-output-accordion-state');
    const [expandedPanels, setExpandedPanels] = useState(addressVerificationOutputAccordionState ? parseJSONWithFallback(addressVerificationOutputAccordionState, defaultPanelState) : defaultPanelState);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const  activeDialogContent = useRef("returnedData");

    const currentDialogContent = {
        returnedData: ReturnedDataFieldDescriptions,
        parsedAddress: ParsedAddressFieldDescriptions,
        advancedAddressInformation: AdvancedAddressFieldDescriptions
    };

    useEffect(() => {
        const storedState = localStorage.getItem('address-verification-output-accordion-state');
        if (storedState && parseJSONWithFallback(storedState, defaultPanelState ).length) {
            setExpandedPanels(parseJSONWithFallback(storedState, defaultPanelState ));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('address-verification-output-accordion-state', JSON.stringify(expandedPanels));
    }, [expandedPanels]);

    const handleOpenDialogReturnedData = (event) => {
        event.stopPropagation();
        activeDialogContent.current = "returnedData";
        setDialogOpen(true);
    };

    const handleOpenDialogParsedAddress = (event) => {
        event.stopPropagation();
        activeDialogContent.current = "parsedAddress";
        setDialogOpen(true);
    };

    const handleOpenDialogAdvancedAddressInformation = (event) => {
        event.stopPropagation();
        activeDialogContent.current = "advancedAddressInformation";
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleAccordionChange = (panel) => (_event, isExpanded) => {
        setExpandedPanels((prevExpandedPanels) =>
            isExpanded
                ? [...prevExpandedPanels, panel]
                : prevExpandedPanels.filter((item) => item !== panel)
        );

        localStorage.setItem('address-verification-output-accordion-state', JSON.stringify(expandedPanels));
    };

     if (!areObjectStringValuesEmpty(searchResults) || (isRequestError !== null && isRequestError.title !== undefined && isRequestError.message !== undefined)) {
        return (
            <div style={{flexGrow: 1}} data-testid="output-details">
                <Box>
                    <Accordion expanded={expandedPanels.includes('returnedDataPanel')}
                               onChange={handleAccordionChange('returnedDataPanel')} elevation={3}>
                        <AccordionSummary
                            data-testid="returned-data-accordion-summary"
                            expandIcon={<ExpandMoreIcon sx={{color: "grey.main"}}/>}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{bgcolor: "primary.dark", borderRadius: "4px 4px 0 0"}}>
                            <Typography variant={"h6"} sx={{color: "primary.contrastText", flexGrow: 1}}>Returned Data</Typography>
                            <Button variant="text" sx={{color: "primary.light"}} onClick={handleOpenDialogReturnedData}>Field Descriptions</Button>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ReturnedData searchResults={searchResults} isRequestError={isRequestError}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedPanels.includes('parsedAddressPanel')}
                               onChange={handleAccordionChange('parsedAddressPanel')} elevation={2}>
                        <AccordionSummary
                            data-testid="parsed-address-accordion-summary"
                            expandIcon={<ExpandMoreIcon sx={{color: "grey.main"}}/>}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            sx={{bgcolor: "primary.dark", borderRadius: "0 0 0 0"}}>
                            <Typography variant={"h6"} sx={{color: "primary.contrastText", flexGrow: 1}}>Parsed Address</Typography>
                            <Button variant="text" sx={{color: "primary.light"}} onClick={handleOpenDialogParsedAddress}>Field Descriptions</Button>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ParsedAddress
                                searchResults={searchResults}
                                isRequestError={isRequestError}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedPanels.includes('advancedAddressPanel')}
                               onChange={handleAccordionChange('advancedAddressPanel')} elevation={2}>
                        <AccordionSummary
                            data-testid="advanced-address-accordion-summary"
                            expandIcon={<ExpandMoreIcon sx={{color: "grey.main"}}/>}
                            aria-controls="panel3-content"
                            id="panel3-header"
                            sx={{bgcolor: "primary.dark", borderRadius: "0 0 4px 4px"}}>
                            <Typography variant={"h6"} sx={{color: "primary.contrastText", flexGrow: 1}}>Advanced Address
                                Information</Typography>
                            <Button type="button" variant="text" sx={{color: "primary.light"}} onClick={handleOpenDialogAdvancedAddressInformation}>Field Descriptions</Button>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AdvancedAddressInformation
                                searchResults={searchResults}
                                isRequestError={isRequestError}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Dialog fullWidth maxWidth={"lg"} open={isDialogOpen} onClose={handleCloseDialog}>
                    <DialogContent>
                        <DialogContentText>{currentDialogContent[activeDialogContent.current]}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    } else {
        return null
    }
}

OutputDetails.propTypes = {
    searchResults: SearchResultsPropTypes,
    isRequestError: IsRequestErrorPropTypes,
};

export default OutputDetails;
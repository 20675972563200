import { useTheme } from '@emotion/react';
import { ThemeProvider, alpha, styled } from '@mui/system';
import PropTypes from 'prop-types';
import * as React from 'react';

const TitleStyle = styled('i')(({ theme }) => ({
    color: alpha(theme.palette.text.secondary, 0.5)
}));

/**
 * Italic html component with custom color.
 *  
 * @param {object} props properties
 * @param {Element} props.children the contents of the tab
 * @returns {Element} styled component
 */
export default function BgTitleStyle(props) {
    const theme = useTheme();
    const { children } = props;
    return (
        <ThemeProvider theme={theme}>
            <TitleStyle>{children}</TitleStyle>
        </ThemeProvider>
    );
}

BgTitleStyle.propTypes = {
    children: PropTypes.node
    
  };

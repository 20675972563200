import React from 'react';
import PropTypes from 'prop-types';
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';

export const MenuItem = ({ children, onClick, ...overrides }) => (
  <MuiMenuItem onClick={onClick} {...overrides}>
    {children}
  </MuiMenuItem>
);

MenuItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
};

const Menu = ({
  children,
  id,
  open,
  anchorEl,
  onClose,
  listLabelledby,
  MenuListProps,
  ...overrides
}) => (
  <MuiMenu
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    MenuListProps={{
      'aria-labelledby': listLabelledby,
      ...MenuListProps
    }}
    {...overrides}
  >
    {children}
  </MuiMenu>
);

Menu.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.node,
  onClose: PropTypes.func,
  listLabelledby: PropTypes.string,
  MenuListProps: PropTypes.shape()
};

export default Menu;

import dayjs from 'dayjs';

/**
 * @param {{ version: Reseller.Version, field: Exclude<keyof Reseller.Version, 'id' | 'name'>; value: string; isRecalculationEnabled: boolean; inHomeWindowRange: Reseller.InHomeWindowRange }} params - params
 * @returns {{ changes: { [key: typeof field]: string }, isRecalculationEnabled: boolean }} - changes
 */
export function recalculateVersionDates(params) {
  const { version, field, value, isRecalculationEnabled, inHomeWindowRange } = params;

  /** @type {{ [key in typeof field]: string }} */
  const changes = {};
  /** @type {boolean} */
  let newIsRecalculationEnabled = isRecalculationEnabled;

  if (isRecalculationEnabled && field === 'firstMailDate') {
    changes['inHomeStartDate'] = dayjs(value).add(inHomeWindowRange.inHomeStart, 'day').format('YYYY-MM-DD');
  }
  if (isRecalculationEnabled && field === 'lastMailDate') {
    changes['inHomeEndDate'] = dayjs(value).add(inHomeWindowRange.inHomeEnd, 'day').format('YYYY-MM-DD');
  }
  const inHomeStartDiffInDays = dayjs(value).diff(dayjs(version.firstMailDate), 'day');
  if (field === 'inHomeStartDate' && inHomeStartDiffInDays !== inHomeWindowRange.inHomeStart) {
    newIsRecalculationEnabled = false;
  }
  const inHomeEndDiffInDays = dayjs(value).diff(dayjs(version.lastMailDate), 'day');
  if (field === 'inHomeEndDate' && inHomeEndDiffInDays !== inHomeWindowRange.inHomeEnd) {
    newIsRecalculationEnabled = false;
  }

  return { changes, isRecalculationEnabled: newIsRecalculationEnabled };
}

/**
 * @param {Reseller.Version} version - version
 * @returns {DataGridError<Reseller.Version>[]} - error
 */
export function validateVersionDates(version) {
  const { id, firstMailDate, lastMailDate, inHomeStartDate, inHomeEndDate } = version;
  return /** @type {DataGridError<Reseller.Version>[]} */ (
    [
      !firstMailDate && { id, field: 'firstMailDate', error: 'First mail date is required' },
      !lastMailDate && { id, field: 'lastMailDate', error: 'Last mail date is required' },
      !inHomeStartDate && { id, field: 'inHomeStartDate', error: 'In-home start date is required' },
      !inHomeEndDate && { id, field: 'inHomeEndDate', error: 'In-home end date is required' },
      dayjs(firstMailDate).isAfter(dayjs(lastMailDate)) && { id, field: 'lastMailDate', error: 'Last mail date must be equal to or later than first mail date' },
      dayjs(inHomeStartDate).isBefore(dayjs(firstMailDate)) && { id, field: 'inHomeStartDate', error: 'In-home start date must be equal to or later than first mail date' },
      dayjs(inHomeEndDate).isBefore(dayjs(inHomeStartDate)) && { id, field: 'inHomeEndDate', error: 'In-home end date must be equal to or later than in-home start date' },
      dayjs(inHomeEndDate).isBefore(dayjs(lastMailDate)) && { id, field: 'inHomeEndDate', error: 'In-home end date must be equal to or later than last mail date' }
    ].filter(Boolean)
  );
}

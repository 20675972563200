import { createStore, sample } from 'effector';
import { isEqual } from 'lodash';

/**
 * createFxStatuses - Factory function that creates a model for tracking multiple simultaneous calls to the same effect.
 *
 * @template T - effect result type
 * @param {import('effector').Effect<T>} effect - effect to track
 * @returns {{
 *  $statuses: import('effector').Store<T[]>,
 *  watchParams: (params: T) => import('effector').Store<boolean>
 * }} - store
 */
export default function createFxStatuses(effect) {
  const $statuses = createStore(/** @type {T[]} */ ([]));

  sample({
    source: $statuses,
    clock: effect,
    fn: (statuses, params) => [...statuses, params],
    target: $statuses
  });

  sample({
    source: $statuses,
    clock: effect.finally,
    fn: (statuses, { params }) => statuses.filter((_, index) => index !== statuses.findIndex(stored => isEqual(stored, params))),
    target: $statuses
  });

  /** @type {(params: T) => import('effector').Store<boolean>} */
  const watchParams = params => {
    return $statuses.map(statuses => statuses.some(stored => isEqual(stored, params)));
  };

  return {
    $statuses: $statuses.map(d => d),
    watchParams
  };
}

import { useEffect, useState } from 'react';

/**
 * useDebounceValue hook
 *
 * @template T
 * @param {T} inputValue - input value
 * @param {number} delay - delay
 * @returns {T} debounced input value
 */
export default function useDebounceValue(inputValue, delay = 500) {
  const [debouncedInputValue, setDebouncedInputValue] = useState(/** @type {T} */(inputValue));

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, delay);
    return () => clearTimeout(delayInputTimeoutId);
  }, [inputValue, delay]);

  return debouncedInputValue;
}

import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

export const nativePickerTypes = {
  TIME: 'time',
  DATE: 'date',
  DATETIME: 'datetime-local'
};

const NativePicker = ({
  type,
  InputLabelProps,
  defaultValue,
  ...overrides
}) => {
  return (
    <TextField
      id="date"
      label="native picker"
      type={type}
      defaultValue={defaultValue}
      InputLabelProps={InputLabelProps}
      {...overrides}
    />
  );
};

NativePicker.propTypes = {
  type: PropTypes.oneOf(Object.values(nativePickerTypes)),
  defaultValue: PropTypes.string,
  InputLabelProps: PropTypes.node
};

export default NativePicker;

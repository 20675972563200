import { DataGridPro, gridClasses, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const createColumn = (field, headerName, flex, type) => {
  const column = {
    field,
    headerName,
    flex
  };

  if (type) {
    column.type = type;
  }

  return column;
};

const CustomToolbar = ({ clientName }) => {
  return (
    <GridToolbarContainer>
      <Box sx={{ flexGrow: 1 }} />
      <div style={{ paddingRight: '1px' }}>
        <GridToolbarExport
          csvOptions={{
            fileName: clientName
          }}
          slotProps={{
            tooltip: { title: 'Export data' },
            button: { variant: 'outlined' }
          }}
        />
      </div>
    </GridToolbarContainer>
  );
};
CustomToolbar.propTypes = {
  clientName: PropTypes.string
};

const ClientTable = ({ clientName, loading, rows }) => {
  const getRowId = row => {
    return row.childId;
  };

  const columns = [
    createColumn('lobName', 'LOB Name', 1),
    createColumn('childId', 'Child ID', 1),
    createColumn('ytdJobQuantity', 'YTD Job Quantity', 1, 'number'),
    createColumn('ytdRecordCount', 'YTD Record Count', 1, 'number')
  ];

  return (
    <DataGridPro
      initialState={{
        pagination: { paginationModel: { pageSize: 5 } }
      }}
      sx={{
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none'
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: 'none'
        },
        '& .MuiDataGrid-cell': {
          alignContent: 'center'
        }
      }}
      slots={{ toolbar: CustomToolbar }}
      slotProps={{ toolbar: { clientName } }}
      loading={loading}
      columns={columns}
      rows={rows}
      pageSizeOptions={[5, 10, 25]}
      getRowId={getRowId}
      pagination
      disableMultipleRowSelection
      autoHeight
      disableRowSelectionOnClick
    ></DataGridPro>
  );
};
ClientTable.propTypes = {
  clientName: PropTypes.string,
  loading: PropTypes.bool,
  rows: PropTypes.array
};

export default ClientTable;

import OutputDetailsDataTable from "../utils/OutputDetailsDataTable";
import {IsRequestErrorPropTypes, SearchResultsPropTypes} from "../../utils/PropTypes";

const parsedAddressSpecifications = [
    {name: 'House #:', jsonName: 'houseNumber', type: 'string'},
    {name: 'Pre Dir:', jsonName: 'preDirectional', type: 'string'},
    {name: 'Street Name:', jsonName: 'streetName', type: 'string'},
    {name: 'Suffix:', jsonName: 'streetSuffix', type: 'string'},
    {name: 'Post Dir:', jsonName: 'postDirectional', type: 'string'},
    {name: 'Unit Type:', jsonName: 'secondaryUnitDesignator', type: 'string'},
    {name: 'Unit #:', jsonName: 'unitNumber', type: 'string'},
    {name: 'PMB:', jsonName: 'pmb', type: 'string'},
];

/**
 * ParsedAddress component is used for grouping and displaying the certain data from the
 * searchResults object in a table. If there is a request error then it will display a table skeleton.
 *
 * @param {object} props - The properties passed to the component.
 * @param {object} props.searchResults - The searchResults data object that contains the data from VerifyAQ request.
 * @param {object} props.isRequestError - An isRequestError object that will contain an object if there was an error making a request to VerifyAQ.
 * @returns {Element} React JSX element representing the ParsedAddress component.
 */
const ParsedAddress = ({searchResults, isRequestError}) => {
    return (
        <OutputDetailsDataTable searchResults={searchResults} specifications={parsedAddressSpecifications} isRequestError={isRequestError}/>
    )
}
ParsedAddress.propTypes = {
    searchResults: SearchResultsPropTypes,
    isRequestError: IsRequestErrorPropTypes,
};

export default ParsedAddress
import { Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import { Fragment } from 'react';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import ManageUserRolesModal from '../../../components/ManageUserRolesModal/ManageUserRolesModal';
import ManageUsersAssignedToRoleModal from '../../../components/ManageUsersAssignedToRoleModal/ManageUsersAssignedToRoleModal';
import SnackbarRoot from '../../../components/SnackbarRoot/SnackbarRoot';
import { $$tutorialVideos } from '../../../components/TutorialVideosModal/model';
import TutorialVideoButton from '../../../components/TutorialVideosModal/TutorialVideoButton';
import { $$adminUsersPage } from './model';

/**
 * Component for displaying the form to manage external users.
 *
 * @returns {React.ReactNode} AdminUsers component
 */
export default function AdminUsersPage() {
  const openedTabNumber = useUnit($$adminUsersPage.$openedTab);

  return (
    <>
      <ConfirmationDialog />
      <ManageUserRolesModal />
      <ManageUsersAssignedToRoleModal />
      <TutorialVideoButton url={$$tutorialVideos.links['ST_ADMIN:ROLES_AND_PERMISSIONS']} />

      <Container id="admin-users-container">
        <Stack sx={{ gap: 4, mb: 3 }}>
          <Typography variant="h5" color="text.primary">
            Roles and Permissions
          </Typography>
          <Typography variant="subtitle2" color="text.primary" sx={{ mb: 2, display: openedTabNumber === 2 ? 'none' : 'flex' }}>
            Only users that have logged into the Portal will be visible in this page.
          </Typography>
        </Stack>
        <Tabs value={openedTabNumber} onChange={(_, index) => $$adminUsersPage.tabSelectedByIndex(index)} aria-label="basic tabs example" sx={{ mb: 4 }}>
          {$$adminUsersPage.tabs.map(tab => (
            <Tab key={tab.name} label={tab.title} sx={{ textTransform: 'none' }} />
          ))}
        </Tabs>
        {$$adminUsersPage.tabs.map((tab, index) => (
          <Fragment key={tab.name}>{index === openedTabNumber && tab.component ? tab.component : null}</Fragment>
        ))}
        <SnackbarRoot snackbarProps={{ autoHideDuration: 3000 }} />
      </Container>
    </>
  );
}

import { createEffect, createStore, sample } from 'effector';
import {ghApi, HTTPResponseError} from '../DataService';

const $contactInfo = createStore(/** @type {ContactInfo | null} */ (null));

/** @type {import('effector').Effect<void, ContactInfo>} */
const getContactInfoFx = createEffect(async () => {
    return ghApi.get('/contact').then(response => response.data);
});

export const sendEmail = async ({ firstName, lastName, email, phone, subject, comments }) => {
    const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        subject: subject,
        comments: comments
    };

    return ghApi
        .post('/contact', data)
        .then(() => true)
        .catch(response => {
            throw new HTTPResponseError(response);
        });
};

sample({
    clock: getContactInfoFx.doneData,
    target: $contactInfo
});

export const $$contactService = {
    $contactInfo,

    getContactInfoFx
};

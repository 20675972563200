import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const DEFAULT_ERROR = 'Unknown login error. Please contact GrayHair support for further assistance.';
const ERRORS = {
  USER_NOT_FOUND: 'We could not find your user in our database. Please contact GrayHair support for further assistance.',
  USER_NOT_PROVISIONED: 'We could not find your user in our database and we could not create it. Please contact GrayHair support for further assistance.',
  NO_USERID: <span>GrayHair did not properly receive the login credentials from your organization. Please contact your company&apos;s technical support team for further assistance. You may provide them with the following error: The string in http parameter SAMLResponse did not contain an email address.</span>,
  BAD_CONFIG: 'SSO configuration error. Please contact GrayHair support for further assistance.',

};

const Callback = () => {
  const { error } = useAuth0();

  if (error) {
    const message = Object.hasOwn(ERRORS, error.message) ? ERRORS[error.message] : DEFAULT_ERROR;

    return (
      <Container>
        <Typography variant="h3" color="text.primary">Error</Typography>
        <Typography color="text.primary">{message}</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography color="text.primary">Login successful. Returning to your page.</Typography>
    </Container>
  );
};

export default Callback;
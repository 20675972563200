import React from 'react';
import PropTypes from 'prop-types';
import { Divider as MuiDivider, Typography } from '@mui/material';

import Chip from '~/components/Chip';

const Divider = ({
  children,
  component,
  textAlign,
  chip,
  orientation,
  ...overrides
}) => (
  <MuiDivider
    component={component}
    orientation={orientation}
    textAlign={textAlign}
    {...overrides}
  >
    {children &&
      (chip ? (
        <Chip label={children} />
      ) : (
        <Typography variant="overline">{children}</Typography>
      ))}
  </MuiDivider>
);

Divider.propTypes = {
  children: PropTypes.string,
  component: PropTypes.elementType,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  textAlign: PropTypes.oneOf(['left', 'right']),
  chip: PropTypes.bool
};

Divider.defaultProps = {
  children: undefined,
  orientation: undefined,
  textAlign: undefined,
  chip: false
};

export default Divider;

import { createEvent, createStore, sample } from 'effector';
import { persist } from 'effector-storage/query';
import { createElement } from 'react';
import ManageRolesTab from '../../../components/ManageRolesTab/ManageRolesTab';
import ManageUsersTab from '../../../components/ManageUsersTab/ManageUsersTab';
import MyRolesTab from '../../../components/MyRolesTab/MyRolesTab';

/** @typedef {'manage-users' | 'manage-roles' | 'my-roles'} TabName */

const tabs = [
  { name: /** @type {TabName} */ ('manage-users'), title: 'Manage Users', component: createElement(ManageUsersTab) },
  { name: /** @type {TabName} */ ('manage-roles'), title: 'Manage Roles', component: createElement(ManageRolesTab) },
  { name: /** @type {TabName} */ ('my-roles'), title: 'My Roles', component: createElement(MyRolesTab) }
];

/** @type {import('effector').EventCallable<number>} */
const tabSelectedByIndex = createEvent();

const $openedTab = createStore(/** @type {TabName} */ ('manage-users'));

persist({ store: $openedTab, key: 'tab' });

sample({
  clock: tabSelectedByIndex,
  fn: index => tabs[index].name,
  target: $openedTab
});

export const $$adminUsersPage = {
  $openedTab: $openedTab.map(tab => tabs.map(({ name }) => name).indexOf(tab)),

  tabSelectedByIndex,

  tabs
};

import {fieldStepDisplaySpecifications} from "./AdditionalDatesDisplay";

/**
 * Function takes the data and strips it to only include the necessary fields so that the AdditionalDatesDisplay can
 * easily display the data organized. It returns a new Array of files in which each file has two fields: logId and
 * stepArray.
 *
 * The logId field is left to identify the file, and the stepArray field is re-structured (by mapping with the predefined
 * fieldStepDisplaySpecifications structure) so that the steps are organized for easy display.
 *
 * @param {Array} data - Array of File Data returned from API call.
 * @returns {Array} Array of File Data with only the logId and StepArray(organized) fields for each file.
 */
export function getMergedDataWithFieldStepDisplaySpecifications(data) {
    let mergedData;
    if (data.length > 0){
        mergedData = data.map(dataPoint => {

            const mergedArray = Object.values(fieldStepDisplaySpecifications).sort((a, b) => a.order - b.order).map(({name, steps}) => {
                let hasValue = false;
                const updatedSteps = steps.map(step => {
                    const match = dataPoint.steps.filter(subStep => subStep.name == step.dbName)
                    const value = match.length && (match[0].timeStamp !== null && match[0].timeStamp !== '') ? match[0].timeStamp : '';
                    if (value !== '') hasValue = true;

                    return {
                        ...step,
                        value: value
                    };
                });
                return {stepName: name, subSteps: updatedSteps, hasNonEmptyValue: hasValue};
            });
            return {logId: dataPoint.logId, stepArray: mergedArray};
        })
    } else {
        mergedData = []
    }
    return mergedData
}

/**
 * Function takes the data Array and adds a new property to each file object in the data Array that signifies the
 * latest subStep so that we can keep track of which step each file is in.
 *
 * We use the mergedData Array that contains each file but with the steps structured/organized in order to identify
 * the latest subStep.
 *
 * @param {Array} data - Array of File Data returned from API call.
 * @param {Array} mergedData - Array of File Data returned from getMergedDataWithFieldStepDisplaySpecifications().
 * @returns {Array} Array of File Data with all fields for each file in addition to a latestSubStep field.
 */
export function addLatestDateToData(data, mergedData) {
    let finalData;
    if (data.length > 0 && mergedData.length > 0){
        finalData = data.map((dataPoint, index) => {
            let latestSubStep = null;
            mergedData[index].stepArray.forEach(step => {
                step.subSteps.forEach(subStep => {
                    if (latestSubStep === null || (subStep.value && subStep.value > latestSubStep.value)) {
                        latestSubStep = subStep;
                    }
                })
            })
            return {...dataPoint, latestSubStep: latestSubStep}
        })
    } else {
        finalData = []
    }
    return finalData
}
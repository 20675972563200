/* eslint-disable jsdoc/check-tag-names */
import { createEffect, createStore, sample } from 'effector';
import { ghApi } from '../DataService';

const $roles = createStore(/** @type {Roles.Role[]} */ ([]));

/** @type {import('effector').Effect<void, Roles.Role[]>} */
const getManageableRolesFx = createEffect(async () => {
  return ghApi.get('/users/roles').then(response => response.data);
});

/** @type {import('effector').Effect<{ roleId: Roles.Role['id']; size?: number; page?: number; }, PaginatedResponse<Users.User>>} */
const getUsersForRole = createEffect(async ({ roleId, ...params }) => {
  return ghApi.get(`/users/roles/${roleId}/users`, { params }).then(response => response.data);
});

/** @type {import('effector').Effect<{ roleId: Roles.Role['id']; userIds: Users.User['userId'][] }, Users.User[]>} */
const assignUserToRoleFx = createEffect(async ({ roleId, userIds }) => {
  return ghApi.patch(`/users/roles/${roleId}/users`, userIds).then(response => response.data);
});

/** @type {import('effector').Effect<{ roleId: Roles.Role['id']; userIds: Users.User['userId'][] }, Users.User[]>} */
const deleteUsersFromRoleFx = createEffect(async ({ roleId, userIds }) => {
  return ghApi.delete(`/users/roles/${roleId}/users`, { data: userIds }).then(response => response.data);
});

sample({
  clock: getManageableRolesFx.doneData,
  target: $roles
});

export const $$roles = {
  $roles,

  getManageableRolesFx,
  getUsersForRole,
  assignUserToRoleFx,
  deleteUsersFromRoleFx
};

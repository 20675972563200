import { useState, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { always } from 'ramda';
import { ExpandMore } from '@mui/icons-material';
import { requiredUnlessProp } from '~/util/propTypes';
import IconButton from '~/components/IconButton';
import {
  DataGridPro as MuiDataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridPagination,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridEditInputCell
} from '@mui/x-data-grid-pro';
import {
  Pagination as MuiPagination,
  TextField,
  Typography,
  Tooltip,
  tooltipClasses
} from '@mui/material';

export const DETAIL_PANEL_VARIANTS = {
  STANDARD: 'standard',
  ACTIONS: 'actions'
};

// This Tooltip needs to be defined in the component library in order to be used in the portal. If not, a MUI error
// is thrown and it seems it's because component library and the portal use different API references/instances and
// we need both the main component (DataGrid) and the linked components (Toolbar, DataGridCellErrorTooltip) to use
// the same API instance
export const DataGridCellErrorTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="bottom" arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.main
  }
}));

export function DataGridEditInputCell(props) {
  const { error } = props;

  return (
    <DataGridCellErrorTooltip open={Boolean(error)} title={error}>
      <GridEditInputCell {...props} />
    </DataGridCellErrorTooltip>
  );
}

DataGridEditInputCell.propTypes = {
  error: PropTypes.string
};

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const [goTo, setGoTo] = useState('');

  const handlePageChange = event => {
    if (event.target.value.trim().length === 0) {
      setGoTo('');
      return;
    }

    if (/^\d+$/.test(event.target.value)) {
      const pageNumber = parseInt(event.target.value, 10);

      setGoTo(event.target.value);
      onPageChange(event, pageNumber - 1);
    }
  };

  return (
    <>
      {pageCount > 5 && (
        <>
          <Typography
            variant="body2"
            sx={{ ml: 2, mr: 1 }}
            style={{ whiteSpace: 'nowrap' }}
          >
            Go to page
          </Typography>
          <TextField
            size="small"
            value={goTo}
            onChange={handlePageChange}
            inputProps={{ maxLength: 3 }}
            sx={{
              minWidth: '3em',
              maxWidth: '3em',
              '& .MuiInputBase-input': {
                px: 1,
                py: 0.5,
                fontSize: '1.3986rem'
              }
            }}
          />
        </>
      )}
      <MuiPagination
        color="primary"
        className={className}
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />
    </>
  );
}

Pagination.propTypes = {
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  className: PropTypes.string
};

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

// See https://mui.com/x/react-data-grid/master-detail/#customizing-the-detail-panel-toggle
const CustomDetailPanelToggle = ({ id, value: isExpanded }) => {
  const apiRef = useGridApiContext();

  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  const hasDetail = isValidElement(contentCache[id]);

  return (
    <IconButton
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <ExpandMore
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: theme =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest
            })
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
};

CustomDetailPanelToggle.propTypes = {
  id: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export function DefaultToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

const DataGrid = ({
  columns,
  rows,
  initialState,
  height,
  checkboxSelection,
  pagination,
  pageSizeOptions,
  defaultPageSize,
  getDetailPanelContent,
  getDetailPanelHeight,
  getDetailPanelVariant,
  apiRef,
  slots,
  slotProps,
  sx,
  ...overrides
}) => {
  const [pageSize, setPageSize] = useState(
    defaultPageSize
      ? defaultPageSize
      : (pageSizeOptions && pageSizeOptions[0]) || undefined
  );

  return (
    <div style={{ width: '100%', height }}>
      <MuiDataGrid
        columns={
          getDetailPanelContent &&
          getDetailPanelVariant === DETAIL_PANEL_VARIANTS.ACTIONS
            ? [
                ...columns,
                {
                  ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                  renderHeader: always('Actions'),
                  width: 90,
                  align: 'center',
                  // eslint-disable-next-line react/display-name, react/prop-types
                  renderCell: ({ id, value }) => (
                    <CustomDetailPanelToggle id={id} value={value} />
                  )
                }
              ]
            : columns
        }
        rows={rows}
        initialState={{
          ...(pagination &&
            defaultPageSize && {
              pagination: { paginationModel: { pageSize: defaultPageSize } }
            }),
          ...initialState
        }}
        checkboxSelection={checkboxSelection}
        pagination={pagination}
        pageSizeOptions={pageSizeOptions}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        apiRef={apiRef}
        slots={{
          ...(pagination && { pagination: CustomPagination }),
          ...slots
        }}
        slotProps={slotProps}
        sx={{
          '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox), & .MuiDataGrid-cell':
            {
              px: 2
            },
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: theme => theme?.palette?.misc?.dataGridHeader
          },
          ...sx
        }}
        {...overrides}
      />
    </div>
  );
};

DataGrid.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()),
  rows: PropTypes.arrayOf(PropTypes.shape()),
  initialState: PropTypes.shape(),
  height: requiredUnlessProp('autoHeight'),
  checkboxSelection: PropTypes.bool,
  pagination: PropTypes.bool,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  defaultPageSize: PropTypes.number,
  getDetailPanelContent: PropTypes.func,
  getDetailPanelHeight: PropTypes.func,
  getDetailPanelVariant: PropTypes.oneOf(Object.values(DETAIL_PANEL_VARIANTS)),
  apiRef: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  slots: PropTypes.shape(),
  slotProps: PropTypes.shape(),
  sx: PropTypes.shape()
};

DataGrid.defaultProps = {
  pageSizeOptions: [10, 25, 50, 100],
  defaultPageSize: 10
};

export default DataGrid;

import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, TextField, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import { $$manageUserRolesModal } from './model';

/**
 * RoleSelect component
 *
 * @returns {React.ReactNode} RoleSelect
 */
export default function RoleSelect() {
  const rolesOptions = useUnit($$manageUserRolesModal.$rolesOptions);
  const rolesToAdd = useUnit($$manageUserRolesModal.$rolesToAdd);
  const userRoles = useUnit($$manageUserRolesModal.$$rolesDataGrid.$rows);

  const optionsToShow = rolesOptions.filter(role => !userRoles.map(userRole => userRole.id).includes(role.id));

  const getInputLabelText = (selectedRoles, availableRoles) => {
    if (selectedRoles.length > 0) {
      return '';
    }
    return availableRoles.length > 0 ? 'Assign roles to user' : 'No roles available';
  }

  return (
    <>
      <Typography variant="body1" color="text.primary" sx={{ my: 2 }}>
        Select role to assign to this user
      </Typography>
      <FormControl fullWidth>
        <InputLabel shrink={false}>{getInputLabelText(rolesToAdd, optionsToShow)}</InputLabel>
        <TextField
          select
          label="Assign Roles"
          fullWidth
          InputLabelProps={{ shrink: true }}
          placeholder="Assign roles to user"
          disabled={optionsToShow.length === 0}
          SelectProps={{
            multiple: true,
            value: rolesToAdd,
            renderValue: selected => /** @type {typeof rolesToAdd} */ (selected).map(roleId => rolesOptions.find(role => role.id === roleId)?.name || '').join(', '),
            onChange: e => {
              $$manageUserRolesModal.rolesToAddChanged(/** @type {string[]} */ (e.target.value));
            }
          }}
        >
          {optionsToShow.length === 0 && (
            <MenuItem disabled>
              <ListItemText primary="No roles available" />
            </MenuItem>
          )}
          {optionsToShow.map(role => (
            <MenuItem key={role.id} value={role.id}>
              <Checkbox checked={[...rolesToAdd, ...userRoles.map(r => r.id)].includes(role.id)} />
              <ListItemText primary={role.name} />
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </>
  );
}

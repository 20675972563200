import { PlayCircleOutline } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { $$tutorialVideos } from './model';

type TutorialVideoButtonProps = {
  url: (typeof $$tutorialVideos.links)[keyof typeof $$tutorialVideos.links];
};

export default function TutorialVideoButton(props: TutorialVideoButtonProps) {
  const { url } = props;

  return (
    <Button
      color="secondary"
      variant="text"
      sx={{
        position: 'fixed',
        bottom: '4rem',
        right: '2rem',
        padding: '1rem',
        minWidth: 'unset',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
        textTransform: 'none'
      }}
      onClick={() => $$tutorialVideos.open({ url })}
    >
      <PlayCircleOutline sx={{ fontSize: '3rem' }} />
      <Typography variant="body2">Play Tutorial</Typography>
    </Button>
  );
}

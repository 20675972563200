import { useUnit } from 'effector-react';
import PropTypes from 'prop-types';
import React from 'react';
import CombinedThemeProvider from './CombinedThemeProvider';
import { $$theme } from './model';

/**
 * Theme provider for the application.
 *
 * @param {object} props Props.
 * @param {React.ReactNode} props.children Children.
 * @returns {React.ReactElement} Theme provider.
 */
export default function AppThemeProvider(props) {
  const { children } = props;

  const palette = useUnit($$theme.$palette);

  return <CombinedThemeProvider palette={palette}>{children}</CombinedThemeProvider>;
}

AppThemeProvider.displayName = 'AppThemeProvider';

AppThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

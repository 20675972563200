import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { InvertedThemeProvider } from '~/util/theme';
import {
  Snackbar as MuiSnackbar,
  SnackbarContent as MuiSnackbarContent
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import IconButton from '~/components/IconButton';

export const snackbarAnchorOrigins = {
  vertical: {
    TOP: 'top',
    BOTTOM: 'bottom'
  },
  horizontal: {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right'
  }
};

const Snackbar = ({
  open: openProp,
  message,
  anchorOrigin,
  action,
  onClose,
  withClose,
  static: staticProp,
  sx,
  ...overrides
}) => {
  const Component = staticProp ? MuiSnackbarContent : MuiSnackbar;

  const [open, setOpen] = useState(openProp);
  const handleCloseBtnClick = () => {
    if (onClose) {
      onClose();
    }

    setOpen(false);
  };

  const handleClose = (event, reason) => {
    if (onClose) {
      onClose(event, reason);
    }

    if (!withClose || reason !== 'clickaway') {
      setOpen(false);
    }
  };

  useEffect(() => setOpen(openProp), [openProp]);

  return (
    <Component
      open={staticProp || open}
      message={message}
      onClose={!staticProp && handleClose}
      anchorOrigin={!staticProp && anchorOrigin}
      sx={{
        '& .MuiSnackbarContent-root': {
          bgcolor: 'misc.backgroundSnackbar'
        },
        ...sx
      }}
      action={
        action || withClose ? (
          <>
            <InvertedThemeProvider>{action}</InvertedThemeProvider>
            {withClose && (
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={handleCloseBtnClick}
                data-testid="snackbar-close"
              >
                <CloseIcon />
              </IconButton>
            )}
          </>
        ) : undefined
      }
      {...overrides}
    />
  );
};

Snackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(Object.values(snackbarAnchorOrigins.vertical)),
    horizontal: PropTypes.oneOf(Object.values(snackbarAnchorOrigins.horizontal))
  }),
  action: PropTypes.node,
  onClose: PropTypes.func,
  withClose: PropTypes.bool,
  static: PropTypes.bool,
  sx: PropTypes.shape()
};

Snackbar.defaultProps = {
  withClose: false,
  static: false
};

export default Snackbar;

import React from 'react';
import PropTypes from "prop-types";
import { withIdleTimer, IdleTimerComponent } from 'react-idle-timer'

let dashboardSettings = {
    showLeftPane: false,
    showRightPane: false,
    showToolbar: false
};

export const loadEmbedSdk = async (url) => {
    if (document.querySelector(`script[script-name="sisenseEmbedSdk"]`)) {
        return Promise.resolve(); // already exists
    }
    const script = document.createElement('script');
    script.src = url;
    script.setAttribute('script-name', 'sisenseEmbedSdk');
    document.head.appendChild(script);
    return new Promise((resolve, reject) => {
        script.onload = resolve;
        script.onerror = reject;
    });
}

class BiDashboardEmbed extends IdleTimerComponent {
    constructor(props) {
        super(props);
        this.state = {
            embedSdk: undefined,
            sisenseFrame: undefined,
            currentDashboard: undefined,
            isDashboardEditable: false
        };
        this._dashboardLoaded = this._dashboardLoaded.bind(this);
        this._setDashboardSettingsFromProps = this._setDashboardSettingsFromProps.bind(this);
        this.sendUserIsActiveOnDashboard = this.sendUserIsActiveOnDashboard.bind(this);
        this.isInitialized = React.createRef(false);
    }

    openDashboard(dashboardId, editMode = false) {
        if (this.sisenseFrame && this.sisenseFrame.dashboard && this.sisenseFrame.dashboard.iframeReady) {
            this.sisenseFrame.dashboard.open(dashboardId, editMode);//.then(() => {
            //sisenseDashboard = this.state.sisenseFrame..dashboard
            //})
        }
    }

    sendUserIsActiveOnDashboard() {
        //Sent a message to all idle timer instances
        this.props.message("User active on Dashboards.", true);
    }

    //We capture sisense events to propagate to main idle timer in order to count as user being active 
    addDashboardEventListeners() {
        if (this.state.sisenseFrame && this.state.sisenseFrame.dashboard && this.state.sisenseFrame.dashboard.iframeReady) {
            this.state.sisenseFrame.widget.on(this.state.embedSdk.enums.WidgetEventType.LOADED, this.sendUserIsActiveOnDashboard);
            this.state.sisenseFrame.widget.on(this.state.embedSdk.enums.WidgetEventType.LOADED, this.sendUserIsActiveOnDashboard);
            this.state.sisenseFrame.dashboard.on(this.state.embedSdk.enums.DashboardEventType.LOADED, this.sendUserIsActiveOnDashboard);
            this.state.sisenseFrame.dashboard.on(this.state.embedSdk.enums.DashboardEventType.UNLOADED, this.sendUserIsActiveOnDashboard);
            this.state.sisenseFrame.dashboard.on(this.state.embedSdk.enums.DashboardEventType.FILTERS_CHANGED, this.sendUserIsActiveOnDashboard);
            this.state.sisenseFrame.dashboard.on(this.state.embedSdk.enums.DashboardEventType.REFRESHED, this.sendUserIsActiveOnDashboard);
            this.state.sisenseFrame.dashboard.on(this.state.embedSdk.enums.DashboardEventType.STYLE_CHANGED, this.sendUserIsActiveOnDashboard);
            this.state.sisenseFrame.dashboard.on(this.state.embedSdk.enums.DashboardEventType.WIDGET_ADDED, this.sendUserIsActiveOnDashboard);
            this.state.sisenseFrame.dashboard.on(this.state.embedSdk.enums.DashboardEventType.SIZECHANGED, this.sendUserIsActiveOnDashboard);
        }
    }

    toggleShowLeftPane(enabled) {
        dashboardSettings.showLeftPane = enabled;
        this.state.sisenseFrame.updateSettings(dashboardSettings);
    }

    toggleShowRightPane(enabled) {
        dashboardSettings.showRightPane = enabled;
        this.state.sisenseFrame.updateSettings(dashboardSettings);
    }

    toggleShowToolbar(enabled) {
        dashboardSettings.showToolbar = enabled;
        this.state.sisenseFrame.updateSettings(dashboardSettings);
    }

    setTheme(themeId) {
        if (this.state.sisenseFrame && this.state.sisenseFrame.app) {
            this.state.sisenseFrame.app.setTheme(themeId).then(function () {
                console.debug('New theme applied: ', themeId);
            });
        }
    }

    setFrameHeight(frameHeight, frameElement) {
        if (frameElement && frameHeight) {
            frameElement.getElementsByTagName('iframe')[0].style.height = frameHeight;
        }
    }

    setFrameWidth(frameWidth, frameElement) {
        if (frameElement && frameWidth) {
            frameElement.getElementsByTagName('iframe')[0].style.width = frameWidth;
        }
    }

    render() {
        return (
            <div
                ref={this.props.sisenseContainerElement}
                id={this.props.sisenseContainerElementId}
            >
            </div>
        );
    }

    _dashboardLoaded(args) {
        this.setState(prevState => ({
            ...prevState,
            currentDashboard: args.dashboard,
            isDashboardEditable: args.dashboard.userAuth.dashboards.toggle_edit_mode
        }));

        let frameHeight = this.props.frameHeight ? this.props.frameHeight : '85vh';
        let frameWidth = this.props.frameWidth ? this.props.frameWidth : '100%';

        let containerElement = this.props.sisenseContainerElement.current;
        if (containerElement && containerElement.getElementsByTagName('iframe')[0] && containerElement.getElementsByTagName('iframe')[0].style) {
            containerElement.getElementsByTagName('iframe')[0].style.height = frameHeight;
            containerElement.getElementsByTagName('iframe')[0].style.width = frameWidth;
        }

        // Call custom onLoaded handler passed into props
        if (this.props.onDashboardLoaded) {
            this.props.onDashboardLoaded(this, args);
        }
        this.addDashboardEventListeners();
    }

    _setDashboardSettingsFromProps() {
        this.state.sisenseFrame.updateSettings(dashboardSettings);
    }

    componentDidMount() {
        if (this.isInitialized.current) {
            return;
        } else {
            this.isInitialized.current = true;
        }
        // Include the SDK file from the Sisense Web Server
        // Remove trailing slash and add embedSdk
        const embedSdkUrl = this.props.serverUrl.replace(/\/$/, '') + '/js/frame.js';

        const setDashboardSetting = (prop, setting) => {
            dashboardSettings[prop] = typeof this.props[prop] === "undefined" ? setting : this.props[prop];
        };
        setDashboardSetting('showLeftPane', false);
        setDashboardSetting('showRightPane', false);
        setDashboardSetting('showToolbar', false);

        loadEmbedSdk(embedSdkUrl)
            .then(() => {
                const embedSdk = window['sisense.embed'];
                this.setState(prevState => ({
                    ...prevState,
                    embedSdk: embedSdk
                }));

                if (this.props.dashboardId === '' || !this.props.sisenseContainerElement.current) {
                    return;
                }

                let sisenseFrame = this.state.sisenseFrame;
                this.setState(prevState => ({
                    ...prevState,
                    sisenseFrame: sisenseFrame
                }));

                if (embedSdk && typeof sisenseFrame === "undefined") {
                    sisenseFrame = new embedSdk.SisenseFrame({
                        url: this.props.serverUrl,
                        dashboard: this.props.dashboardId,
                        settings: dashboardSettings,
                        theme: this.props.themeId,
                        volatile: this.props.volatile,
                    });

                    sisenseFrame._element.style.minHeight = '600px';
                    if (this.props.urlParams) {
                        sisenseFrame._element.src += '&' + this.props.urlParams;
                    }
                }

                if (sisenseFrame) {
                    sisenseFrame.render(this.props.sisenseContainerElement.current).then(() => {
                        if (sisenseFrame._state.dashboard !== this.props.dashboardId) {
                            this.openDashboard(this.props.dashboardId);
                        }
                        sisenseFrame.dashboard.on(embedSdk.enums.DashboardEventType.LOADED, this._dashboardLoaded);

                        // Workaround for https://sisenseglobal.atlassian.net/browse/SNS-63556
                        sisenseFrame.widget.on(embedSdk.enums.WidgetEventType.LOADED, function () {
                            sisenseFrame.updateSettings({ showToolbar: true, showLeftPane: true, showRightPane: true });
                        });
                        sisenseFrame.widget.on(embedSdk.enums.WidgetEventType.UNLOADED, this._setDashboardSettingsFromProps);
                    })
                }

                // When dashboard is first loaded, pass dashboard object into onDashboardFirstLoaded handler function, passed from parent via props
                if (this.props.onDashboardFirstLoaded) {
                    this.props.onDashboardFirstLoaded(sisenseFrame.dashboard)
                }
                
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.showLeftPane !== prevProps.showLeftPane) {
            this.toggleShowLeftPane(this.props.showLeftPane || false);
        }
        if (this.props.showRightPane !== prevProps.showRightPane) {
            this.toggleShowRightPane(this.props.showRightPane || false);
        }
        if (this.props.showToolbar !== prevProps.showToolbar) {
            this.toggleShowToolbar(this.props.showToolbar || false);
        }
        if (this.props.dashboardId !== prevProps.dashboardId || this.props.editMode !== prevProps.editMode) {
            this.openDashboard(this.props.dashboardId, this.props.editMode);
        }
        if (this.props.themeId !== prevProps.themeId) {
            this.setTheme(this.props.themeId || '');
        }
        if (this.props.volatile !== prevProps.volatile) {
            // Only possible to delete and recreate sisenseFrame??
            // Handle volatile change logic here
        }
        if (this.props.frameHeight !== prevProps.frameHeight) {
            if (this.props.sisenseContainerElement.current) {
                this.setFrameHeight(this.props.frameHeight || '', this.props.sisenseContainerElement.current);
            }
        }
        if (this.props.frameWidth !== prevProps.frameWidth) {
            if (this.props.sisenseContainerElement.current) {
                this.setFrameWidth(this.props.frameWidth || '', this.props.sisenseContainerElement.current);
            }
        }
    }

    componentWillUnmount() {
        if (this.state.sisenseFrame && this.state.sisenseFrame.dashboard) {
            this.state.sisenseFrame.dashboard.off(this.state.embedSdk.enums.DashboardEventType.LOADED, this._dashboardLoaded);
        }

        // Clean up anything needed
        // - Cleaning everything has performance impact between different pages as need to reload embedSDK
        if (this.props.unmountShouldDestroySisenseFrame) {
            this.setState(prevState => ({
                ...prevState,
                sisenseFrame: undefined
            }));
        }

        if (this.props.unmountShouldUnloadEmbedSdk) {
            this.setState(prevState => ({
                ...prevState,
                embedSdk: undefined
            }));
            delete window['sisense.embed'];
            let embedSdkTags = document.querySelectorAll('[script-name="sisenseEmbedSdk"]');
            embedSdkTags.forEach((tag) => {
                tag.remove();
            });
        }
    }
}

BiDashboardEmbed.propTypes = {
    frameHeight: PropTypes.string,
    frameWidth: PropTypes.string,
    serverUrl: PropTypes.string.isRequired,
    showLeftPane: PropTypes.bool,
    showRightPane: PropTypes.bool,
    showToolbar: PropTypes.bool,
    dashboardId: PropTypes.string.isRequired,
    themeId: PropTypes.string,
    volatile: PropTypes.bool,
    editMode: PropTypes.bool,
    urlParams: PropTypes.string,
    onDashboardLoaded: PropTypes.func,
    onDashboardFirstLoaded: PropTypes.func,
    unmountShouldDestroySisenseFrame: PropTypes.bool,
    unmountShouldUnloadEmbedSdk: PropTypes.bool,
    // Sisense properties
    sisenseContainerElement: PropTypes.object.isRequired,
    sisenseContainerElementId: PropTypes.string.isRequired
};

export default withIdleTimer(BiDashboardEmbed)

import React from 'react';
import PropTypes from 'prop-types';

import { Pagination as MuiPagination } from '@mui/material';

export const paginationColors = {
  STANDARD: 'standard',
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

export const paginationSizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

const Pagination = ({ count, page, color, size, disabled, ...overrides }) => (
  <MuiPagination
    count={count}
    page={page}
    color={color}
    size={size}
    disabled={disabled}
    {...overrides}
  />
);

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number,
  color: PropTypes.oneOf(Object.values(paginationColors)),
  size: PropTypes.oneOf(Object.values(paginationSizes)),
  disabled: PropTypes.bool
};

export default Pagination;

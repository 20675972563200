import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer as MuiDrawer, Collapse as MuiCollapse } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction
} from '@mui/material';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import Tooltip from '~/components/Tooltip';

export const drawerAnchors = {
  RIGHT: 'right',
  LEFT: 'left'
};

const openedMixin = (theme, width) => ({
  width: width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `64px`,

  '& .MuiListItemText-root': {
    display: 'none'
  }
});

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open, width }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.secondary.dark,
    ...(open ? openedMixin(theme, width) : closedMixin(theme, width))
  },
  '& .MuiDrawer-root': {
    position: 'absolute'
  },
  '& .MuiPaper-root': {
    position: 'absolute'
  }
}));

const StyledListItem = styled(ListItem, {
  shouldForwardProp: prop => prop !== 'isSelected'
})(({ theme, isSelected }) => {
  return {
    '& .MuiListItemText-root': {
      color: theme.palette.secondary.contrastText
    },
    '& .MuiListItemSecondaryAction-root svg': {
      fill: theme.palette.grey[500]
    },
    '& .MuiListItemIcon-root svg': {
      fill: theme.palette.grey[500]
    },
    ...(isSelected && {
      '& .MuiListItemIcon-root svg': {
        fill: theme.palette.secondary.contrastText
      }
    })
  };
});

const MaybeTooltip = ({ shouldShow, children, ...tooltipProps }) => {
  return shouldShow ? (
    <Tooltip {...tooltipProps}>{children}</Tooltip>
  ) : (
    children
  );
};

const Drawer = ({ open, items, ...overrides }) => {
  const isSmallScreen = useMediaQuery('(max-width:800px)');
  const theme = useTheme();

  const isOpen = isSmallScreen ? open : true;

  const getMappedItems = mapItems => {
    return mapItems.map((item, index) => {
      const [collapse, setCollapse] = useState(false);

      if (item.type && item.type === 'divider') {
        return (
          <Divider key={index} color={blueGrey[800]} sx={{ height: '3px' }} />
        );
      }

      const hasInnerItems = item?.innerItems?.length > 0;
      const bgColor = item?.isSelected
        ? theme.palette.primary.dark
        : theme.palette.secondary.dark;

      return (
        <List key={index}>
          <MaybeTooltip
            shouldShow={!isOpen}
            title={item.label}
            placement="right"
          >
            <StyledListItem
              onClick={evt => {
                if (hasInnerItems) {
                  setCollapse(!collapse);
                }
                if (item?.onClick) {
                  item.onClick(evt);
                }
              }}
              disablePadding
              isSelected={item?.isSelected ?? false}
              sx={{ display: 'block' }}
            >
              <ListItemButton
                aria-label={item.label}
                title={item.label}
                href={item?.href}
                sx={{
                  px: 2.5,
                  ...(item?.isSelected && { backgroundColor: bgColor }),
                  '&:hover': {
                    ...(item?.isSelected && { backgroundColor: bgColor })
                  }
                }}
              >
                {item.icon && (
                  <>
                    <ListItemIcon
                      sx={{
                        mr: isOpen ? 3 : 'auto'
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                  </>
                )}
                <ListItemText primary={item.label} />
                {hasInnerItems && isOpen && (
                  <ListItemSecondaryAction>
                    {collapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </ListItemSecondaryAction>
                )}
              </ListItemButton>
            </StyledListItem>
          </MaybeTooltip>

          {hasInnerItems && (
            <MuiCollapse in={collapse} timeout="auto" unmountOnExit>
              {getMappedItems(item.innerItems)}
            </MuiCollapse>
          )}
        </List>
      );
    });
  };

  return (
    <StyledDrawer
      variant="permanent"
      width="320px"
      open={isOpen}
      PaperProps={{
        elevation: 16
      }}
      {...overrides}
    >
      {getMappedItems(items)}
    </StyledDrawer>
  );
};

const ItemShape = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  isSelected: PropTypes.bool,
  innerItems: [],
  onClick: PropTypes.func,
  href: PropTypes.string
};

const DividerTypeItem = {
  type: PropTypes.oneOf(['divider'])
};

// Adding recursive innerItem type.
ItemShape.innerItems = PropTypes.arrayOf(PropTypes.shape(ItemShape));

MaybeTooltip.propTypes = {
  shouldShow: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

Drawer.propTypes = {
  open: PropTypes.bool,
  anchor: PropTypes.oneOf(Object.values(drawerAnchors)),
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(ItemShape),
      PropTypes.shape(DividerTypeItem)
    ])
  )
};

Drawer.defaultProps = {};

export default Drawer;

import { createEvent, createStore, sample } from 'effector';
import { persist } from 'effector-storage/local';
import { palettes } from './palettes';
import '@mui/material/styles';

/** @typedef {import('@mui/material/styles').Theme} Theme */

/** @type {import('effector').StoreWritable<'light' | 'dark' | 'system'>} */
const $selectedTheme = createStore('light');

/** @type {import('effector').StoreWritable<'light' | 'dark'>} */
const $systemTheme = createStore(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

/** @type {import('effector').StoreWritable<keyof typeof palettes>} */
const $selectedPaletteKey = createStore('default');

/** @type {import('effector').Store<'light' | 'dark'>} */
const $theme = sample({
  source: {
    selectedTheme: $selectedTheme,
    systemTheme: $systemTheme
  },
  fn: ({ selectedTheme, systemTheme }) => (selectedTheme === 'system' ? systemTheme : selectedTheme)
});

const $palette = sample({
  source: {
    theme: $theme,
    selectedPaletteName: $selectedPaletteKey
  },
  fn: ({ theme, selectedPaletteName }) => palettes[selectedPaletteName][theme]
});

/** @type {import('effector').Store<Record<'light' | 'dark', Theme>>} */
const $themePalettes = sample({
  source: $selectedPaletteKey,
  fn: selectedPaletteName => palettes[selectedPaletteName]
});

/** @type {import('effector').EventCallable<'light' | 'dark' | 'system'>} */
const changeTheme = createEvent();

/** @type {import('effector').EventCallable<'light' | 'dark'>} */
const systemThemeChanged = createEvent();

/** @type {import('effector').EventCallable<keyof typeof palettes>} */
const changePalette = createEvent();

persist({
  store: $selectedTheme,
  key: 'ghs-theme'
});

sample({
  clock: changeTheme,
  target: $selectedTheme
});

sample({
  clock: changePalette,
  target: $selectedPaletteKey
});

sample({
  clock: systemThemeChanged,
  target: $systemTheme
});

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
  systemThemeChanged(e.matches ? 'dark' : 'light');
});

export const $$theme = {
  $theme,
  $mode: $selectedTheme,
  $palette,
  $paletteKey: $selectedPaletteKey,
  $themePalettes,

  changeTheme,
  changePalette
};

import React from 'react';
import PropTypes from 'prop-types';

import { Paper as MuiPaper } from '@mui/material';

export const paperVariants = {
  ELEVATION: 'elevation',
  OUTLINED: 'outlined'
};

const Paper = ({ children, variant, elevation, square, ...overrides }) => (
  <MuiPaper
    variant={variant}
    elevation={variant === paperVariants.ELEVATION ? elevation : 0}
    square={square}
    {...overrides}
  >
    {children}
  </MuiPaper>
);

Paper.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(Object.values(paperVariants)),
  elevation: PropTypes.number,
  square: PropTypes.bool
};

Paper.defaultProps = {
  children: null,
  variant: paperVariants.ELEVATION,
  elevation: 1,
  square: false
};

export default Paper;

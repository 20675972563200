import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogTitle, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useUnit } from 'effector-react';
import { pick } from 'lodash';
import { useEffect, useState } from 'react';
import { $$reseller } from '../../services/ResellerService/model';
import { recalculateVersionDates } from '../../services/ResellerService/utils';
import DateCell from '../DateCell';
import { $$batchEditVersionDatesDialogModel } from './model';

/**
 * CampaignVersionsDialog
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function BatchEditVersionDatesDialog() {
  const open = useUnit($$batchEditVersionDatesDialogModel.$isOpen);
  const state = useUnit($$batchEditVersionDatesDialogModel.$state);
  const campaign = useUnit($$batchEditVersionDatesDialogModel.$campaign);
  const isRecalculationEnabled = useUnit($$batchEditVersionDatesDialogModel.$isRecalculationEnabled);
  const inHomeWindowRanges = useUnit($$reseller.$inHomeWindowRanges);

  const [rows, setRows] = useState(/** @type {Reseller.Version[]} */ ([]));

  useEffect(() => {
    if (state) {
      setRows(state.versions);
    } else {
      setTimeout(() => {
        setRows([]);
      }, 250);
    }
  }, [state?.versions]);

  const handleSubmit = () => {
    if (state) {
      $$batchEditVersionDatesDialogModel.changesApproved({
        campaignId: state.campaignId,
        versionsDates: rows.map(option => ({ ...option, ...pick(option, ['firstMailDate', 'lastMailDate', 'inHomeStartDate', 'inHomeEndDate']) }))
      });
      $$batchEditVersionDatesDialogModel.close();
    }
  };

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Reseller.Version>} */
  const dataColumnProps = {
    field: '',
    flex: 1,
    valueGetter: params => dayjs(params).toDate(),
    valueFormatter: date => dayjs(date).format('MM/DD/YYYY'),
    renderEditCell: ({ row, id, field }) => <DateCell id={id} field={field} row={row} />,
    type: 'date'
  };

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Reseller.Version>[]} */
  const columns = [
    { field: 'name', flex: 1, headerName: 'Version Name' },
    { ...dataColumnProps, field: 'firstMailDate', headerName: 'First Mail Date' },
    { ...dataColumnProps, field: 'lastMailDate', headerName: 'Last Mail Date' },
    { ...dataColumnProps, field: 'inHomeStartDate', headerName: 'In-Home Start' },
    { ...dataColumnProps, field: 'inHomeEndDate', headerName: 'In-Home End' },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      getActions: ({ id }) => [
        <IconButton
          key={`campaign-cancel-button-${id}`}
          onClick={() => {
            setRows(rows.filter(row => row.id !== id));
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ]
    }
  ];

  return (
    <Dialog
      id="batch-edit-version-dates-dialog"
      data-testid="batch-edit-version-dates-dialog"
      open={open}
      onClose={() => $$batchEditVersionDatesDialogModel.close()}
      fullWidth={true}
      maxWidth="md"
      PaperProps={{ sx: { borderRadius: 2, padding: 4, gap: 1 } }}
      disableEnforceFocus
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', flex: 1, padding: 0 }}>
        <Typography variant="h6">Batch edit dates</Typography>
        <IconButton onClick={() => $$batchEditVersionDatesDialogModel.close()}>
          <CloseIcon data-testid="close-batch-edit-version-dates-dialog" />
        </IconButton>
      </DialogTitle>
      <Tooltip title="In-Home dates will be recalculated when Mail Dates are changed. GHS standard defaults will be used unless Client-specific defaults are available" arrow placement="right">
        <Box
          data-testid="recalculate-in-home-dates"
          sx={{ display: 'flex', alignItems: 'center', gap: 2, width: 'fit-content' }}
          onClick={() => $$batchEditVersionDatesDialogModel.recalculatingChanged(!isRecalculationEnabled)}
        >
          <Switch data-testid="recalculate-in-home-dates-switch" color="success" checked={isRecalculationEnabled} />
          <Typography sx={{ textTransform: 'uppercase', cursor: 'default' }} variant="subtitle2" color="primary">
            Recalculate in-home dates
          </Typography>
        </Box>
      </Tooltip>
      <Box data-testid="date-pickers" sx={{ display: 'flex', flexDirection: 'row', flex: 1, gap: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {
            /** @type {const} */ (['firstMailDate', 'lastMailDate', 'inHomeStartDate', 'inHomeEndDate']).map(field => (
              <DatePicker
                key={field}
                name={field}
                label={columns.find(c => c.field === field)?.headerName}
                onChange={v => {
                  if (!v) {
                    return;
                  }
                  setRows(
                    rows.map(row => {
                      row[field] = v.format('YYYY-MM-DD');
                      const inHomeWindowRange = /** @type {Reseller.InHomeWindowRange} */ (
                        inHomeWindowRanges.find(range => range.mailClassId === row.mailClass.id && range.customerId === campaign?.customer.id && range.lineOfBusinessId === campaign?.lineOfBusiness.id)
                      );
                      const { changes, isRecalculationEnabled: newIsRecalculationEnabled } = recalculateVersionDates({
                        version: row,
                        field,
                        value: v.format('YYYY-MM-DD'),
                        isRecalculationEnabled,
                        inHomeWindowRange
                      });
                      if (isRecalculationEnabled && !newIsRecalculationEnabled) {
                        $$batchEditVersionDatesDialogModel.recalculatingChanged(false);
                      }
                      return { ...row, ...changes };
                    })
                  );
                }}
              />
            ))
          }
        </LocalizationProvider>
      </Box>
      <DataGridPro
        data-testid="batch-edit-version-dates-datagrid"
        rows={rows}
        columns={columns}
        getRowId={row => row.id}
        disableRowSelectionOnClick={true}
        initialState={{ columns: { columnVisibilityModel: { id: false } } }}
        sx={{ [`.${gridClasses.cell}`]: { userSelect: 'none' } }}
      />
      <DialogActions sx={{ padding: 0 }}>
        <Button data-testid="cancel-changes" variant="outlined" color="primary" sx={{ minWidth: 150 }} onClick={() => $$batchEditVersionDatesDialogModel.close()}>
          Cancel
        </Button>
        <Button data-testid="apply-changes" variant="contained" onClick={handleSubmit}>
          Apply change to all selected
        </Button>
      </DialogActions>
    </Dialog>
  );
}

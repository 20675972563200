/* eslint-disable no-nested-ternary */
import * as React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import TextField from '@mui/material/TextField';

const defaultValue = dayjs();

export const dateTimePickerTypes = {
  TIME: 'time',
  MOBILETIME: 'mobileTime',
  DESKTOPDATE: 'desktopDate',
  MOBILEDATE: 'mobileDate',
  DATETIME: 'datetime'
};

const DateTimePicker = ({ type, ...overrides }) => {
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = newValue => {
    setValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {type === dateTimePickerTypes.DESKTOPDATE ? (
        <DesktopDatePicker
          label="Date desktop"
          inputFormat="MM/DD/YYYY"
          value={dayjs(value)}
          onChange={handleChange}
          textField={params => <TextField {...params} />}
          {...overrides}
        />
      ) : type === dateTimePickerTypes.MOBILEDATE ? (
        <MobileDatePicker
          label="Date mobile"
          inputFormat="MM/DD/YYYY"
          value={dayjs(value)}
          onChange={handleChange}
          textField={params => <TextField {...params} />}
          {...overrides}
        />
      ) : type === dateTimePickerTypes.TIME ? (
        <TimePicker
          label="Time"
          value={dayjs(value)}
          onChange={handleChange}
          textField={params => <TextField {...params} />}
          {...overrides}
        />
      ) : type === dateTimePickerTypes.MOBILETIME ? (
        <MobileTimePicker
          label="Time"
          value={dayjs(value)}
          onChange={handleChange}
          textField={params => <TextField {...params} />}
          {...overrides}
        />
      ) : (
        <MuiDateTimePicker
          label="Date&Time picker"
          value={dayjs(value)}
          onChange={handleChange}
          textField={params => <TextField {...params} />}
          {...overrides}
        />
      )}
    </LocalizationProvider>
  );
};

DateTimePicker.propTypes = {
  type: PropTypes.oneOf(Object.values(dateTimePickerTypes))
};

export default DateTimePicker;

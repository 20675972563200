import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { without, append } from 'ramda';

import { Collapse } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

import List, { ListItemButton, ListItemIcon, ListItemText } from './index';

const NavigationList = ({ items, sx, ...overrides }) => {
  const [openList, setOpenList] = useState([0]);

  return (
    <List component="nav" sx={{ width: '100%', ...sx }} {...overrides}>
      {items.map(({ label, href, icon, items: subItems }, i) => {
        const isOpen = openList.includes(i);

        const toggleOpen = () => setOpenList(isOpen ? without([i]) : append(i));
        const dropIcon = isOpen ? <ArrowDropUp /> : <ArrowDropDown />;

        return (
          <Fragment key={label}>
            <ListItemButton href={href} onClick={subItems ? toggleOpen : null}>
              {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
              <ListItemText primary={label} />
              {subItems ? dropIcon : null}
            </ListItemButton>
            {subItems && (
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {subItems.map(
                    ({ label: subItemLabel, href: subItemHref }) => (
                      <ListItemButton
                        key={`${subItemLabel}-${i}`}
                        href={subItemHref}
                      >
                        <ListItemText inset primary={subItemLabel} />
                      </ListItemButton>
                    )
                  )}
                </List>
              </Collapse>
            )}
          </Fragment>
        );
      })}
    </List>
  );
};

NavigationList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.node,
      href: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape())
    })
  ),
  sx: PropTypes.shape()
};

NavigationList.defaultProps = {
  items: []
};

export default NavigationList;

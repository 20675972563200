import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import useAuth0WithErrorHandling from '../hooks/useAuth0WithErrorHandling';
import { getUserDashboards } from '../services/BiService';
import BiThumbnailCard from './BiThumbnailCard';

/**
 * @typedef {import('../services/BiService').SisenseInfo} SisenseInfo
 */

const BiDashboardList = () => {
  const { getAccessTokenSilently } = useAuth0WithErrorHandling();

  const [dashboards, setDashboards] = useState(/** @type { SisenseInfo | null } */ (null));
  const [biServerAuthResult, setBiServerAuthResult] = useState('');
  const [isDashboardListLoading, setIsDashboardListLoading] = useState(false);

  useEffect(() => {
    setIsDashboardListLoading(true);
    getAccessTokenSilently()
      .then(token => getUserDashboards(token).then(setDashboards))
      .then(() => setBiServerAuthResult(''))
      .catch(() => setBiServerAuthResult('Error loading dashboards.'))
      .finally(() => setIsDashboardListLoading(false));
  }, []);

  if (isDashboardListLoading) {
    return (
      <Box sx={{ display: 'flex' }} justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container id="dashboard-list-container">
      <Typography color="text.primary">{biServerAuthResult}</Typography>
      <Grid container>
        {(dashboards || [])
          .sort((a, b) => a.title.localeCompare(b.title))
          .map(dashboard => {
            const hrefParams = createSearchParams({ dashboardId: dashboard.id }).toString();
            const hrefUrl = `/dashboard?${hrefParams}`;
            return (
              <Grid item key={dashboard.id + '-grid'} xs={12} sm={6} md={4} lg={3}>
                <BiThumbnailCard key={dashboard.id + '-card'} {...dashboard} link={hrefUrl} />
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
};

BiDashboardList.propTypes = {};

export default BiDashboardList;

import axios from 'axios';
import '../pages/AddressVerification/utils/TypeDefs';
import { createEvent, createStore, sample } from 'effector';
import qs from 'qs';

const FETCH_TIMEOUT_MS = 299000;

const $getToken = createStore(/** @type {(() => Promise<string>) | null} */ (null));

/** @type {import('effector').EventCallable<(() => Promise<string>) | null>} */
export const getTokenChanged = createEvent();

sample({
  clock: getTokenChanged,
  target: $getToken
});

export const ghApi = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
  timeout: FETCH_TIMEOUT_MS,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' })
});

[ghApi].forEach(api => {
  api.interceptors.request.use(async config => {
    const getToken = $getToken.getState();
    if (getToken !== null && typeof getToken === 'function') {
      const token = await getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  });
});

export const sisenseApi = axios.create({
  baseURL: `${process.env.REACT_APP_BI_SERVER_URL}/api/v1`,
  withCredentials: true
});

/**
 * Fetch mail details.
 *
 * @param {string} imb imb of mail piece
 * @param {string} pkgId pkgId of mail piece
 * @param {string} accessToken accessToken of authenticated user
 * @returns {Promise<any|undefined>} mail details
 */
export const fetchMailDetails = async (imb, pkgId, accessToken) => {
  const url = `${process.env.REACT_APP_API_SERVER_URL}/mailPiece?imb=${imb}&pkgId=${pkgId}`;
  return fetchDataAxios(url, accessToken, { timeout: FETCH_TIMEOUT_MS });
};

/**
 * Fetch ACS events for a mail piece.
 *
 * @param {string} trackCode track code of the mail piece
 * @param {string} pkgId pkg id of the mail piece
 * @param {string} mailDate the mail date of the piece
 * @param {string} accessToken accessToken of authenticated user
 * @returns {Promise<any|undefined>} ACS events
 */
export const fetchAcsEvents = async (trackCode, pkgId, mailDate, accessToken) => {
  const url = `${process.env.REACT_APP_API_SERVER_URL}/acsEvents?trackCode=${trackCode}&pkgId=${pkgId}&mailDate=${mailDate}`;
  return fetchDataAxios(url, accessToken, { timeout: FETCH_TIMEOUT_MS });
};

/**
 * Fetch scan data for a mail piece.
 *
 * @param {string} trackCode track code of the mail piece
 * @param {string} pkgId pkg id of the mail piece
 * @param {string} date date of the mail piece
 * @param {string} accessToken accessToken of authenticated user
 * @returns {Promise<any|undefined>} scan data
 */
export const fetchScanData = async (trackCode, pkgId, date, accessToken) => {
  const url = `${process.env.REACT_APP_API_SERVER_URL}/scans?trackCode=${trackCode}&pkgId=${pkgId}&date=${date}`;
  return fetchDataAxios(url, accessToken, { timeout: FETCH_TIMEOUT_MS });
};

/**
 * Lookup mail pieces by piece number.
 *
 * @param {object} lookupRequestParams Object containing the lookup request params.
 * @param {string} lookupRequestParams.imb imb of the mail piece
 * @param {string} lookupRequestParams.uniqueRecordId unique record id of the mail piece
 * @param {boolean} lookupRequestParams.origin true for origin; false for destination
 * @param {import('dayjs').Dayjs} lookupRequestParams.startDate start date of search range
 * @param {import('dayjs').Dayjs} lookupRequestParams.endDate end date of search range
 * @param {string} accessToken accessToken of authenticated user
 * @returns {Promise<any|undefined>} mail pieces search results
 */
export const fetchLookupByPieceNumResults = async (lookupRequestParams, accessToken) => {
  return fetchDataAxios(`${process.env.REACT_APP_API_SERVER_URL}/singlePieceLookup`, accessToken, {
    params: {
      imb: lookupRequestParams.imb,
      seqNum: lookupRequestParams.uniqueRecordId,
      origin: lookupRequestParams.origin,
      startDate: lookupRequestParams.startDate.format('MM/DD/YYYY'),
      endDate: lookupRequestParams.endDate.format('MM/DD/YYYY')
    },
    timeout: FETCH_TIMEOUT_MS
  });
};

/**
 * Lookup mail pieces by name.
 *
 * @param {object} lookupRequestParams Object containing the lookup request params.
 * @param {string} lookupRequestParams.name name on mail piece
 * @param {string} lookupRequestParams.businessName business name on mail piece
 * @param {string} lookupRequestParams.city city on mail piece
 * @param {string} lookupRequestParams.zip zip on mail piece
 * @param {string} lookupRequestParams.userField1 user field 1 (Only used in SelectTrak)
 * @param {string} lookupRequestParams.userField2 user field 2 (Only used in SelectTrak)
 * @param {string} lookupRequestParams.userField3 user field 3 (Only used in SelectTrak)
 * @param {boolean} lookupRequestParams.origin true for origin; false for destination
 * @param {import('dayjs').Dayjs} lookupRequestParams.startDate start date of search range
 * @param {import('dayjs').Dayjs} lookupRequestParams.endDate end date of search range
 * @param {string} accessToken accessToken of authenticated user
 * @returns {Promise<any|undefined>} mail pieces search results
 */
export const fetchLookupByNameResults = async (lookupRequestParams, accessToken) => {
  return fetchDataAxios(`${process.env.REACT_APP_API_SERVER_URL}/singlePieceLookup`, accessToken, {
    params: {
      name: lookupRequestParams.name,
      businessName: lookupRequestParams.businessName,
      city: lookupRequestParams.city,
      zip: lookupRequestParams.zip,
      userField1: lookupRequestParams.userField1,
      userField2: lookupRequestParams.userField2,
      userField3: lookupRequestParams.userField3,
      origin: lookupRequestParams.origin,
      startDate: lookupRequestParams.startDate.format('MM/DD/YYYY'),
      endDate: lookupRequestParams.endDate.format('MM/DD/YYYY')
    },
    timeout: FETCH_TIMEOUT_MS
  });
};

/**
 * Call VerifyAQ API Endpoint.
 *
 * @param {object} verifyAQRequestObject - Object containing address information.
 * @param {string} verifyAQRequestObject.company - Company name.
 * @param {string} verifyAQRequestObject.street - Street address.
 * @param {string} verifyAQRequestObject.street2 - Additional street address information.
 * @param {string} verifyAQRequestObject.urbanization - Urbanization information.
 * @param {string} verifyAQRequestObject.city - City.
 * @param {string} verifyAQRequestObject.state - State.
 * @param {string} verifyAQRequestObject.zip - ZIP code.
 * @param {string} verifyAQRequestObject.zipPlusFour - ZIP+4 code.
 * @param {boolean} verifyAQRequestObject.standardizeStreets - Indicates whether to standardize street addresses (Set to true in all cases).
 * @param {boolean} verifyAQRequestObject.useMixedCase - Indicates whether to use mixed case for address information (optional, default is false).
 * @param {boolean} verifyAQRequestObject.retainAddressLocation - Indicates whether to retain address location information (optional, default is false).
 * @param {boolean} verifyAQRequestObject.suiteLinkToAddress2 - Links a suite to the second address line (optional).
 * @param {string} accessToken - Access token for authentication.
 * @returns {VerifyAQResponseDef} Verify AQ API request results.
 */
/* eslint-enable jsdoc/no-undefined-types */
export const fetchVerifyAQ = async (verifyAQRequestObject, accessToken) => {
  return fetchDataAxios(`${process.env.REACT_APP_API_SERVER_URL}/addressQuality`, accessToken, {
    params: { ...Object.fromEntries(Object.entries(verifyAQRequestObject).filter(([, value]) => value !== '')) }
  });
};

export const fetchDataAxios = async (url, accessToken, config = {}) => {
  const response = await axios.get(url, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    ...config
  });

  if (response.status === 200) {
    return response.data;
  } else {
    throw new HTTPResponseError(response);
  }
};

export const postDataAxios = async (url, accessToken, data, config = {}) => {
  const response = await axios.post(url, data,{
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    ...config
  });

  if (response.status === 200) {
    return response.data;
  } else {
    throw new HTTPResponseError(response);
  }
};

export class HTTPResponseError extends Error {
  constructor(response) {
    super(`HTTP Error Response: ${response.status} ${response.statusText}`);
    this.response = response;
  }
}

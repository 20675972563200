import {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import {copyTextToClipboard} from "../../../../util/ClipboardUtils";
import {SearchResultsPropTypes} from "../../utils/PropTypes";

/**
 * The OutputAddress component is used for displaying output data from searchResults in an address form. It also
 * allows users to copy the address form to their clipboard.
 *
 * @param {object} props - The properties passed to the component.
 * @param {object} props.searchResults - The searchResults data object that contains the data from VerifyAQ request.
 * @returns {Element} React JSX element representing the OutputAddress component.
 */
const OutputAddress = ({searchResults}) => {

    const [outputTextToCopy, setOutputTextToCopy] = useState("")
    const [openCopySnackBar, setOpenCopySnackBar] = useState(false);

    const getStandardizedAddress = () => {
        let address = "";
        if (searchResults['company']){
            address += (searchResults['company'] + "\n")
        }
        if (searchResults['street']){
            address += (searchResults['street'] + "\n")
        }
        if (searchResults['street2']) {
            address += (searchResults['street2'] + "\n")
        }
        if (searchResults['city']){
            address += searchResults['city']
        }
        address += ", "
        if (searchResults['state']){
            address += searchResults['state'];
            address += " "
        }
        if (searchResults['zip']) {
            address += searchResults['zip']
        }
        if (searchResults['zipPlusFour']) {
            address += "-"
            address += searchResults['zipPlusFour']
        }
        setOutputTextToCopy(address)
    }

    const handleOutputCopy = async () => {
        await copyTextToClipboard(outputTextToCopy)
        setOpenCopySnackBar(true);
    };

    const handleCloseCopySnackbar = () => {
        setOpenCopySnackBar(false);
    };

    useEffect(() => {
        getStandardizedAddress();
    }, [searchResults]);

    return (
        <Card sx={{flexGrow: 1}}>
            <CardContent>
                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Output Address
                </Typography>
                <Divider sx={{width: '100%', marginTop: 1, marginBottom: 1}}/>
                <div>
                    <Typography data-testid="outputText-typography" variant={"h6"} style={{whiteSpace: 'pre-line'}} noWrap>
                        {outputTextToCopy}
                    </Typography>
                </div>
            </CardContent>
            <CardActions>
                <Button data-testid="copy-button-output-address"  size="small" onClick={handleOutputCopy}>Copy</Button>
                <Snackbar
                    open={openCopySnackBar}
                    color="inherit"
                    autoHideDuration={3000}
                    onClose={handleCloseCopySnackbar}
                    message="Copied to clipboard!"
                    action={
                        <>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleCloseCopySnackbar}
                            >
                                <CloseIcon fontSize="small"/>
                            </IconButton>
                        </>
                    }
                />
            </CardActions>
        </Card>
    )
}
OutputAddress.propTypes = {
    searchResults: SearchResultsPropTypes
}

export default OutputAddress;
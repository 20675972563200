import { Box } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * CampaignDeleteConfirmationMessage
 *
 * @param {object} props - props
 * @param {Reseller.Campaign} props.campaign - campaign
 * @returns {React.ReactNode} - JSX Element
 */
export default function CampaignDeleteConfirmationMessage(props) {
  const { campaign } = props;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <span>Are you sure you wish to delete the following campaign?</span>
      <span>{`${campaign.name} - ${campaign.number}`}</span>
    </Box>
  );
}

CampaignDeleteConfirmationMessage.propTypes = {
  campaign: PropTypes.object.isRequired
};

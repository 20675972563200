import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import { Badge as MuiBadge } from '@mui/material';

import { colors as themeColors } from '~/theme';

export const badgeColors = {
  ...omit(['TEXT'], themeColors),
  DEFAULT: 'default'
};

export const badgeVariants = {
  STANDARD: 'standard',
  DOT: 'dot'
};

export const badgeOverlaps = {
  RECTANGULAR: 'rectangular',
  CIRCULAR: 'circular'
};

const Badge = ({
  children,
  label,
  badgeContent,
  max,
  showZero,
  variant,
  color,
  overlap,
  anchorOrigin,
  ...overrides
}) => (
  <MuiBadge
    aria-label={label}
    badgeContent={badgeContent}
    max={max}
    showZero={showZero}
    variant={variant}
    color={color}
    overlap={overlap}
    anchorOrigin={anchorOrigin}
    {...overrides}
  >
    {children}
  </MuiBadge>
);

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  badgeContent: PropTypes.node,
  max: PropTypes.number,
  showZero: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(badgeVariants)),
  color: PropTypes.oneOf(Object.values(badgeColors)),
  overlap: PropTypes.oneOf(Object.values(badgeOverlaps)),
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOf(['left', 'right']).isRequired,
    vertical: PropTypes.oneOf(['top', 'bottom']).isRequired
  })
};

Badge.defaultProps = {
  variant: badgeVariants.STANDARD,
  color: badgeColors.PRIMARY,
  badgeContent: null,
  max: undefined,
  showZero: false,
  label: null,
  overlap: badgeOverlaps.RECTANGULAR,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
};

export default Badge;

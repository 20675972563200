import { Dialog } from '@mui/material';
import { useUnit } from 'effector-react';
import { $$tutorialVideos } from './model';

export default function TutorialVideosModal() {
  const open = useUnit($$tutorialVideos.$isOpen);
  const videoUrl = useUnit($$tutorialVideos.$state.map(state => state?.url || null));

  return (
    <Dialog open={open} onClose={() => $$tutorialVideos.close()} sx={{ '.MuiDialog-paper': { maxWidth: 'unset' } }}>
      {videoUrl ? <video src={videoUrl} controls className="rounded-full" style={{ width: '100%', height: 'auto', maxWidth: '900px' }} controlsList="nodownload" /> : null}
    </Dialog>
  );
}

// @ts-ignore
import { TextField, textFieldVariants } from '@ghs/grayhair-component-library';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

/**
 * DelayedTextField
 *
 * @param {{
 *  value?: string;
 *  onChange: (value: string) => void;
 *  delay?: number;
 * } & Omit<import('@mui/material').TextFieldProps, 'value' | 'onChange'>} props - props
 * @returns {React.ReactNode} - JSX Element
 */
function DelayedTextField(props) {
  const { value = '', onChange, variant, delay = 200, ...overrides } = props;

  const [inputValue, setInputValue] = useState(/** @type {string} */ (value));
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    let typingTimeout;
    if (isTyping) {
      typingTimeout = setTimeout(() => {
        setIsTyping(false);
        onChange(inputValue);
      }, delay);
    }

    return () => clearTimeout(typingTimeout);
  }, [inputValue, isTyping]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = event => {
    setInputValue(event.target.value);
    setIsTyping(true);
  };

  return <TextField value={inputValue} variant={!variant && props.type === 'search' ? textFieldVariants.OUTLINED : variant} onChange={handleInputChange} {...overrides} />;
}

DelayedTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  variant: PropTypes.string,
  delay: PropTypes.number
};

export default DelayedTextField;

import { Dialog } from "@ghs/grayhair-component-library";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as React from "react";

import PropTypes from "prop-types";

/**
 * Dialog to warn the user of session timeout due to inactivity.
 *
 * @param {object} props props for the component
 * @param {number} props.secondsToLogout number of seconds until the user is logged out
 * @param {Function} props.onClose handler function to run when dialog closes
 * @param {boolean} props.open whether the dialog should be open
 * @param {Function} props.logoutFn handler function to log out
 * @returns {Element} SessionTimeoutDialog
 */
function SessionTimeoutDialog({ secondsToLogout, onClose, open, logoutFn }) {
    return (
      <Dialog
        fullWidth
        open={open}
        onClose={onClose}
        title="Are you still there?"
        actions={
            <Box sx={{ display: 'flex' }}>
                <Button color="secondary" onClick={onClose} size="medium" variant="contained" disabled={false} sx={{ mt: 1 }}>I&apos;m Still Here</Button>
                <Button color="primary" onClick={logoutFn} size="medium" variant="contained" disabled={false} sx={{ mt: 1, ml: 1 }}>Log me out, please!</Button>
            </Box>
        }
      >
          <Box>
              <Typography>You&apos;ve been inactive for a while. For your security, your GrayHair Client Portal session will expire in {convertSecondsToMinutesAndSeconds(secondsToLogout)}.</Typography>
          </Box>
      </Dialog>
    )
}
SessionTimeoutDialog.propTypes = {
    secondsToLogout: PropTypes.number.isRequired,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    logoutFn: PropTypes.func,
};

const convertSecondsToMinutesAndSeconds = (input) => {
    const minutes = `${Math.floor(input / 60)}`.padStart(2, "0");
    const seconds = `${input - minutes * 60}`.padStart(2, "0");
    return `${minutes}:${seconds}`;
}

export { SessionTimeoutDialog, convertSecondsToMinutesAndSeconds };
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import PersonalHeader from '../MyRolesTab/PersonalHeader';
import RoleSelect from './RoleSelect';
import UserRolesTable from './UserRolesTable';
import { $$manageUserRolesModal } from './model';

/**
 * ManageUserRolesModal component
 *
 * @returns {React.ReactNode} ManageUserRolesModal
 */
export default function ManageUserRolesModal() {
  const open = useUnit($$manageUserRolesModal.$isOpen);
  const state = useUnit($$manageUserRolesModal.$state);
  const user = useUnit($$manageUserRolesModal.$user);

  if (!state || !user) {
    return null;
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => {
        $$manageUserRolesModal.close();
      }}
      PaperProps={{ sx: { borderRadius: 2, padding: 2 } }}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
        <IconButton onClick={() => $$manageUserRolesModal.close()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <PersonalHeader name={user.name || ''} firstName={user.firstName || ''} lastName={user.lastName || ''} avatarUrl={user.avatarUrl} />
          <Typography variant="body1" color="text.primary" sx={{ mb: 1 }}>
            {user.email}
          </Typography>
          <UserRolesTable />
          <RoleSelect />
        </DialogContentText>
        <DialogActions sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={() => $$manageUserRolesModal.close()}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => $$manageUserRolesModal.saveClicked()}>
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

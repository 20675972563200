import React from 'react';
import PropTypes from 'prop-types';

import {
    ToggleButton as MuiToggleButton,
    ToggleButtonGroup
} from '@mui/material';

export const toggleButtonSizes = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
};

const ToggleButton = ({
                          children,
                          size,
                          disabled,
                          label,
                          sx,
                          divider,
                          ariaLabel,
                          ...overrides
                      }) => {
    const paddings = {
        [toggleButtonSizes.SMALL]: '11px',
        [toggleButtonSizes.MEDIUM]: '15px',
        [toggleButtonSizes.LARGE]: '19px'
    };

    return (
        <MuiToggleButton
            size={size}
            disabled={disabled}
            aria-label={ariaLabel}
            sx={{
                gap: 1,
                px: paddings[size],
                ...(!divider && { borderWidth: 0 }),
                ...(divider && { borderWidth: 0, borderRightWidth: 1 }),
                borderRadius: 0,
                '&.Mui-disabled': {
                    ...(!divider && { borderWidth: 0 }),
                    ...(divider && { borderWidth: 0, borderRightWidth: 1 })
                },
                ...sx
            }}
            {...overrides}
        >
            {children}
            {label}
        </MuiToggleButton>
    );
};

ToggleButton.propTypes = {
    children: PropTypes.node,
    size: PropTypes.oneOf(Object.values(toggleButtonSizes)),
    ariaLabel: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    divider: PropTypes.bool,
    sx: PropTypes.object
};

ToggleButton.defaultProps = {
    size: toggleButtonSizes.MEDIUM
};

export { ToggleButtonGroup };

export default ToggleButton;

import { Box, Grid } from '@mui/material';
import ClientTable from './utils/ClientTable';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { $$mailTrakClientLOBPage } from './model';
import { useUnit } from 'effector-react';
import useMountedEvent from '../../hooks/useMountedEvent';
import SnackbarRoot from '../../components/SnackbarRoot/SnackbarRoot';

const MailTrakClientLOB = () => {
  useMountedEvent($$mailTrakClientLOBPage.pageMounted);

  const clientListLoading = useUnit($$mailTrakClientLOBPage.$clientListLoading);
  const clientLoading = useUnit($$mailTrakClientLOBPage.$clientLoading);
  const clientList = useUnit($$mailTrakClientLOBPage.$clientList);
  const clientData = useUnit($$mailTrakClientLOBPage.$client);

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Autocomplete
              autoHighlight
              data-testid="client-list-autocomplete"
              loading={clientListLoading}
              options={clientList || []}
              getOptionLabel={option => option.clientName + ' (' + option.clientId + ')'}
              renderInput={params => <TextField {...params} label="Client" autoFocus />}
              onChange={(_event, value) => {
                if (value != null) {
                  $$mailTrakClientLOBPage.clientSelected(value);
                } else {
                  $$mailTrakClientLOBPage.noClientSelected();
                }
              }}
            />
          </Grid>
          <Grid item container xs={4} justifyContent={'center'}>
            <TextField
              data-testid="ytd-job-quantity-field"
              label="YTD Job Quantity"
              value={clientData.ytdJobQuantity.toLocaleString()}
              variant="standard"
              InputProps={{
                readOnly: true
              }}
              sx={{ flexGrow: 1 }}
            />
          </Grid>
          <Grid item container xs={4} justifyContent={'center'}>
            <TextField
              data-testid="ytd-record-count"
              label="YTD Record Count"
              value={clientData.ytdRecordCount.toLocaleString()}
              variant="standard"
              InputProps={{
                readOnly: true
              }}
              sx={{ flexGrow: 1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <ClientTable clientName={clientData.clientName} loading={clientLoading} rows={clientData.linesOfBusiness} />
          </Grid>
        </Grid>
      </Box>
      <SnackbarRoot snackbarProps={{ autoHideDuration: 3000 }} />
    </div>
  );
};

export default MailTrakClientLOB;

import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * ConfirmationWithMemoryContent
 *
 * @typedef {{
 *  title: string;
 *  comment: string;
 *  onValueChange?: (value: boolean) => void;
 *  withNote?: boolean;
 * }} ConfirmationWithMemoryContentProps
 * @param {ConfirmationWithMemoryContentProps} props - props
 * @returns {React.ReactNode} ConfirmationWithMemoryContent component
 */
export default function ConfirmationWithMemoryContent(props) {
  const { title, comment, onValueChange = () => {}, withNote = false } = props;
  return (
    <Stack sx={{ gap: 2 }}>
      <span>{title}</span>
      <span>
        {withNote && (
          <>
            <strong>Please note</strong>
            {': '}
          </>
        )}
        {comment}
      </span>
      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
        <FormControlLabel control={<Checkbox />} label="Don’t show this message again" onChange={(_, value) => onValueChange(value)} />
      </Stack>
    </Stack>
  );
}

ConfirmationWithMemoryContent.propTypes = {
  title: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  onValueChange: PropTypes.func,
  withNote: PropTypes.bool
};

import React from 'react';
import Dropzone from 'react-dropzone';
import { Box, Container, Typography } from '@mui/material';
import PropTypes from 'prop-types';

class GrayhairDropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }

  handleDropAccepted = async acceptedFiles => {
    const extendedFiles = await Promise.all(
      acceptedFiles.map(async file => {
        return {
          rawFile: file,
          base64: await this.readFileAsBase64(file),
          previewURL: URL.createObjectURL(file)
        };
      })
    );

    this.setState({ files: extendedFiles });
    this.props.onDropAcceptedExtended(extendedFiles);
  };

  readFileAsBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  };

  render() {
    const { files } = this.state;
    const { withPreview = true } = this.props;

    return (
      <Dropzone maxFiles={this.props.maxFiles} onDropAccepted={this.handleDropAccepted} accept={this.props.accept}>
        {({ getRootProps, getInputProps }) => (
          <Container>
            <Box {...getRootProps()} display="flex" alignItems="center" sx={{ minHeight: 150, border: '2px dashed grey', cursor: 'pointer' }}>
              <input data-testid={this.props.inputDataTestId} {...getInputProps()} />
              <Typography sx={{ m: 4 }}>{this.props.label}</Typography>
            </Box>
            <Box sx={{ mt: 1, ...(!(files[0]?.rawFile && withPreview) && { display: 'none' }) }}>
              <Typography>Preview:</Typography>
              <Box sx={{ bgcolor: this.props.previewBackgroundColor, p: 1, height: '50px' }}>
                <img src={files[0]?.previewURL} onLoad={() => URL.revokeObjectURL(files[0]?.previewURL)} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="Preview" /> {/* NOSONAR */}
              </Box>
            </Box>
          </Container>
        )}
      </Dropzone>
    );
  }
}

GrayhairDropZone.propTypes = {
  onDropAcceptedExtended: PropTypes.func.isRequired,
  maxFiles: PropTypes.number.isRequired,
  accept: PropTypes.shape({
    [PropTypes.string.isRequired]: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  }).isRequired,
  label: PropTypes.string.isRequired,
  previewBackgroundColor: PropTypes.string.isRequired,
  inputDataTestId: PropTypes.string.isRequired,
  withPreview: PropTypes.bool
};
export default GrayhairDropZone;

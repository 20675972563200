import '../field-description-table.css'

const ReturnedDataFieldDescriptions = (<>
    <table className={"table"}>
        <thead>
        <tr>
            <th className={"field-description"}>Field</th>
            <th className={"field-description"}>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td className={"field-name"}>Return Code</td>
            <td className={"field-description"}>Retrieves the output error code. Address Verification assigns an error code if the input record could not be found in the USPS National Database. This is a 2-byte code that identifies what was wrong with the input address and why our verification could not match it. If Address Verification matches the input address successfully, then this field returns a &quot;0&quot; string.</td>
        </tr>
        <tr>
            <td className={"field-name"}>Return Description</td>
            <td className={"field-description"}>If Address Verification matches the input address successfully, then this field returns a &quot;0&quot; string.
                <ul>
                    <li className={"unordered-list-item"}><strong>0</strong>: The address has been successfully coded.</li>
                    <li className={"unordered-list-item"}><strong>1</strong>: The address is coded but undeliverable (i.e. on side of street known to contain no houses).</li>
                    <li className={"unordered-list-item"}><strong>2</strong>: The ZIP code was not found and the city and state cannot be used to determine a geographical area to search.</li>
                    <li className={"unordered-list-item"}><strong>3</strong>: Coding would result in changing both ZIP and city. This is illegal for PO BOX and route type addresses.</li>
                    <li className={"unordered-list-item"}><strong>4</strong>: The best match would result in too many suspicious changes.</li>
                    <li className={"unordered-list-item"}><strong>5</strong>: The street was identified as an alias but was out of the range restricted for that alias.</li>
                    <li className={"unordered-list-item"}><strong>6</strong>: No street address was given.</li>
                    <li className={"unordered-list-item"}><strong>7</strong>: There are no street name matches in the given ZIP code or in any geographically-related ZIP code.</li>
                    <li className={"unordered-list-item"}><strong>8</strong>: The street may contain superfluous components which cannot be discarded with confidence.</li>
                    <li className={"unordered-list-item"}><strong>9</strong>: The house number could not be matched.</li>
                    <li className={"unordered-list-item"}><strong>10</strong>: The best match was made to a ZIPMOVE record but was not an exact match.</li>
                    <li className={"unordered-list-item"}><strong>11</strong>: A ZIPMOVE match was made but no exact match could be found in the new ZIP.</li>
                    <li className={"unordered-list-item"}><strong>12</strong>: The Early Warning System indicates that an exact match will become available in the next database update.</li>
                    <li className={"unordered-list-item"}><strong>13</strong>: There are multiple matches with the same degree of confidence. This may indicate an inconsistency in the USPS data.</li>
                    <li className={"unordered-list-item"}><strong>14</strong>: Incorrect suffix, directional, street name, or unit resulted in multiple matches with the same degree of confidence.</li>
                    <li className={"unordered-list-item"}><strong>15</strong>: Incorrect ZIP, city, or urbanization resulted in multiple matches with the same degree of confidence.</li>
                    <li className={"unordered-list-item"}><strong>16</strong>: A corrected field was too long to fit into the supplied field.</li>
                    <li className={"unordered-list-item"}><strong>17</strong>: Media Error. The database could not be read because of a hardware or system problem</li>
                </ul></td>
        </tr>
        <tr>
            <td className={"field-name"}>Business Name</td>
            <td className={"field-description"}>Populated name of company, business or non-residential building e.g. Kingswood.</td>
        </tr>
        <tr>
            <td className={"field-name"}>Address Line 1</td>
            <td className={"field-description"}>Primary address information. This reflects USPS standardization of the address information. Example: Input &quot;123 Main Street&quot; results in &quot;123 Main St.&quot;</td>
        </tr>
        <tr>
            <td className={"field-name"}>Address Line 2</td>
            <td className={"field-description"}>Secondary address information, if requested / needed to be separated from the Primary address information. If requested, reflects USPS standardization of the address information. Example: Input &quot;Apartment 12&quot; results in &quot;Apt 12.&quot;</td>
        </tr>
        <tr>
            <td className={"field-name"}>Urbanization Code</td>
            <td className={"field-description"}>Corrected / Populated Puerto Rico urbanization.</td>
        </tr>
        <tr>
            <td className={"field-name"}>City</td>
            <td className={"field-name"}>Corrected / Populated city e.g. Los Angeles.</td>
        </tr>
        <tr>
            <td className={"field-name"}>State</td>
            <td className={"field-description"}>Corrected / Populated state.</td>
        </tr>
        <tr>
            <td className={"field-name"}>ZIP</td>
            <td className={"field-description"}>Retrieves the output ZIP Code.</td>
        </tr>
        <tr>
            <td className={"field-name"}>ZIP+4</td>
            <td className={"field-description"}>Retrieves the output ZIP+4 Code. The ZIP+4 Code is the 4-digit extension only. You can retrieve the 5-digit ZIP Code using the ZIP field type described earlier in this table.</td>
        </tr>
        <tr>
            <td className={"field-name"}>Delivery Point</td>
            <td className={"field-description"}>This string consists of a 2-byte delivery point code plus a 1-byte checksum digit. These constitute the values required for producing a Delivery Point Barcode.</td>
        </tr>
        </tbody>
    </table>
</>)

export default ReturnedDataFieldDescriptions;
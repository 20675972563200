import states from 'states-us';

/* We are filtering the states out then adding them again so that when the Autocomplete is searching through the state list when a user types it will first show the 50 states in the US,
and then show the territories together. */
export const validStates = states.filter((state) => !['Northern Mariana Islands', 'Virgin Islands', 'American Samoa', 'Palau'].includes(state.name)).concat(
    [{ name: 'American Samoa', abbreviation: 'AS', territory: true, contiguous: false },
        { name: 'Armed Forces Europe, the Middle East, Africa, Canada', abbreviation: 'AE', territory: true, contiguous: false },
        { name: 'Armed Forces Pacific', abbreviation: 'AP', territory: true, contiguous: false },
        { name: 'Armed Forces Americas (except Canada)', abbreviation: 'AA', territory: true, contiguous: false },
        { name: 'Federated States of Micronesia', abbreviation: 'FM', territory: true, contiguous: false },
        { name: 'Marshall Islands', abbreviation: 'MH', territory: true, contiguous: false },
        { name: 'Northern Mariana Islands', abbreviation: 'MP', territory: true, contiguous: false },
        { name: 'Palau', abbreviation: 'PW', territory: true, contiguous: false },
        { name: 'Virgin Islands', abbreviation: 'VI', territory: true, contiguous: false },]
)
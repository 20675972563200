import React from 'react';
import PropTypes from 'prop-types';

import { Skeleton as MuiSkeleton } from '@mui/material';

export const skeletonVariants = {
  TEXT: 'text',
  CIRCULAR: 'circular',
  RECTANGULAR: 'rectangular'
};

const Skeleton = ({ variant, width, height, ...overrides }) => {
  return (
    <MuiSkeleton
      width={width}
      height={height}
      variant={variant}
      {...overrides}
    />
  );
};

Skeleton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  variant: PropTypes.oneOf(Object.values(skeletonVariants))
};

Skeleton.defaultProps = {
  variant: 'text'
};

export default Skeleton;

import { useEffect } from 'react';

/**
 * Hook to execute an event when the component is mounted.
 *
 * @param {import('effector').EventCallable<boolean>} event - Event to execute when the component is mounted.
 */
export default function useMountedEvent(event) {
  useEffect(() => {
    event(true);
    return () => {
      event(false);
    };
  }, [event]);
}

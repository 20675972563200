import React from 'react';
import PropTypes from 'prop-types';
import { pick } from 'ramda';

import { Switch as MuiSwitch, FormControlLabel } from '@mui/material';

import { colors as themeColors } from '~/theme';

export const switchSizes = {
  SMALL: 'small',
  MEDIUM: 'medium'
};

export const switchColors = {
  DEFAULT: 'default',
  ...pick(
    ['PRIMARY', 'SECONDARY', 'ERROR', 'INFO', 'SUCCESS', 'WARNING'],
    themeColors
  )
};

const Switch = ({
  label,
  'aria-label': ariaLabel,
  small,
  color,
  disabled,
  defaultChecked,
  checked,
  onChange,
  inputProps,
  ...overrides
}) => (
  <FormControlLabel
    label={label}
    disabled={disabled}
    control={
      <MuiSwitch
        inputProps={{
          'aria-label': ariaLabel || label,
          ...inputProps
        }}
        size={small ? switchSizes.SMALL : switchSizes.MEDIUM}
        color={color}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        {...overrides}
      />
    }
  />
);

Switch.propTypes = {
  label: PropTypes.string,
  'aria-label': PropTypes.string,
  small: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(switchColors)),
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  inputProps: PropTypes.shape()
};

Switch.defaultProps = {
  small: false
};

export default Switch;

import { attach, createEffect, createEvent, createStore, sample } from "effector";
import { persist } from 'effector-storage/local';
import { ghApi } from './DataService';
import { $$theme } from './ThemeService/model';
import { $$personaService } from "./PersonaService/model";
import { spread } from "patronum";

/**
 * @typedef {import('effector').StoreWritable} StoreWritable
 * @typedef {import('effector').Effect} Effect
 */

/**
 * Add current server url to image path.
 *
 * @param {string} url - Image path.
 * @returns {string} Image path with server url.
 */
export function addServerUrlToImage(url) {
  //Use dev url for localhost
  const publicUrl = process.env.REACT_APP_ENVIRONMENT === 'local' ? 'https://portal.dev-int.grayhairsoftware.com' : `${process.env.PUBLIC_URL}`;
  return `${publicUrl}/${url}`;
}

/**
 * Add current server url to return logo path.
 *
 * @param {Array} logos array of logos to work with
 * @returns {Array} array of logos with proper paths.
 */
const addServerUrlToLogos = logos => {
  return [].concat(logos).map(logo => ({ ...logo, light: addServerUrlToImage(logo.light), dark: addServerUrlToImage(logo.dark) }));
};

/** @type {Effect<string, { maxLogos: number; maxSizeInBytes: number; }[]>} */
export const fetchLogoCriteriaFx = createEffect(async () => {
  return ghApi
      .get('/logos/criteria')
      .then(response => response.data);
});

/** @type {Effect<string, unknown>} */
export const fetchCurrentLogosFx = createEffect(async params => {
  return ghApi
    .get('/logos/current', { params })
    .then(response => response.data)
    .then(data => addServerUrlToLogos(data)[0]);
});

const fetchGhsLogoFx = attach({ effect: fetchCurrentLogosFx });
const fetchResellerLogoFx = attach({ effect: fetchCurrentLogosFx });
const fetchCustomerLogoFx = attach({ effect: fetchCurrentLogosFx });
export const fetchPrimaryLogoFx = createEffect(persona => {
  if (persona.ghsSystem === 'MAP') {
    // Prevent HTTP 4xx errors trying to get reseller logos for MAP personas
    return fetchGhsLogoFx();
  } else {
    return Promise.allSettled([ fetchGhsLogoFx(), fetchResellerLogoFx({ resellerId: persona.ghsClientId })])
      .then(([ ghsLogoResult, resellerLogoResult ]) => {
        return resellerLogoResult.status === 'fulfilled' ? resellerLogoResult.value : ghsLogoResult.value;
      });
  }
});

/** @type {Effect<string, { id: number; name: string; light: string; dark: string; }[]>} */
export const listLogosFx = createEffect(async params => {
  return ghApi
    .get('/logos/list', { params })
    .then(response => response.data)
    .then(data => addServerUrlToLogos(data));
});

/**
 * Upload set of logos for a theme.
 *
 * @param {object} params parameters
 * @param {string} params.name name of the theme
 * @param {object} params.lightFile base64 encoded file with light theme logo to upload
 * @param {object} params.darkFile base64 encoded file with dark theme logo to upload
 * @param {string} params.accessToken accessToken of authenticated user
 * @returns {Promise<any|undefined>} upload result
 */
export const uploadLogoSetFx = createEffect(async params => {
  const { name, lightFile, darkFile, ...queryParams } = params;

  const data = {
    ...queryParams,
    themeName: name,
    lightLogo: lightFile,
    darkLogo: darkFile,
  };

  return ghApi
    .post('/logos/upload/logoset', data)
    .then(response => response.data);
});

/**
 * Set logo as current.
 *
 * @param {object} params parameters
 * @param {string} params.newThemeName name of the theme to set
 * @param {string} params.accessToken accessToken of authenticated user
 * @returns {Promise<any|undefined>} current request result
 */
export const setCurrentLogoFx = createEffect(async params => {
  const { newThemeName, ...queryParams } = params;
  return ghApi.post(`/logos/current/${newThemeName}`, null, { params: queryParams }).then(response => response.data);
});

/**
 * Delete a logo set.
 *
 * @param {object} params parameters
 * @param {string} params.themeName name of the theme to delete
 * @param {string} params.accessToken accessToken of authenticated user
 * @returns {Promise<any|undefined>} current request result
 */
export const deleteLogoFx = createEffect(async params => {
  const { themeName, ...queryParams } = params;
  return ghApi.delete(`/logos/${themeName}`, { params: queryParams }).then(response => response.data);
});

// In future we need to devide api requests declatations and model management. Smoothest change - create LogoService folder, add api.js and model.js files insed. Only model should be used in the application as it handles all api requests.

/** @type {StoreWritable<string>} */
const $primaryLogo = createStore(null);
const $secondaryLogo = createStore(null);

const logoUpdated = createEvent();

persist({
  store: $primaryLogo,
  key: 'ghs-logo'
});

sample({
  source: $$theme.$theme,
  clock: fetchPrimaryLogoFx.doneData,
  fn: (theme, logos) => `${logos[theme]}?ts=${new Date().getTime()}`,
  target: $primaryLogo
});

sample({
  source: $$theme.$theme,
  clock: fetchCustomerLogoFx.doneData,
  fn: (theme, logos) => `${logos[theme]}?ts=${new Date().getTime()}`,
  target: $secondaryLogo
});

sample({
  clock: fetchCustomerLogoFx.fail,
  fn: () => null,
  target: $secondaryLogo
});

sample({
  source: $$personaService.$activePersona,
  clock: [ $$personaService.$activePersona.updates, $$theme.$theme.updates, logoUpdated ],
  fn: persona => {
    const targetData = {
      primaryLogo: persona
    };
    if (persona.customerId) {
      targetData['customerLogo'] = { resellerId: persona.ghsClientId, customerId: persona.customerId }
    }
    return targetData;
  },
  target: spread({ primaryLogo: fetchPrimaryLogoFx, customerLogo: fetchCustomerLogoFx })
})

// Clear secondary logo for MT personas or reseller level personas
sample({
  source: $$personaService.$activePersona,
  clock: $$personaService.$activePersona.updates,
  filter: persona => persona.source === 'MT' || !persona.customerId,
  fn: () => null,
  target: $secondaryLogo
})

export const $$logo = {
  $primaryLogo,
  $secondaryLogo,

  logoUpdated,

  setCurrentLogoFx,
  uploadLogoSetFx,
  deleteLogoFx
};

import PropTypes from 'prop-types';
import { prop, propEq } from 'ramda';

import {
  Box,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from '@mui/material';
import { Close, ChevronLeft, ChevronRight } from '@mui/icons-material';

import Link, { linkUnderlines } from '~/components/Link';
import Paper from '~/components/Paper';
import IconButton, { iconButtonSizes } from '~/components/IconButton';

const QuickView = ({
  children,
  title,
  subtitle,
  rows,
  columns,
  summaryField,
  selectedRow,
  onSummaryClick,
  onClose,
  rowsPerPage,
  page,
  totalRows,
  onPagePrev,
  onPageNext
}) => {
  return (
    <Paper elevation={2}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            borderRight: { md: '1px solid' },
            borderRightColor: { md: 'misc.divider' },
            overflow: 'hidden'
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ p: 2 }}>
                  {prop(
                    'headerName',
                    columns.find(propEq(summaryField, 'field'))
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      position: 'relative',
                      p: 2,
                      ...(prop(summaryField, selectedRow) ===
                        prop(summaryField, row) && { bgcolor: 'primary.8p' }),
                      ...(i === rows.length - 1 && { borderBottom: 'none' })
                    }}
                  >
                    <Link
                      component="button"
                      variant="body2"
                      underline={linkUnderlines.HOVER}
                      onClick={
                        onSummaryClick && (event => onSummaryClick(row, event))
                      }
                      sx={{
                        textAlign: 'left',
                        position: 'static',
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          width: '100%',
                          height: '100%'
                        }
                      }}
                    >
                      {row[summaryField]}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} md={10} sx={{ px: 3, py: 2 }}>
          <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
              {(title || subtitle) && (
                <Box>
                  {title && (
                    <Typography variant="h5" component="h3">
                      {title}
                    </Typography>
                  )}
                  <Typography variant="body2">{subtitle}</Typography>
                </Box>
              )}
              <Box sx={{ ml: 'auto' }}>
                <IconButton aria-label="Close" onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
            </Box>

            {children}
          </Paper>
        </Grid>
      </Grid>
      {rows.length < totalRows && (
        <Grid
          item
          xs={12}
          sx={{ borderTop: '1px solid', borderTopColor: 'misc.divider' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1 }}>
            <Typography variant="caption">
              {`${page * rowsPerPage + 1} - ${Math.min(
                rows.length * (page + 1),
                totalRows
              )} of ${totalRows}`}
            </Typography>
            <Box sx={{ ml: 2 }}>
              <IconButton
                aria-label="Previous Page"
                size={iconButtonSizes.SMALL}
                disabled={page === 0}
                onClick={onPagePrev}
              >
                <ChevronLeft />
              </IconButton>
              <IconButton
                aria-label="Next Page"
                size={iconButtonSizes.SMALL}
                disabled={rowsPerPage * (page + 1) >= totalRows}
                onClick={onPageNext}
              >
                <ChevronRight />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      )}
    </Paper>
  );
};

QuickView.propTypes = {
  children: PropTypes.node,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape).isRequired,
  summaryField: PropTypes.string.isRequired,
  selectedRow: PropTypes.shape(),
  onSummaryClick: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  totalRows: PropTypes.number,
  onPagePrev: PropTypes.func,
  onPageNext: PropTypes.func
};

QuickView.defaultProps = {
  page: 0,
  rowsPerPage: 10
};

export default QuickView;

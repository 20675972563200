import React from 'react';
import PropTypes from 'prop-types';

import { Autocomplete as MuiAutocomplete } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import TextField, { textFieldVariants } from '~/components/TextField';

const Autocomplete = ({
  options,
  label,
  placeholder,
  variant,
  ...overrides
}) => {
  return (
    <MuiAutocomplete
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          variant={variant}
          placeholder={placeholder}
          label={label}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
      popupIcon={<ExpandMore />}
      {...overrides}
    />
  );
};

Autocomplete.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(textFieldVariants)),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string.isRequired
      })
    ])
  )
};

Autocomplete.defaultProps = {};

export default Autocomplete;

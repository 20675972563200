// @ts-ignore
import { Alert } from '@ghs/grayhair-component-library';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * FailedCampaignUpdateDialogContent
 *
 * @param {object} props - props
 * @param {string} props.content - campaign
 * @returns {React.ReactNode} - JSX Element
 */
export default function FailedCampaignUpdateDialogContent(props) {
  const { content } = props;
  return (
    <Alert severity="warning">
      <Typography variant="body1" display="inline">
        {content}
      </Typography>
    </Alert>
  );
}

FailedCampaignUpdateDialogContent.propTypes = {
  content: PropTypes.string.isRequired
};

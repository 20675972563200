// @ts-ignore
import { buttonColors, dialogMaxWidths } from '@ghs/grayhair-component-library';
import { GridRowModes } from '@mui/x-data-grid-pro';
import { attach, createEffect, createEvent, createStore, sample, split } from 'effector';
import { persist } from 'effector-storage/query';
import { keyBy, pick, uniq } from 'lodash';
import { debounce, delay, spread } from 'patronum';
import { createElement } from 'react';
import { $$reseller } from '../../services/ResellerService/model.js';
import { recalculateVersionDates, validateVersionDates } from '../../services/ResellerService/utils.js';
import { treeShakeObject, VOID } from '../../util/JsonUtils.js';
import createDataGridModel from '../../util/createDataGridModel.js';
import { createDialog } from '../../util/createDialog.js';
import { $$batchEditVersionDatesDialogModel } from '../BatchEditVersionDatesDialog/model.js';
import { $$batchEditVersionNameDialog } from '../BatchEditVersionNameDialog/model.js';
import { $$confirmationDialog } from '../ConfirmationDialog/model.js';
import { $$snackbar } from '../SnackbarRoot/model.js';
import VersionsDeleteConfirmationDialog from './VersionsDeleteConfirmationDialog.jsx';

/**
 * @typedef {import('@mui/x-data-grid-pro').GridRowId} GridRowId
 * @typedef {import('@mui/x-data-grid-pro').GridValidRowModel} GridValidRowModel
 * @typedef {{ id: GridRowId, field: keyof Reseller.Version, error: string }} ValidationError
 * @typedef {{ id: GridRowId; field: keyof Reseller.Version; value: unknown }} UnsavedChange
 */

/** @type {DialogFactory<{ campaignId: Reseller.Campaign['id'] }>} */
const dialog = createDialog();

const $search = createStore(/** @type {string} */ (''));

const $isRecalculationEnabled = createStore(/** @type {boolean} */ (true));

/** @type {import('effector').Store<Reseller.Campaign | null>} */
const $campaign = sample({
  source: {
    campaigns: $$reseller.$campaigns,
    campaignId: dialog.$state.map(state => Number(state?.campaignId) || null)
  },
  fn: ({ campaigns, campaignId }) => campaigns.find(({ id }) => id === campaignId) || null
});

/** @type {import('effector').EventCallable<Reseller.Campaign['id'] | null>} */
const campaignIdChanged = createEvent();

/** @type {import('effector').EventCallable<string>} */
const searchChanged = createEvent();

/** @type {import('effector').EventCallable<boolean>} */
const realculateToggleChanged = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const deleteSingleClicked = createEvent();

/** @type {import('effector').EventCallable<void>} */
const deleteSelectedClicked = createEvent();

/** @type {import('effector').EventCallable<Reseller.Version['id'][]>} */
const deleteByIdsTriggered = createEvent();

/** @type {import('effector').EventCallable<{ saveAll: () => void, discardChanges: () => void }>} */
const openUnsavedCloseConfirmation = createEvent();

/** @type {import('effector').EventCallable<void>} */
const saveAllClicked = createEvent();

/** @type {import('effector').EventCallable<void>} */
const discardClicked = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const singleRowSaveClicked = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const editClicked = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const cancelClicked = createEvent();

/** @type {import('effector').EventCallable<import('@mui/x-data-grid').GridRowModesModel>} */
const rowsModelChanged = createEvent();

/** @type {import('effector').EventCallable<GridRowId[]>} */
const secondConfirmationTriggered = createEvent();

/** @type {import('effector').Effect<Reseller.Version[], ValidationError[]>} */
const validateFx = createEffect(async updatedRows => {
  const backendValidationResult = await Promise.all(updatedRows.map(version => pick(version, ['id', 'name', 'number', 'campaignId'])).map($$reseller.validateVersionNameFx));
  const nameValidationErrors = backendValidationResult
    .map((isValid, index) => (isValid ? null : index))
    .filter(index => index !== null)
    .map(index => [
      { id: updatedRows[/** @type {number} */ (index)].id, field: 'name', error: `The same Version Name / Version Number combination already exists in the campaign and can’t be duplicated.` },
      { id: updatedRows[/** @type {number} */ (index)].id, field: 'number', error: `The same Version Name / Version Number combination already exists in the campaign and can’t be duplicated.` }
    ])
    .flat();
  const dateValidationErrors = updatedRows.map(validateVersionDates).flat();
  return [...nameValidationErrors, ...dateValidationErrors];
});

const getFreshRowsFx = attach({
  source: {
    campaignId: dialog.$state.map(state => state?.campaignId || null),
    search: $search
  },
  /** @type {(params: DataGrid.ServerSideParams, filters: { campaignId: Reseller.Version['campaignId'] | null; search: string }) => object} */
  mapParams: (pagination, filters) => treeShakeObject({ ...pagination, ...filters }),
  effect: $$reseller.getVersionsFx
});

const $$versions = createDataGridModel({
  type: 'server',
  effect: getFreshRowsFx,
  refetch: [debounce($search.updates, 100), $$reseller.deleteVersionsFx.done]
});

/** @type {import('effector').Effect<{ mailClassIds: Reseller.Version["mailClass"]["id"][]; customerId: Reseller.Campaign["customer"]["id"]; lineOfBusinessId: Reseller.LineOfBusiness["id"] }, unknown>} */
const getMultipleInHomeWindowsDetailFx = createEffect(async ({ customerId, lineOfBusinessId, mailClassIds }) => {
  return Promise.all(mailClassIds.map(mailClassId => $$reseller.getInHomeWindowsDetailFx({ customerId, lineOfBusinessId, mailClassId })));
});

// @ts-ignore
split({
  source: campaignIdChanged,
  match: value => (value === null ? 'close' : 'open'),
  cases: {
    // @ts-ignore
    open: dialog.open.prepend(campaignId => ({ campaignId })),
    close: dialog.close.prepend(() => {})
  }
});

// @ts-ignore
persist({
  store: dialog.$state.map(state => state?.campaignId || null),
  key: 'campaign',
  target: campaignIdChanged
});

sample({
  source: searchChanged,
  target: $search
});

sample({
  source: realculateToggleChanged,
  target: $isRecalculationEnabled
});

sample({
  clock: delay(secondConfirmationTriggered, 0), // Delay is needed to let the confirmation dialog close before opening it again
  fn: ids => ({
    confirmation: {
      title: 'Success!',
      content: 'All your changes have been successfully applied.',
      acceptButtonText: 'Save and exit',
      closeButtonText: 'Undo Changes',
      onAccept: () => {
        $$reseller.deleteVersionsFx(ids);
      }
    },
    highlight: ids
  }),
  target: spread({
    confirmation: $$confirmationDialog.open,
    highlight: $$versions.highlightRows
  })
});

sample({
  clock: deleteSingleClicked.map(id => [id]),
  fn: ids => ids.map(Number),
  target: deleteByIdsTriggered
});

sample({
  source: $$versions.$selectedRowsIds,
  clock: deleteSelectedClicked,
  fn: ids => ids.map(Number),
  target: deleteByIdsTriggered
});

sample({
  source: $$versions.$data,
  clock: deleteByIdsTriggered,
  fn: (versions, idsToDelete) => ({
    title: 'Version deletion confirmation',
    content: createElement(VersionsDeleteConfirmationDialog, { versions: versions.filter(v => idsToDelete.includes(v.id)) }),
    onAccept: () => secondConfirmationTriggered(idsToDelete),
    acceptButtonText: 'Delete all selected',
    acceptButtonColor: buttonColors.ERROR,
    maxWidth: dialogMaxWidths.XL
  }),
  target: $$confirmationDialog.open
});

sample({
  // @ts-ignore
  clock: openUnsavedCloseConfirmation,
  fn: ({ saveAll, discardChanges }) => ({
    title: 'Wait!',
    content: 'You have unsaved changes. Are you sure you want to leave? If you exit now, all changes will be lost.',
    maxWidth: dialogMaxWidths.SM,
    buttons: [
      {
        children: 'Save all changes',
        onClick: () => {
          saveAll();
          $$confirmationDialog.close();
        },
        variant: 'outlined'
      },
      {
        children: 'Exit anyway',
        onClick: () => {
          discardChanges();
          $$confirmationDialog.close();
          $$campaignVersionsDialog.close();
        },
        variant: 'outlined'
      },
      { children: 'Cancel', onClick: $$confirmationDialog.close, variant: 'contained' }
    ]
  }),
  target: $$confirmationDialog.open
});

sample({
  source: $$versions.$updatedRows,
  clock: saveAllClicked,
  target: validateFx
});

sample({
  clock: validateFx.done,
  fn: ({ params: versionsToUpdate, result: errors }) =>
    errors.length > 0
      ? {
          setErrors: errors
        }
      : {
          openConfirmation: {
            title: 'Success!',
            content: 'All your changes have been successfully applied.',
            onAccept: () => $$reseller.updateVersionsFx(versionsToUpdate),
            acceptButtonText: 'Save and exit',
            closeButtonText: 'Undo changes'
          }
        },
  target: spread({
    setErrors: $$versions.setErrors,
    openConfirmation: $$confirmationDialog.open
  })
});

sample({
  clock: $$reseller.deleteVersionsFx.done,
  fn: ({ params }) => ({
    unselectAllRows: VOID,
    snackbar: {
      message: params.length > 1 ? 'Campaign versions deleted' : 'Campaign version deleted',
      severity: /** @type {const} */ ('success')
    }
  }),
  target: spread({ unselectAllRows: $$versions.unselectAllRows, snackbar: $$snackbar.open })
});

sample({
  source: $$versions.$data,
  clock: $$reseller.updateVersionsFx.done,
  fn: (data, { params: updatedRows }) => ({
    unselectAllRows: VOID,
    resetAllChanges: VOID,
    setAllRowsMode: GridRowModes.View,
    data: data.map(version => updatedRows.find(({ id }) => id === version.id) || version)
  }),
  target: spread({
    unselectAllRows: $$versions.unselectAllRows,
    resetAllChanges: $$versions.resetAllChanges,
    setAllRowsMode: $$versions.setAllRowsMode,
    data: $$versions.$data
  })
});

sample({
  source: {
    campaignId: dialog.$state.map(state => state?.campaignId || null),
    unsavedChanges: $$versions.$unsavedChanges
  },
  clock: [
    $$batchEditVersionNameDialog.changesApproved.map(({ campaignId, versionNames: data }) => ({ campaignId, data })),
    $$batchEditVersionDatesDialogModel.changesApproved.map(({ campaignId, versionsDates: data }) => ({ campaignId, data }))
  ],
  filter: ({ campaignId }, { campaignId: receivedCampaignId }) => campaignId === receivedCampaignId,
  fn: ({ unsavedChanges }, { data }) => {
    const newChanges = data.map(({ id, ...updatedCell }) => Object.keys(updatedCell).map(field => ({ id, field, value: updatedCell[field] }))).flat();
    const newUnsavedChanges = [...unsavedChanges.filter(({ id, field }) => !newChanges.some(c => c.id === id && c.field === field)), ...newChanges];
    return {
      unsavedChanges: newUnsavedChanges,
      resetErrors: newUnsavedChanges.map(({ id }) => id)
    };
  },
  target: spread({
    unsavedChanges: $$versions.$unsavedChanges,
    resetErrors: $$versions.resetRowsErrors
  })
});

sample({
  clock: discardClicked,
  target: [$$versions.unselectAllRows, $$versions.resetAllChanges, $$versions.setAllRowsMode.prepend(() => GridRowModes.View)]
});

sample({
  // @ts-ignore
  source: {
    versions: $$versions.$data,
    isRecalculationEnabled: $isRecalculationEnabled,
    unsavedChanges: $$versions.$unsavedChanges,
    inHomeWindowRanges: $$reseller.$inHomeWindowRanges,
    campaign: $campaign
  },
  clock: $$versions.cellValueChanged,
  fn: ({ unsavedChanges, versions, isRecalculationEnabled, inHomeWindowRanges, campaign }, { id, field, value }) => {
    const version = /** @type {Reseller.Version | undefined} */ (keyBy(versions, 'id')[id]);
    /** @type {Reseller.InHomeWindowRange | undefined} */
    const inHomeWindowRange = inHomeWindowRanges.find(
      range => range.mailClassId === version?.mailClass.id && range.lineOfBusinessId === campaign?.lineOfBusiness.id && range.customerId === campaign?.customer.id
    );
    if (!version || !inHomeWindowRange) {
      return {};
    }
    const { changes, isRecalculationEnabled: newIsRecalculationEnabled } = recalculateVersionDates({ field, value, version, isRecalculationEnabled, inHomeWindowRange });
    return {
      unsavedChanges: [...unsavedChanges, ...Object.entries(changes).map(([f, v]) => ({ id, field: f, value: v }))],
      isRecalculationEnabled: newIsRecalculationEnabled
    };
  },
  target: spread({ isRecalculationEnabled: $isRecalculationEnabled, unsavedChanges: $$versions.$unsavedChanges })
});

sample({
  clock: $$reseller.updateVersionsFx.done.map(({ params }) => params.map(version => version.id)),
  target: $$versions.highlightRows
});

sample({
  source: $$versions.$updatedRows,
  clock: singleRowSaveClicked,
  fn: (updatedRows, id) => updatedRows.filter(v => v.id === id),
  target: validateFx
});

sample({
  clock: editClicked.map(id => ({ id, mode: GridRowModes.Edit })),
  target: $$versions.setRowMode
});

sample({
  clock: cancelClicked,
  fn: id => ({ setRowMode: { id, mode: GridRowModes.View }, resetRowChanges: id }),
  target: spread({ setRowMode: $$versions.setRowMode, resetRowChanges: $$versions.resetRowChanges })
});

sample({
  clock: dialog.close,
  target: [$isRecalculationEnabled.reinit, $search.reinit]
});

sample({
  clock: $$versions.cellValueChanged,
  filter: ({ field }) => field === 'name' || field === 'number',
  fn: ({ id }) => ({ resetNameError: { id, field: /** @type {const} */ ('name') }, resetNumberError: { id, field: /** @type {const} */ ('number') } }),
  target: spread({ resetNameError: $$versions.resetCellError })
});

sample({
  source: {
    customerId: $campaign.map(campaign => campaign?.customer.id || -1),
    lineOfBusinessId: $campaign.map(campaign => campaign?.lineOfBusiness.id || -1),
    mailClassIds: $$versions.$data.map(data => uniq(data.map(version => version.mailClass.id)))
  },
  clock: [$$versions.tableMounted.filter({ fn: Boolean }), $$versions.$pagination.updates],
  filter: ({ lineOfBusinessId, customerId }) => lineOfBusinessId !== -1 && customerId !== -1,
  target: getMultipleInHomeWindowsDetailFx
});

/** @type {import('effector').Store<boolean>} */
const $selectedVersionWithScans = sample({
  source: {
    selectedRowIds: $$versions.$selectedRowsIds,
    versions: $$versions.$data
  },
  fn: ({ selectedRowIds, versions }) => versions.some(v => selectedRowIds.includes(v.id) && v.hasScans === true)
});

export const $$campaignVersionsDialog = {
  ...dialog,

  $$versions,

  $campaign,
  $isRecalculationEnabled: $isRecalculationEnabled.map(d => d),
  $search: $search.map(d => d),
  $selectedVersionWithScans: $selectedVersionWithScans.map(d => d),
  $isValidationPending: validateFx.pending,
  $isSavePending: $$reseller.updateVersionsFx.pending,

  searchChanged,
  realculateToggleChanged,
  deleteSingleClicked,
  deleteSelectedClicked,
  openUnsavedCloseConfirmation,
  saveAllClicked,
  discardClicked,
  singleRowSaveClicked,
  editClicked,
  cancelClicked,
  rowsModelChanged
};

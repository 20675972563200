import { Box, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/system';
import PropTypes from 'prop-types';

/**
 * PersonalHeader component
 *
 * @param {Pick<Users.User, 'name' | 'firstName' | 'lastName' | 'avatarUrl'>} props - The props of the component.
 * @returns {React.ReactNode} PersonalHeader component
 */
export default function PersonalHeader(props) {
  const { name, firstName, lastName, avatarUrl } = props;
  const nameInitials = firstName && lastName ? (firstName[0] || '') + (lastName[0] || '') : (name || '').slice(0, 2);

  return (
    <Box sx={{ width: '100%', height: 56, backgroundColor: theme => alpha(theme.palette.text.primary, 0.2), my: 4, display: 'flex', alignItems: 'center', position: 'relative' }}>
      <Stack direction="row" left={16} position="absolute" gap={2} alignItems="center">
        <Box sx={{ width: 84, height: 84, backgroundColor: 'info.main', borderRadius: 10000000, display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
          {avatarUrl ? (
            <Box component="img" alt="Avatar" src={avatarUrl} sx={{ width: '100%', height: '100%' }} />
          ) : (
            <Typography variant="h4" fontWeight={500}>
              {nameInitials}
            </Typography>
          )}
        </Box>
        <Typography variant="body2" fontWeight={500} color="text.primary">
          {name}
        </Typography>
      </Stack>
    </Box>
  );
}

PersonalHeader.propTypes = {
  name: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatarUrl: PropTypes.string
};

import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogContentText as MuiDialogContentText,
  Box,
  Slide
} from '@mui/material';

import Button from '~/components/Button';
import Paper from '~/components/Paper';

import { dialogMaxWidths } from '.';
import { Close } from '@mui/icons-material';

export const DialogContentText = MuiDialogContentText;

export const alignments = {
  RIGHT: 'right',
  LEFT: 'left'
};

export { dialogMaxWidths as flyoutMaxWidths };

const transitionMap = {
  [alignments.RIGHT]: 'left',
  [alignments.LEFT]: 'right'
};

const Transition = forwardRef(({ alignment, ...props }, ref) => (
  <Slide ref={ref} direction={transitionMap[alignment]} {...props} />
));

Transition.displayName = 'SlideTransition';

const Flyout = ({
  children,
  open,
  onClose,
  title,
  headerActions,
  alignment,
  maxWidth,
  sx,
  ...overrides
}) => (
  <MuiDialog
    open={open}
    onClose={onClose}
    maxWidth={maxWidth}
    TransitionComponent={Transition}
    TransitionProps={{ alignment }}
    sx={{
      '& .MuiDialog-paper': {
        position: 'fixed',
        [alignment]: 0,
        top: 0,
        width: '100%',
        height: '100vh',
        maxHeight: 'none',
        m: 0,
        borderRadius: 0
      },
      ...sx
    }}
    {...overrides}
  >
    <Paper square elevation={1} sx={{ p: 3 }}>
      <Box sx={{ mb: 1 }}>
        <Button startIcon={<Close />} onClick={onClose}>
          Close
        </Button>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {title && (
          <DialogTitle variant="h5" sx={{ mr: 1, p: 0, fontWeight: 700 }}>
            {title}
          </DialogTitle>
        )}
        {headerActions && <Box sx={{ ml: 'auto' }}>{headerActions}</Box>}
      </Box>
    </Paper>
    <DialogContent>{children}</DialogContent>
  </MuiDialog>
);

Flyout.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  fullWidth: PropTypes.bool,
  alignment: PropTypes.oneOf(Object.values(alignments)),
  maxWidth: PropTypes.oneOf(Object.values(dialogMaxWidths)),
  onClose: PropTypes.func,
  title: PropTypes.node,
  headerActions: PropTypes.node,
  actions: PropTypes.node,
  sx: PropTypes.shape()
};

Flyout.defaultProps = {
  alignment: alignments.RIGHT
};

Transition.propTypes = {
  alignment: Flyout.propTypes.alignment
};

export default Flyout;

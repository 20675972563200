/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

import { Tabs as MuiTabs, Tab } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';

export const tabVariants = {
  SCROLLABLE: 'scrollable',
  FULL_WIDTH: 'fullWidth'
};

export const tabOrientations = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
};

const Tabs = ({
  children,
  scrollable,
  variant,
  orientation,
  value,
  onChange,
  ...overrides
}) => {
  return (
    <>
      <MuiTabs
        value={value}
        onChange={onChange}
        variant={variant}
        orientation={orientation}
        scrollButtons={scrollable}
        textColor="primary"
        indicatorColor="primary"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 }
          }
        }}
        {...overrides}
      >
        {children}
      </MuiTabs>
    </>
  );
};

Tabs.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  variant: PropTypes.oneOf(Object.values(tabVariants)),
  orientation: PropTypes.oneOf(Object.values(tabOrientations)),
  scrollable: PropTypes.bool,
  onChange: PropTypes.func
};

Tabs.defaultProps = {
  children: null,
  value: 0,
  variant: tabVariants.SIMPLE,
  orientation: tabOrientations.HORIZONTAL,
  scrollable: false
};

export { Tab };

export default Tabs;

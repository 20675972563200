import * as React from "react";
import PropTypes from 'prop-types';
import Container from "@mui/material/Container";

/**
 * Component for displaying the contents as a tab.
 *
 * @param {object} props props for this component
 * @param {Element} props.children the contents of the tab
 * @param {number} props.value the index of the currently selected tab
 * @param {number} props.index the index of this tab
 * @returns {Element} TabPanel component
 * @class
 */
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Container
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Container sx={{ p: 3 }}>
            {children}
          </Container>
        )}
      </Container>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number,
    index: PropTypes.number
  };


export default TabPanel;
  
import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'ramda';

import { Chip as MuiChip, useTheme } from '@mui/material';

import { colors as themeColors } from '~/theme';

export const chipVariants = {
  FILLED: 'filled',
  OUTLINED: 'outlined'
};

export const chipSizes = {
  MEDIUM: 'medium',
  SMALL: 'small'
};

export const chipColors = {
  DEFAULT: 'default',
  ...omit(['GREY', 'TEXT'], themeColors)
};

const Chip = ({
  label,
  variant,
  size,
  color,
  avatar,
  disabled,
  onClick,
  onDelete,
  sx,
  ...overrides
}) => {
  const theme = useTheme();

  return (
    <MuiChip
      label={label}
      variant={variant}
      size={size}
      color={color}
      avatar={avatar}
      disabled={disabled}
      onClick={onClick}
      onDelete={onDelete}
      sx={{
        '& .MuiChip-avatar': {
          backgroundColor: theme.palette.action.active,
          color: theme.palette.primary.contrastText
        },
        ...(theme.palette.mode === 'dark' &&
          (color === 'info' || color === 'error') && {
            '&': {
              color: theme.palette.primary.contrastText
            }
          }),
        ...sx
      }}
      {...overrides}
    />
  );
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(chipVariants)),
  color: PropTypes.oneOf(Object.values(chipColors).concat('default')),
  size: PropTypes.oneOf(Object.values(chipSizes)),
  avatar: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  sx: PropTypes.shape()
};

Chip.defaultProps = {
  variant: chipVariants.FILLED
};

export default Chip;

import { createEvent, createStore, sample } from 'effector';
import { $$reseller } from '../../services/ResellerService/model.js';
import { createDialog } from '../../util/createDialog.js';

/** @type {DialogFactory<{ campaignId: Reseller.Campaign['id'], versions: Reseller.Version[]  }>} */
const dialog = createDialog();

/** @type {import('effector').StoreWritable<boolean>} */
const $isRecalculationEnabled = createStore(true);

/** @type {import('effector').EventCallable<boolean>} */
const recalculatingChanged = createEvent();

/** @type {import('effector').EventCallable<{ campaignId: Reseller.Campaign['id'], versionsDates: Reseller.Version[]  }> }>} */
const changesApproved = createEvent();

sample({
  clock: recalculatingChanged,
  target: $isRecalculationEnabled
});

/** @type {import('effector').Store<Reseller.Campaign | null>} */
const $campaign = sample({
  source: {
    campaigns: $$reseller.$campaigns,
    campaignId: dialog.$state.map(state => Number(state?.campaignId) || null)
  },
  fn: ({ campaigns, campaignId }) => campaigns.find(({ id }) => id === campaignId) || null
});

export const $$batchEditVersionDatesDialogModel = {
  ...dialog,

  $isRecalculationEnabled,
  $campaign,

  changesApproved,
  recalculatingChanged
};

import { alpha } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid-pro';
import { omit } from 'lodash';

export const DataGridStyles = /** @type {const} */ ({
  border: 'none',
  [`.${gridClasses.row}.Mui-selected`]: {
    backgroundColor: 'unset'
  },
  [`.${gridClasses.cell}--selected`]: {
    backgroundColor: () => '#0288D180'
  },
  [`.${gridClasses.cell}--success`]: {
    backgroundColor: theme => alpha(theme.palette.success.main, 0.5)
  },
  [`.${gridClasses.cell}--error, .${gridClasses.cell}--error.${gridClasses.cell}--editing.${gridClasses.cell}.${gridClasses.cell}--textLeft.${gridClasses.cell}--editable`]: {
    backgroundColor: theme => alpha(theme.palette.error.main, 0.12),
    border: '2px solid',
    borderColor: 'error.main',
    [`&.${gridClasses['cell--editing']}`]: {
      background: theme => alpha(theme.palette.error.main, 0.12)
    }
  },
  [`.${gridClasses.cell}--edited, .${gridClasses.cell}.editable-date-cell, .${gridClasses.cell}.${gridClasses['cell--editing']}`]: {
    boxShadow: 'unset',
    border: '2px solid',
    borderColor: theme => theme.palette.primary.main,
    backgroundColor: theme => alpha(theme.palette.info.dark, theme.palette.mode === 'light' ? 0.04 : 0.2),
    input: {
      fontSize: 14,
      padding: '14.5px 9px'
    },
    fieldset: {
      border: 'unset'
    }
  },
  [`.${gridClasses.columnHeader}`]: {
    backgroundColor: 'misc.dataGridHeader'
  }
});

export const DataGridStylesWithoutSelectionOverride = omit(
  {
    ...DataGridStyles,
    [`.MuiDataGrid-selectedRowCount`]: { visibility: 'hidden', fontSize: 0 },
    ['.MuiDataGrid-cell:focus']: { outline: 'none' },
    ['.MuiDataGrid-cell:focus-within']: { outline: 'none' },
    ['.MuiDataGrid-columnHeader:focus']: { outline: 'none' },
    ['.MuiDataGrid-columnHeader:focus-within']: { outline: 'none' }
  },
  [`.${gridClasses.row}.Mui-selected`, `.${gridClasses.cell}--selected`]
);

/**
 * Copies the specified text to the clipboard.
 *
 * @param {string} text - The text to be copied to the clipboard.
 * @returns {Promise<void>} - A Promise that completes once the text is copied to the users' clipboard.
 */
export const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
        await navigator.clipboard.writeText(text);
    } else {
        console.warn("Cannot copy to user clipboard as browser is not supported")
    }
};
import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '@mui/material';

import { isNilOrEmpty } from '~/util/ramda';
import Avatar from '~/components/Avatar';
import Divider from '~/components/Divider';

import List, { ListItem, ListItemText, ListItemAvatar } from './index';

const FriendsList = ({ friends, sx, ...overrides }) => {
  return (
    <List sx={{ width: '100%', ...sx }} {...overrides}>
      {friends.map(
        (
          {
            name,
            friendsCount,
            avatarSrc,
            avatarChildren,
            isOnline,
            checkboxLabel,
            checked,
            onChange
          },
          i
        ) => (
          <Fragment key={`${name}-${i}`}>
            {i !== 0 && <Divider component="li" />}
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  src={avatarSrc}
                  {...(isOnline && {
                    badge: true,
                    badgeLabel: 'online'
                  })}
                >
                  {avatarChildren}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={name}
                secondary={
                  !isNilOrEmpty(friendsCount) && `${friendsCount} friends`
                }
              />
              {checkboxLabel ? (
                <Checkbox
                  checked={checked}
                  onChange={onChange}
                  inputProps={{ 'aria-label': checkboxLabel }}
                />
              ) : null}
            </ListItem>
          </Fragment>
        )
      )}
    </List>
  );
};

FriendsList.propTypes = {
  friends: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      friendsCount: PropTypes.number,
      avatarSrc: PropTypes.string,
      avatarChildren: PropTypes.node,
      isOnline: PropTypes.bool,
      checkboxLabel: PropTypes.string,
      checked: PropTypes.bool,
      onChange: PropTypes.func
    })
  ),
  sx: PropTypes.shape()
};

FriendsList.defaultProps = {
  friends: []
};

export default FriendsList;

import React from 'react';
import PropTypes from 'prop-types';

import { ButtonGroup as MuiButtonGroup } from '@mui/material';

import { buttonVariants, buttonColors, buttonSizes } from '~/components/Button';

export {
  buttonVariants as buttonGroupVariants,
  buttonColors as buttonGroupColors,
  buttonSizes as buttonGroupSizes
};

export const buttonGroupOrientations = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
};

const ButtonGroup = ({
  children,
  variant,
  size,
  color,
  orientation,
  ...overrides
}) => (
  <MuiButtonGroup
    variant={variant}
    size={size}
    color={color}
    orientation={orientation}
    {...overrides}
  >
    {children}
  </MuiButtonGroup>
);

ButtonGroup.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(Object.values(buttonVariants)),
  color: PropTypes.oneOf(Object.values(buttonColors)),
  size: PropTypes.oneOf(Object.values(buttonSizes)),
  orientation: PropTypes.oneOf(Object.values(buttonGroupOrientations)),
  onClick: PropTypes.func
};

ButtonGroup.defaultProps = {
  children: null,
  variant: buttonVariants.CONTAINED,
  color: buttonColors.PRIMARY,
  size: buttonSizes.MEDIUM,
  orientation: buttonGroupOrientations.HORIZONTAL,
  onClick: undefined
};

export default ButtonGroup;
